import { get, isEmpty } from 'lib/lodash'
import { langCode } from 'lib/i18n'

import {
  SHIPMENT_FIELDS,
  RETURN_TYPES,
  NETWORKS,
  SHOP_FIELDS,
  COUNTRIES_WITH_STATES,
} from 'config/constants'
import shopSettings from 'config/shopSettings'
import { isBlank } from 'lib/helpers'

const { RETURN_TYPE, NETWORK, SHIP_FROM_COUNTRY_CODE, RETURN_REASON_ID } =
  SHIPMENT_FIELDS

const orderHelper = (props = {}) => {
  let { order } = props
  const { shop, values } = props
  if (!shop || isEmpty(shop)) return { noData: true }
  if (!order || isEmpty(order)) return { noData: true }

  if (values) order = { ...order, ...values }

  const allPricelists = get(shop, SHOP_FIELDS.PRICELISTS)
  if (!allPricelists) return { noData: true }

  const {
    gatekeeperEnabled,
    showReturnReasons,
    showPersonalFieldsForDropoff,
    showNameField,
  } = shop

  const rawReturnReasonOptions = get(shop, `returnReasons.${langCode()}`, {})

  const returnReasonId = get(order, RETURN_REASON_ID)
  const isEditing = get(order, 'isEditing')
  // const price = get(order, "price");
  const network = get(order, NETWORK)
  const shipFromCountryCode = get(order, SHIP_FROM_COUNTRY_CODE)
  const returnType = get(order, RETURN_TYPE)
  const isPickup = returnType === RETURN_TYPES.PICKUP
  const isDropoff = returnType === RETURN_TYPES.DROPOFF
  const isUps = network && network.toLowerCase() === NETWORKS.UPS
  const isInternational =
    !isBlank(shipFromCountryCode) && shipFromCountryCode !== 'nl'

  const isLocal = !isBlank(shipFromCountryCode) && shipFromCountryCode === 'nl'

  const returnReasonsEnabled = showReturnReasons || gatekeeperEnabled

  const chosenReturnReason =
    !isEditing && rawReturnReasonOptions[returnReasonId]
  const showDefectField =
    returnReasonsEnabled && get(chosenReturnReason, 'defect', false)

  const showName =
    isPickup || isInternational || showNameField || showPersonalFieldsForDropoff

  const showPhone =
    shopSettings('showPhoneNumberField', shop) || showPersonalFieldsForDropoff

  const showConsent = !isEditing && shopSettings('consentRequired', shop)
  const addressNeedsState =
    isUps &&
    get(COUNTRIES_WITH_STATES, returnType, []).includes(shipFromCountryCode)

  return {
    isPickup,
    isDropoff,
    isLocal,
    isInternational,
    showName,
    showPhone,
    showDefectField,
    showConsent,
    addressNeedsState,
  }
}

export default orderHelper
