import React from 'react'
import i18n from 'lib/i18n'
import ShamedEmoji from 'components/ShamedEmoji'
import smiles from 'assets/images/emoji/smiles.png'

function ApprovePending({ shop, t, children, showEmoji = true }) {
  const { frontendAssetId, workingDaysToApprove } = shop
  let headerText = t('confirmation.gatekeeperPending.header.default')
  if (i18n.exists(`confirmation.gatekeeperPending.header.${frontendAssetId}`))
    headerText = t(`confirmation.gatekeeperPending.header.${frontendAssetId}`, {
      days: t('confirmation.days', { days: workingDaysToApprove }),
    })

  return (
    <div className="columns is-mobile is-multiline">
      <div className="column is-10 is-offset-1 confirmation__message">
        {showEmoji && (
          <ShamedEmoji emoji={smiles} style={{ marginBottom: '1.5rem' }} />
        )}
        <h2>{headerText}</h2>
        <h3>{t('confirmation.gatekeeperPending.footer')}</h3>
      </div>

      {children}
    </div>
  )
}

export default ApprovePending
