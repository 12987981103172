import React from 'react'
import Lottie from 'react-lottie'
import preloaderAnimation from '../../assets/animations/preLoader.json'

export default function () {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: preloaderAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
    },
  }

  return (
    <div className="lottie-wrapper">
      <Lottie options={defaultOptions} height="300px" />
    </div>
  )
}
