import numeral from 'numeral'

// load a locale
numeral.register('locale', 'nl', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  currency: {
    symbol: '€',
  },
})

// switch between locales
numeral.locale('nl')
