import React from 'react'
import FieldSelect from 'components/FieldSelect'
import FieldWrapper from 'components/FieldWrapper'
import { isFieldDirty } from 'lib/formHelpers'
import {
  SHIPMENT_FIELDS,
  STATES_OPTIONS,
  STATES_OPTIONS_INDEX,
} from 'config/constants'
import { get, orderBy } from 'lib/lodash'

const { SHIP_FROM_STATE_PROVINCE_CODE, SHIP_FROM_COUNTRY_CODE } =
  SHIPMENT_FIELDS

export default function ShipFromStateProvinceCode(props) {
  const { errors, values, touched, t } = props
  const value = get(values, SHIP_FROM_STATE_PROVINCE_CODE)
  const error = get(errors, SHIP_FROM_STATE_PROVINCE_CODE)
  let shipFromCountryCode = get(values, SHIP_FROM_COUNTRY_CODE)
  if (shipFromCountryCode)
    shipFromCountryCode = shipFromCountryCode.toLowerCase()

  let stateOptions = get(STATES_OPTIONS, shipFromCountryCode)
  stateOptions = orderBy(stateOptions, ['text'], ['asc'])
  const stateOptionsIndex = get(STATES_OPTIONS_INDEX, shipFromCountryCode)
  const shipFromStateProvinceCodeProps = {
    ...props,
    id: SHIP_FROM_STATE_PROVINCE_CODE,
    name: SHIP_FROM_STATE_PROVINCE_CODE,
    prompt: t('address.form.state_province_code.prompt'),
    label: t('address.form.state_province_code.label'),
    optionsIndex: stateOptionsIndex,
    options: stateOptions,
    optionsStyle: { zIndex: 11 },
    isFieldDirty: isFieldDirty(touched, SHIP_FROM_STATE_PROVINCE_CODE),
    error,
    value,
  }

  return (
    <FieldWrapper errors={error} filled={value} classNames="control-no-hr">
      <FieldSelect {...shipFromStateProvinceCodeProps} />
    </FieldWrapper>
  )
}
