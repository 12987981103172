import Axios from 'axios'

import { URI } from '../lib/urlConstructor'

const axiosInstance = Axios.create({
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

export default async (body) => axiosInstance.post(URI, body)
