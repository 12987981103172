import React from 'react'
import PropTypes from 'prop-types'

function Arrow({ direction }) {
  return (
    <div className={`arrow arrow--${direction}`}>
      <div className="arrow__triangle" />
    </div>
  )
}

Arrow.defaultProps = {
  direction: 'right',
}

Arrow.propTypes = {
  direction: PropTypes.string,
}

export default Arrow
