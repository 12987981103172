import React from 'react'
import ShamedEmoji from 'components/ShamedEmoji'
import { isBlank } from 'lib/helpers'
import thinking from 'assets/images/emoji/thinking.png'

function PaymentPending({ order, t, children, showEmoji = true }) {
  const { name } = order

  return (
    <div className="columns is-mobile is-multiline">
      <div className="column is-10 is-offset-1 confirmation__message">
        {showEmoji && (
          <ShamedEmoji emoji={thinking} style={{ marginBottom: '1.5rem' }} />
        )}
        <h2>
          {t('confirmation.header-pending', {
            name: !isBlank(name) ? `, ${name}` : '',
          })}
        </h2>
        <p>{t('confirmation.payment_status.pending')}</p>
      </div>

      {children}
    </div>
  )
}

export default PaymentPending
