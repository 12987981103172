import { useEffect, useState } from 'react'
import { withNamespaces } from 'react-i18next'
import { get, isNil } from 'lib/lodash'
import useHistory from 'hooks/useHistory'
import useOrder from 'hooks/useOrder'
import { orderAction } from 'lib/actions'
import useProceedApi from './useProceedApi'

function ProceedContainer(props) {
  const orderId = get(props, 'match.params.id')
  const { goToConfirmation, goToTimeslots } = useHistory()
  const { requestProceed } = useProceedApi()
  const [order, setOrder] = useState()
  const { noData, mayProceed, isPickup, isBrenger } = useOrder({ order })

  const getOrder = async () => {
    const responseData = await orderAction.fetchOrder(orderId)
    if (responseData) {
      setOrder(responseData)
      orderAction.setOrder(responseData)
    }
  }

  useEffect(() => {
    getOrder()
  }, [])

  useEffect(() => {
    if (isNil(order)) return
    if (mayProceed) {
      proceed()
      return
    }

    goToConfirmation(orderId)
  }, [order])
  if (noData) return null

  const proceed = async () => {
    if (isPickup && !isBrenger && mayProceed) {
      return goToTimeslots()
    }

    return requestProceed(order)
  }

  return null
}

export default withNamespaces('translation')(ProceedContainer)
