import { get } from 'lib/lodash'
import appActions from 'features/app/actions'

const rootApiCatch = (payload, error, options = { notify: true }) => {
  const status = get(error, 'response.status')
  if (error.message === 'Network Error') return appActions.setError(503)
  if ([401, 403, 500].includes(status)) return appActions.setError(status)

  if (options.notify && ![422, 404, null, undefined].includes(status))
    return appActions.sendError({
      debugInfo: { status, query: payload.query, ...payload.variables },
    })

  throw error
}

export default rootApiCatch
