import React from 'react'
import cn from 'classnames'
import ReactSVG from 'react-svg'
import { getShop } from 'lib/selectors'
import { useSelector } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import returnistaLogo from 'assets/returnistaReversedLogo.svg'
import { SHOP_FIELDS } from 'config/constants'
import { get } from 'lib/lodash'
import shopSettings from 'config/shopSettings'

const { GATEKEEPER_ENABLED } = SHOP_FIELDS

export function Footer({ t, className = null }) {
  const shop = useSelector(getShop)
  const gatekeeperEnabled = get(shop, GATEKEEPER_ENABLED)
  const textVisible =
    !gatekeeperEnabled && !shopSettings('hideFooterText', shop)

  return (
    <footer className={cn({ footer: true, [className]: className })}>
      <div className="has-text-centered has-text-right-mobile">
        {textVisible && (
          <>
            <ReactSVG src={returnistaLogo} className="returnista-logo" />
            <span className="is-hidden-mobile">{t('footer.title')}</span>
          </>
        )}
      </div>
    </footer>
  )
}

export default withNamespaces('translation')(Footer)
