import React, { useRef } from 'react'
import { Field } from 'formik'
import { withNamespaces } from 'react-i18next'
import cn from 'classnames'
import { truncate } from 'lib/helpers'
import './index.scss'

function FieldFile({
  setFieldValue,
  name,
  validate,
  label,
  errors,
  values,
  required,
  t,
}) {
  const buttonRef = useRef(null)

  const handleChange = () => {
    const file = buttonRef.current.files[0]
    if (!file) return setFieldValue(name, null)

    const r = new window.FileReader()
    r.onload = function (e) {
      setFieldValue(name, {
        name: file.name,
        size: file.size,
        type: file.type,
        binary: e.target.result,
      })
    }
    r.readAsDataURL(file)
    return null
  }

  return (
    <Field name={name} validate={validate}>
      {() => {
        const hasErrors =
          errors[name] || (values[name || ''] === '' && required)
        const value = values[name]
        const hasValue = Boolean(value)

        return (
          <div
            className={`field-file__wrapper ${
              hasErrors
                ? 'field-file__wrapper-with-errors'
                : 'field-file__wrapper-is-valid'
            }`}
          >
            <label className="field-file__label" htmlFor={name}>
              {label}
            </label>
            <div className="field-file__inputs">
              <input
                ref={buttonRef}
                onChange={handleChange}
                type="file"
                name={name}
                id="file"
                style={{ display: 'none' }}
              />
              <button
                type="button"
                onClick={() => buttonRef.current.click()}
                className={cn(
                  'button',
                  'field-file__button',
                  'focus:outline-none',
                  {
                    'field-file__button--chosen': hasValue,
                  },
                )}
                style={{ position: 'unset' }}
              >
                {hasValue
                  ? truncate(value.name, 23)
                  : t('details.form.image.button.select')}
              </button>
              {errors[name] ? (
                <div className="field-file__error">{t(errors[name])}</div>
              ) : null}
            </div>
          </div>
        )
      }}
    </Field>
  )
}

export default withNamespaces('translation')(FieldFile)
