import React from 'react'
import FieldInput from 'components/FieldInput'
import FieldWrapper from 'components/FieldWrapper'
import { get } from 'lib/lodash'
import { isFieldDirty } from 'lib/formHelpers'
import { LOCAL_ADDRESS_FIELDS } from 'config/constants'

const { STREET } = LOCAL_ADDRESS_FIELDS

export default function Street(props) {
  const { errors, values, touched, t } = props
  const value = get(values, STREET)
  const error = get(errors, STREET)

  const streetProps = {
    ...props,
    id: STREET,
    name: STREET,
    label: t('address.form.street_name.label'),
    disabled: true,
    isFieldDirty: isFieldDirty(touched, STREET),
    value,
  }

  return (
    <FieldWrapper errors={error} filled={value} hidden={streetProps.hidden}>
      <FieldInput {...streetProps} />
    </FieldWrapper>
  )
}
