import React from 'react'
import FieldInput from 'components/FieldInput'
import FieldWrapper from 'components/FieldWrapper'
import { isFieldDirty } from 'lib/formHelpers'
import styled from 'styled-components'
import { get } from 'lib/lodash'
import { getTransKey } from 'lib/i18n'

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
`

const Button = styled.button`
  height: 1rem;
  width: 1rem;
  padding: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function Item(props) {
  const {
    errors,
    values,
    touched,
    t,
    index,
    itemAmount = 0,
    incrItemAmount,
    decrItemAmount,
    shop,
  } = props

  const itemId = `item_${index}`

  const value = get(values, itemId)
  const error = get(errors, itemId)

  const itemProps = {
    ...props,
    id: itemId,
    name: itemId,
    label: t(getTransKey('details.form.description.label', shop)),
    isFieldDirty: isFieldDirty(touched, itemId),
    required: false,
    style: { height: '10rem' },
    labelStyle: { width: '80%' },
    value,
  }

  if (itemAmount === index + 1) {
    itemProps.items = (
      <>
        <Button type="button" onClick={() => incrItemAmount()}>
          +
        </Button>
        <Button type="button" onClick={() => decrItemAmount(itemId)}>
          -
        </Button>
      </>
    )
  }
  return (
    <Container>
      <FieldWrapper
        errors={error}
        filled={value}
        style={{ width: 'inherit', flexGrow: 1 }}
      >
        <FieldInput {...itemProps} />
      </FieldWrapper>
    </Container>
  )
}
