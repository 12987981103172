// werkschoenenbaas
export default {
  '--shop-black': 'hsl(0, 0%, 20%)',
  '--shop-black--lighter': 'hsl(0, 0%, 27%)',
  '--shop-white': 'hsl(0, 0%, 100%)',
  '--shop-white--darker': 'hsl(0, 0%, 95%)',
  '--shop-color': 'hsl(353, 79%, 57%)',
  '--shop-color--light': 'hsl(353, 79%, 80%)',
  '--shop-color--darker': 'hsl(353, 79%, 47%)',
  '--shop-color--dark': 'hsl(353, 79%, 37%)',

  '--headers-text-color': 'var(--shop-white)',
  '--headers-text-color--dimmed': 'var(--shop-white--darker)',

  '--input-text-color': 'var(--shop-white)',
  '--input-check-bg-color': 'var(--shop-color)',
  '--main-text-color': 'var(--shop-black)',
  '--main-text-color--disabled': 'var(--shop-white--darker)',
  '--scn-border-color': 'var(--shop-white)',
  '--input-bg-color': 'hsla(0, 0%, 27%, .7)',

  '--field-select-bg-color': 'var(--shop-white)',

  '--prm-btn-border-radius': '.4rem',
  '--prm-btn-border-color': 'var(--shop-color)',
  '--prm-btn-border-color--hover': 'var(--shop-color--darker)',
  '--prm-btn-bg-color': 'var(--shop-color)',
  '--prm-btn-bg-color--hover': 'var(--shop-black--lighter)',

  '--scn-btn-border-color': 'var(--shop-white)',
  '--prm-btn-text-color': 'var(--shop-white)',
  '--prm-btn-text-color--hover': 'var(--prm-btn-text-color)',
  '--prm-btn-text-color--disabled': 'var(--prm-btn-text-color)',

  '--box-border-color--active': 'var(--shop-white)',
  '--box-text-color--active': 'var(--shop-white)',

  '--main-gradient-color-1': 'var(--shop-black)',
  '--main-gradient-color-1--hover': 'var(--shop-black--lighter)',
  '--main-gradient-color-2': 'var(--shop-black)',
  '--main-gradient-color-2--hover': 'var(--shop-black)',

  '--footer-bg-color-1': 'hsl(0, 0%, 13%)',
  '--footer-bg-color-2': 'var(--footer-bg-color-1)',

  '--footer-text-color': 'hsl(0, 0%, 98%)',
  '--footer-logo-color': 'var(--footer-text-color)',

  '--scn-gradient-color-1': 'var(--shop-black)',
  '--scn-gradient-color-1--hover': 'var(--shop-black--lighter)',
  '--scn-gradient-color-2': 'var(--shop-black)',
  '--scn-gradient-color-2--hover': 'var(--shop-black)',

  '--progress-bar-bg-color': 'var(--shop-black)',
  '--progress-bar-bg-color--active': 'var(--progress-bar-bg-color)',
  '--progress-bar-step-bg-color': 'var(--headers-text-color--dimmed)',
  '--progress-bar-step-bg-color--active': 'var(--main-gradient-color-2)',
  '--progress-bar-gradient-color-1': 'var(--shop-color)',
  '--progress-bar-gradient-color-2': 'var(--shop-color--darker)',
  '--progress-bar-step-checked-bg-color': 'var(--input-bg-color)',

  '--network-btn-bg-color': 'var(--shop-black)',
  '--network-btn-bg-color--active': 'var(--shop-black--lighter)',
  '--network-btn-border-color': 'var(--shop-black--lighter)',

  '--confirmation-box-bg-color': 'var(--input-bg-color)',
  '--a-color': 'var(--shop-white--darker)',
  '--language-bg-color': 'var(--shop-white--darker)',
  '--brand-color-1': 'var(--shop-white--darker)',
  '--brand-color-2': 'hsl(32, 41%, 55%))',
  '--brand-text-color': 'hsl(32, 41%, 55%)',

  '--background': "center / cover no-repeat url('/bg/werkschoenenbaas@3.png')",
}
