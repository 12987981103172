import React, { useState, useEffect } from 'react'
import { withNamespaces } from 'react-i18next'
import LoadingPage from 'containers/LoadingPage'
import i18n from 'lib/i18n'
import { formatAsLongDate, formatDateAsHour } from 'lib/dateHelpers'
import pinIcon from 'assets/icons/mapIcon.svg'
import returnistaBox from 'assets/icons/returnistaBoxAEVersion.svg'
import tickIcon from 'assets/icons/tickIcon.svg'
import { isBlank } from 'lib/helpers'
import { get } from 'lib/lodash'
import ReactSVG from 'react-svg'
import useInterval from 'hooks/useInterval'
import useOrder from 'hooks/useOrder'
import { SHIPMENT_FIELDS } from 'config/constants'
import { orderAction } from 'lib/actions'
import { useSelector } from 'react-redux'
import { getApp } from 'lib/selectors'
import shopSettings from 'config/shopSettings'
import PaymentFailed from './PaymentFailed'
import SuccessOrder from './SuccessOrder'
import PaymentPending from './PaymentPending'
import ApprovePending from './ApprovePending'
import CheckEmail from './CheckEmail'

// import { isDevelopment } from 'lib/environment';

const { ID, LOCALE } = SHIPMENT_FIELDS

function ConfirmationContainer(props) {
  const {
    noData,
    shop,
    order,
    isDropoff,
    isPickup,
    approvePending,
    paymentFailed,
    paymentPending,
    isDhlPickup,
    noLabel,
    showAddressForm,
  } = useOrder()
  const [polledIninitially, setPolledInitially] = useState(false)

  const app = useSelector(getApp)
  const storedLocale = get(app, 'locale')
  const currentLocale = i18n.language.substring(0, 2)

  const pollOrderInitially = async () => {
    setPolledInitially(true)
    await orderAction.fetchOrder(orderId)
  }

  useEffect(() => {
    if (noData) return
    if (polledIninitially) return

    pollOrderInitially()
  }, [])

  useEffect(() => {
    if (noData) return
    i18n.changeLanguage(locale)
  }, [locale])

  const keepPolling = () => {
    if (isBlank(get(order, ID))) return true

    if (approvePending) return false
    if (noLogistics) return false

    if (!isFree) {
      if (paymentFailed) return false
      if (paymentPending) return true
    }
    if (isDhlPickup) return false
    return noLabel
  }

  const pollOrder = async () => {
    if (keepPolling()) await orderAction.fetchOrder(orderId)
  }

  useInterval(() => {
    pollOrder()
  }, 2000)

  useEffect(() => {
    if (!storedLocale) return

    if (currentLocale.toLowerCase() === storedLocale.toLowerCase()) return
    i18n.changeLanguage(storedLocale)
  }, [storedLocale, currentLocale])

  if (noData) return <LoadingPage />

  const { t, match } = props

  const appLocale = i18n.language.substring(0, 2)
  const { gatekeeperEnabled } = shop
  const orderId = match.params.id
  const locale = get(order, LOCALE) || appLocale

  const {
    name,
    shipFromHouseNumber,
    shipFromStreet,
    start,
    stop,
    dropoffProviderName,
    dropoffProviderUrl,
    orderNumber,
    numberOfBoxes,
    isFree,
    noLogistics,
  } = order

  let Comp = SuccessOrder
  if (approvePending) Comp = ApprovePending
  else if (isFree === false) {
    if (paymentFailed) Comp = PaymentFailed
    if (paymentPending) Comp = PaymentPending
  }

  if (noLogistics) Comp = CheckEmail
  const showEmoji = !shopSettings('hideConfirmationPageEmojis', shop)

  return (
    <Comp {...props} order={order} shop={shop} showEmoji={showEmoji}>
      <div className="column is-10-mobile is-offset-1-mobile confirmation__box is-6-desktop is-offset-3-desktop is-6-tablet is-offset-3-tablet">
        <div className="columns is-gapless is-multiline is-mobile">
          {start && (
            <div className="column is-12">
              <div className="columns is-gapless is-mobile confirmation__block has-vcentered">
                <div className="column is-offset-1 is-2 confirmation__icon-container">
                  <div className="confirmation__icon">
                    <ReactSVG src={tickIcon} alt="confirmation" />
                  </div>
                </div>
                <div className="column is-9">
                  <p className="confirmation__box--header">
                    {isPickup && formatAsLongDate(start, locale)}
                    {isDropoff && name}
                  </p>
                  <p className="confirmation__box--highlight">
                    {isPickup &&
                      start &&
                      `${formatDateAsHour(start)} - ${formatDateAsHour(stop)}`}
                    {isDropoff && !isBlank(orderNumber) && `#${orderNumber}`}
                  </p>
                </div>
              </div>
              <hr />
            </div>
          )}
          <div className="column is-12">
            <div className="columns is-gapless is-mobile confirmation__block has-vcentered">
              <div className="column is-offset-1 is-2 confirmation__icon-container">
                <div className="confirmation__icon">
                  <img src={returnistaBox} alt="confirmation" />
                </div>
              </div>
              <div className="column is-9">
                <p>{t('confirmation.parcels', { count: numberOfBoxes })}</p>
                {!gatekeeperEnabled && showAddressForm && (
                  <p className="confirmation_street_name">
                    {`${shipFromStreet || ''} ${shipFromHouseNumber || ''}`}
                  </p>
                )}
              </div>
            </div>
          </div>

          {isDropoff && (
            <div className="column is-12">
              <hr />
              <div className="columns is-gapless is-mobile confirmation__block">
                <div className="column is-offset-1 is-2 confirmation__icon-container">
                  <div className="confirmation__icon">
                    <img
                      src={pinIcon}
                      alt="confirmation"
                      className="confirmation__icon-pin"
                    />
                  </div>
                </div>
                <div className="column is-8 confirmation__dropoff">
                  <a target="blank" href={dropoffProviderUrl}>
                    {t('confirmation.provider_link', {
                      dropoffProviderName,
                    })}
                  </a>
                  <div className="confirmation__dropoff__arrow" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Comp>
  )
}

export default withNamespaces('translation')(ConfirmationContainer)
