import React from 'react'
import { formatDateAsHour } from 'lib/dateHelpers'
import cn from 'classnames'

function Button({ start, stop, isSelected, isDefault }) {
  return (
    <button
      type="button"
      className={cn('slot-button', {
        'slot-button--active': isSelected,
        default: isDefault,
      })}
    >
      {`${formatDateAsHour(start)} - ${formatDateAsHour(stop)}`}
    </button>
  )
}

export default Button
