import { ORDER } from 'features/order/actions'

export const INITIAL_STATE = {
  id: null,
  numberOfBoxes: null,
  size: 'parcel',

  // DHL
  postalCode: null,
  postalCodeEnd: null,
  houseNumber: null,
  houseAddition: null,

  shipFromStreet: null, // common fields for all carriers

  // UPS
  shipFromPostalCode: null,
  shipFromCity: null,
  shipFromAddressLine: null,
  shipFromStateProvinceCode: null,

  // pickup
  timeslotId: null,
  start: null,
  stop: null,

  // common for all return types
  name: null,
  email: null,
  orderNumber: null,
  comments: null,
  termsAndConditions: false,
  insured: false,
  network: null,
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case ORDER.SET_ORDER:
    case ORDER.GET_ORDER_OK:
    case ORDER.UPDATE_SHIPMENT_OK: {
      return {
        ...state,
        ...payload,
      }
    }

    case ORDER.CLEAR_ORDER: {
      return INITIAL_STATE
    }

    default:
      return state
  }
}
