import React from 'react'
import classnames from '../../lib/classnames'
import Arrow from '../Arrow'

function CtaButton({
  text,
  arrow,
  className,
  disabled,
  onClick,
  submitting,
  type,
  buttonLength,
}) {
  const buttonClassNames = classnames({
    'cta-button': true,
    'cta-button--disabled': disabled === true,
    button: true,
    'is-loading': submitting,
    [className]: className,
  })

  const style = {}

  if (buttonLength) style.width = `${buttonLength}ch`

  const newOnClick = disabled ? () => {} : onClick

  return (
    <button
      disabled={disabled}
      onClick={newOnClick}
      type={type}
      className={buttonClassNames}
      style={style}
    >
      <span className="button__text">{text}</span>
      {arrow && !submitting ? <Arrow direction={arrow} /> : ''}
    </button>
  )
}

export default CtaButton
