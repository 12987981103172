// voordeelvanger
export default {
  '--voordeelvanger-green': 'hsl(145, 63%, 49%)',
  '--voordeelvanger-green--darker': 'hsl(131, 34%, 44%)',
  '--voordeelvanger-pink': 'hsl(345, 77%, 57%)',

  fontFamilies: ['Montserrat:600,700,800,900'],
  '--font-family-primary': 'Montserrat',
  '--font-family-headers': 'Montserrat',
  '--font-weight-normal': '600',
  '--font-weight-h1': '700',
  '--font-weight-h2': '700',
  '--font-weight-h3': '700',
  '--font-weight-extra-bold': '800',

  '--headers-text-color': 'hsl(220, 11%, 43%)',

  '--prm-btn-bg-color': 'var(--voordeelvanger-green)',
  '--prm-btn-text-color': 'hsl(0, 0%, 100%)',
  '--prm-btn-bg-color--hover': 'var(--voordeelvanger-green--darker)',
  '--prm-btn-text-color--hover': 'hsl(0, 0%, 100%)',
  '--prm-btn-bg-color--disabled': 'hsl(219, 14%, 81%)',
  '--prm-btn-text-color--disabled': 'hsl(0, 0%, 100%)',

  '--scn-text-color': 'var(--voordeelvanger-green--darker)',
  '--scn-border-color': 'var(--voordeelvanger-green)',

  '--error-text-color': 'hsl(37, 91%, 55%)',
  '--error-border-color': 'var(--error-text-color)',
  '--error-bg-color': 'hsla(37, 91%, 55%, 0.2)',

  '--input-text-color': 'hsl(224, 100%, 50%)',

  '--main-gradient-color-1': 'var(--voordeelvanger-pink)',
  '--main-gradient-color-1--hover': 'var(--voordeelvanger-pink)',
  '--main-gradient-color-2': 'var(--voordeelvanger-pink)',
  '--main-gradient-color-2--hover': 'var(--voordeelvanger-green--darker)',
  '--main-gradient':
    'linear-gradient(to bottom right, var(--main-gradient-color-1), var(--main-gradient-color-2))',
  '--main-gradient--hover':
    'linear-gradient(to bottom right, var(--main-gradient-color-1--hover), var(--main-gradient-color-2--hover))',

  '--scn-gradient-color-1': 'var(--voordeelvanger-green--darker)',
  '--scn-gradient-color-1--hover': 'var(--voordeelvanger-green)',
  '--scn-gradient-color-2': 'var(--voordeelvanger-green)',
  '--scn-gradient-color-2--hover': 'var(--voordeelvanger-green)',
  '--scn-gradient':
    'linear-gradient(to bottom right, var(--scn-gradient-color-1), var(--scn-gradient-color-2))',
  '--scn-gradient--hover':
    'linear-gradient(to bottom right, var(--scn-gradient-color-1--hover), var(--scn-gradient-color-2--hover))',

  '--main-text-color': 'hsl(220, 11%, 43%)',
  '--main-text-color--light': 'hsl(0, 0%, 100%)',
  '--main-bg-color--hover': 'hsla(220, 27%, 70%, 0.1)',
  '--main-bg-color': 'hsl(0, 0%, 100%)',
  '--main-border-color': 'hsl(220, 11%, 43%)',

  '--progress-bar-bg-color': 'var(--voordeelvanger-pink)',
  '--progress-bar-bg-color--active': 'var(--voordeelvanger-pink)',
  '--progress-bar-step-bg-color': 'hsl(260, 36%, 76%)',
  '--progress-bar-step-bg-color--active': 'var(--main-gradient-color-2)',
  '--progress-bar-gradient-color-1': 'var(--voordeelvanger-pink)',
  '--progress-bar-gradient-color-2': 'var(--voordeelvanger-pink)',
  '--progress-bar-step-checked-bg-color': 'var(--voordeelvanger-pink)',

  '--confirmation-box-bg-color': 'hsl(220, 46%, 95%)',

  '--box-border-color': 'hsla(220, 27%, 70%, 0.1)',
  '--box-box-shadow-color': 'rgba(black, 0.75)',
  '--box-text-color--active': 'var(--voordeelvanger-green)',
  '--box-border-color--active': 'var(--voordeelvanger-green)',

  '--parcel-btn-bg-color--hover': 'var(--voordeelvanger-green)',

  '--network-btn-bg-color': 'var(--voordeelvanger-green)',
  '--network-btn-bg-color--active': 'var(--voordeelvanger-green)',
  '--network-btn-border-color': 'var(--voordeelvanger-green)',
  '--network-btn-box-shadow-color': 'hsla(220, 11%, 43%, 0.15)',

  '--brand-color-1': 'var(--voordeelvanger-green)',
  '--brand-color-2': 'var(--voordeelvanger-pink)',
  '--brand-text-color': 'var(--voordeelvanger-green)',

  '--language-bg-color': 'hsl(220, 46%, 95%)',
}
