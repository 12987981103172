import React from 'react'
import FieldFile from 'components/FieldFile'
import FieldWrapper from 'components/FieldWrapper'
import { isFieldDirty } from 'lib/formHelpers'
import { SHIPMENT_FIELDS } from 'config/constants'
import { get } from 'lib/lodash'

const { IMAGE } = SHIPMENT_FIELDS

export default function Image(props) {
  const { errors, values, touched, t } = props
  const value = get(values, IMAGE)
  const error = get(errors, IMAGE)

  const imageProps = {
    ...props,
    id: IMAGE,
    name: IMAGE,
    type: 'image',
    label: t('details.form.image.label'),
    isFieldDirty: isFieldDirty(touched, IMAGE),
    value,
  }

  return (
    <FieldWrapper errors={error} filled={value} classNames="control-no-hr">
      <FieldFile {...imageProps} />
    </FieldWrapper>
  )
}
