import { get, orderBy } from 'lib/lodash'
import { getTransKey, langCode } from 'lib/i18n'
import shopSettings from 'config/shopSettings'

const formatReturnReasonsIndex = (optionsIndex) => {
  const newOptionsIndex = {}

  Object.keys(optionsIndex).forEach((key) => {
    const returnReason = optionsIndex[key]
    const newReturnReason = {
      ...returnReason,
      value: returnReason.value.toString(),
    }
    newOptionsIndex[key] = newReturnReason
  })

  return newOptionsIndex
}

export const getOtherOption = ({ shop, t }) => {
  const transKey = getTransKey('details.form.returnReasonId.other', shop)
  const otherOption = {
    text: t(transKey),
    value: '0',
  }

  return shop.showOtherReturnReason ? otherOption : undefined
}

export function fetchReturnReasons({ shop, otherOption }) {
  const rawOptionsIndex = get(shop, `returnReasons.${langCode()}`, {})
  const formattedOptionsIndex = formatReturnReasonsIndex(rawOptionsIndex)
  let formattedOptions = Object.values(formattedOptionsIndex)
  const sortByText = shopSettings('sortReturnReasons', shop, false)
  if (sortByText)
    formattedOptions = orderBy(formattedOptions, ['text'], ['asc'])

  if (otherOption) {
    formattedOptionsIndex[0] = otherOption
    formattedOptions.push(otherOption)
  }

  return {
    raw: { index: rawOptionsIndex },
    formatted: {
      index: formattedOptionsIndex,
      options: formattedOptions,
    },
  }
}
