import React from 'react'
import { styled, respondTo } from 'lib/styled'
import { withNamespaces } from 'react-i18next'
import { get, isNil, uniq } from 'lib/lodash'
import { capitalize } from 'lib/helpers'
import i18n, { getTransKey, getTransKeyForShop } from 'lib/i18n'
import Title from 'components/Title'
import useOrder from 'hooks/useOrder'
import { NETWORKS, RETURN_TYPES } from 'config/constants'
import shopSettings from 'config/shopSettings'
import NetworkButton from './NetworkButton'

const Container = styled.div`
  margin-top: 2.8125rem;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
  align-items: ${({ vertical }) => (vertical ? 'flex-start' : 'center')} '';
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: '100%';
  margin-right: 0;

  gap: 0.5rem;

  ${respondTo.md`
    width: ${({ withNotes }) => (withNotes ? '100%' : 'calc(50% - .5rem)')};
    flex-direction: row;
    margin-right: ${({ withMargin }) => (withMargin ? '.5rem' : 0)};  
  `}
`

const PackageNotes = styled.div`
  width: 50%;
  position: relative;
  bottom: 2rem;

  @media (max-width: 820px) {
    width: 90%;
    position: unset;
    margin-bottom: 0.5rem;
  }
`

export const compare = (a, b) => {
  if (a > b) {
    return -1
  }
  if (a < b) {
    return 1
  }
  return 0
}

function NetworkFields(props) {
  const {
    noData,
    shop,
    order,
    isInternational,
    isLocal,
    upsPaperless,
    pricelists: orderPricelists,
    isDropoff,
    isPickup,
  } = useOrder()
  if (noData) return null

  const { network: selectedNetwork, setNetwork: setSelectedNetwork, t } = props
  const { returnType, size, shipFromCountryCode, countrySetFromForm } = order

  const getPrice = (pl) => {
    const priceName = `${returnType}${capitalize(size)}Price`
    const basePrice = get(pl, priceName, 0)
    if (isNil(basePrice)) return -1 // meaning do not use this network

    return basePrice
  }

  const payloads = []
  const networksPricelists = {}
  const networkPriceTotals = { possiblePrices: {} }
  orderPricelists.forEach((pl) => {
    const networkPricelists = networksPricelists[pl.network] || []
    networkPricelists.push(pl)

    networksPricelists[pl.network] = networkPricelists
    const pricelistPrice = getPrice(pl)

    const { possiblePrices } = networkPriceTotals
    const networkPossiblePrices = possiblePrices[pl.network] || []
    networkPossiblePrices.push(pricelistPrice)
    possiblePrices[pl.network] = uniq(networkPossiblePrices)
    networkPriceTotals.possiblePrices = possiblePrices

    networkPriceTotals.all = get(networkPriceTotals, 'all', 0) + pricelistPrice
  })

  Object.keys(networksPricelists).forEach((network) => {
    const networkPricelists = networksPricelists[network]
    let chosenPricelist
    if (!countrySetFromForm)
      chosenPricelist = networkPricelists.find(
        (pl) => pl.countryCode === shipFromCountryCode,
      )

    const possiblePrices = networkPriceTotals.possiblePrices[network]
    let price
    if (possiblePrices.length === 1) price = getPrice(networkPricelists[0])
    if (chosenPricelist) price = getPrice(chosenPricelist)
    const paperlessUps = network === NETWORKS.UPS && upsPaperless
    const homerrPaperless = network === NETWORKS.HOMERR
    const dhlPaperless =
      network === NETWORKS.DHL &&
      isLocal &&
      returnType === RETURN_TYPES.DROPOFF &&
      get(shop, 'dhlPaperlessEnabled')

    const paperless = paperlessUps || homerrPaperless || dhlPaperless

    payloads.push({
      price,
      network,
      active: selectedNetwork === network,
      paperless,
    })
  })
  //  }

  const withNotes = shopSettings('networkButtonsWithNotes', shop)
  const vertical = shopSettings('verticalNetworkButtons', shop)
  const parcelSize = isInternational ? '100x50x50cm' : '180x100x50cm'
  const titleTransKey = t(getTransKey('network.form.title', shop))
  const sentenceKey = getTransKeyForShop('network.form.sentence', shop)

  return (
    <Container>
      <Title text={`${t(titleTransKey)}`} />
      {i18n.exists(sentenceKey) && <h4>{t(sentenceKey)}</h4>}
      <ButtonsContainer vertical={vertical}>
        {payloads.map((p, index) => (
          <ButtonContainer
            key={p.network}
            withNotes={withNotes}
            withMargin={index + 1 < payloads.length}
          >
            {withNotes && (
              <PackageNotes key={`package-note-${p.network}`}>
                <ul style={{ listStyle: 'initial' }}>
                  <li>
                    {t(`network.form.sizeNote.${p.network}`, {
                      size: parcelSize,
                    })}
                  </li>
                  <li>{t(`network.form.weightNote.${p.network}`)}</li>
                  {isLocal && <li>{t('network.form.replacementNote')}</li>}
                </ul>
              </PackageNotes>
            )}
            <NetworkButton
              shop={shop}
              key={`network-button-${p.network}`}
              network={p.network}
              price={p.price}
              active={p.active}
              paperless={p.paperless}
              handleOnClick={setSelectedNetwork}
              withNotes={withNotes}
              isPickup={isPickup}
              isDropoff={isDropoff}
            />
          </ButtonContainer>
        ))}
      </ButtonsContainer>
      {i18n.exists(`confirmation.shopNote.${shop.frontendAssetId}`) && (
        <h3 style={{ marginTop: '1.5rem' }}>
          {t(`confirmation.shopNote.${shop.frontendAssetId}`)}
        </h3>
      )}
    </Container>
  )
}

export default withNamespaces('translation')(NetworkFields)
