import React from 'react'
import CheckBox from 'components/CheckBox'
import { isFieldDirty } from 'lib/formHelpers'
import { getTermsAndConditionsUrl } from 'lib/helpers'
import { SHIPMENT_FIELDS } from 'config/constants'

export default function TermsAndConditions(props) {
  const { touched, shop, t } = props
  const termsAndConditionsProps = {
    ...props,
    name: SHIPMENT_FIELDS.TERMS_AND_CONDITIONS,
    label: t('details.form.terms_conditions.label'),
    linkText: t('details.form.terms_conditions.link_text'),
    linkUrl: getTermsAndConditionsUrl(shop),
    isFieldDirty: isFieldDirty(touched, SHIPMENT_FIELDS.TERMS_AND_CONDITIONS),
    wrapperStyle: { display: 'block' },
    labelStyle: { display: 'inline' },
  }

  return <CheckBox {...termsAndConditionsProps} />
}
