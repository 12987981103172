import React from 'react'
import {
  Router as BrowserRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import ConfirmationContainer from 'containers/Confirmation'
import ProceedContainer from 'containers/Proceed'
// import EditContainer from 'containers/Edit'
import DetailsLayout from 'components/DetailsLayout'
import DetailsContainer from 'containers/Details'
import ResetWindowScroll from 'components/ResetWindowScroll'
import ErrorContainer from 'containers/Error'
import LandingPage from 'containers/LandingPage'
import ParcelsContainer from 'containers/ParcelsAndNetwork'
import TimeslotContainer from 'containers/Timeslots'
import AddressContainer from 'containers/Address'
import ReturnType from 'containers/ReturnType'
import Footer from 'components/Layout/Footer'
import SwitchLanguage from 'components/Layout/SwitchLanguage'
import Logo from 'components/Layout/Logo'
import { FLOWS, PATHS } from 'config/constants'
import { useSelector } from 'react-redux'
import { getApp } from 'lib/selectors'
import history from './history'

const routes = (
  <>
    <Route path="/" exact component={LandingPage} />
    <Route
      path="/return-type/:countryCode"
      exact
      component={ResetWindowScroll(ReturnType)}
    />
    <Route
      path="/return-type"
      exact
      component={ResetWindowScroll(ReturnType)}
    />
    {Object.values(FLOWS).map((path) => (
      <>
        <Route
          exact
          path={`/${path}/${PATHS.PARCELS}`}
          render={(props) => (
            <DetailsLayout>
              <ParcelsContainer {...props} />
            </DetailsLayout>
          )}
        />
        <Route
          exact
          path={`/${path}/${PATHS.TIMESLOT}`}
          render={(props) => (
            <DetailsLayout>
              <TimeslotContainer {...props} />
            </DetailsLayout>
          )}
        />
        <Route
          exact
          path={`/${path}/${PATHS.ADDRESS}`}
          render={(props) => (
            <DetailsLayout>
              <AddressContainer {...props} />
            </DetailsLayout>
          )}
        />
        <Route
          exact
          path={`/${path}/${PATHS.DETAILS}`}
          render={(props) => (
            <DetailsLayout>
              <DetailsContainer {...props} />
            </DetailsLayout>
          )}
        />
      </>
    ))}
    <Route path="/confirmation/:id" component={ConfirmationContainer} />
    <Route path="/proceed/:id" component={ProceedContainer} />
    <Route
      path="/edit/:id"
      render={(props) => (
        <Redirect to={`/confirmation/${props.match.params.id}`} />
      )}
    />
    <Route path="/error/:code" component={ResetWindowScroll(ErrorContainer)} />
    <Route exact path="/r/new" component={ResetWindowScroll(LandingPage)} />
  </>
)

function Routes() {
  const { error: errorCode } = useSelector(getApp)
  return (
    <BrowserRouter history={history}>
      <div className="App">
        <div className="content">
          <header className="App-header section">
            <div className="container">
              <div className="columns is-mobile sections">
                <div className="column">
                  <Logo />
                </div>
                <div className="column is-one-quarter switch-language-section">
                  <SwitchLanguage />
                </div>
              </div>
            </div>
          </header>
          <section className="main section" style={{ flexGrow: 1 }}>
            <div className="container">
              <Switch>{errorCode ? <ErrorContainer /> : routes}</Switch>
            </div>
          </section>
        </div>
        <Footer className="footer" />
      </div>
    </BrowserRouter>
  )
}

export default Routes
