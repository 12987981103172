// ROOT
export default {
  fontFamilies: ['Nunito:700,800,900'],
  '--font-family-primary': 'Nunito',
  '--font-family-headers': 'Nunito',
  '--font-weight-normal': '700',
  '--font-weight-h1': '800',
  '--font-weight-h2': '800',
  '--font-weight-h3': '800',
  '--font-weight-extra-bold': '900',

  '--headers-text-color': 'hsl(220, 11%, 43%)',
  '--headers-text-color--dimmed': 'hsla(220, 27%, 70%)',
  '--a-color': 'hsl(217, 71%, 53%)',

  '--main-text-color': 'hsl(220, 11%, 43%)',
  '--main-text-color--light': 'hsl(0, 0%, 100%)',
  '--main-bg-color--hover': 'hsla(220, 27%, 70%, 0.1)',
  '--main-text-color--disabled': 'hsl(0, 0%, 48%)',
  '--main-bg-color': 'hsl(0, 0%, 100%)',
  '--main-border-color': 'hsl(220, 11%, 43%)',

  '--scn-text-color': 'hsl(220, 27%, 70%)',
  '--scn-border-color': 'var(--scn-text-color)',

  '--main-gradient-color-1': 'hsl(272, 64%, 50%)',
  '--main-gradient-color-1--hover': 'hsl(272, 64%, 50%)',
  '--main-gradient-color-2': 'hsl(252, 100%, 50%)',
  '--main-gradient-color-2--hover': 'hsl(252, 100%, 50%)',
  '--main-gradient':
    'linear-gradient(to bottom right, var(--main-gradient-color-1), var(--main-gradient-color-2))',
  '--main-gradient--hover':
    'linear-gradient(to bottom right, var(--main-gradient-color-1--hover), var(--main-gradient-color-2--hover))',

  '--scn-gradient-color-1': 'hsl(272, 64%, 50%)',
  '--scn-gradient-color-1--hover': 'hsl(272, 64%, 50%)',
  '--scn-gradient-color-2': 'hsl(252, 100%, 50%)',
  '--scn-gradient-color-2--hover': 'hsl(252, 100%, 50%)',
  '--scn-gradient':
    'linear-gradient(to bottom right, var(--scn-gradient-color-1), var(--scn-gradient-color-2))',
  '--scn-gradient--hover':
    'linear-gradient(to bottom right, var(--scn-gradient-color-1--hover), var(--scn-gradient-color-2--hover))',

  '--prm-btn-border-radius': '1000rem',
  '--prm-btn-bg-color': 'hsl(57, 100%, 50%)',
  '--prm-btn-bg-color--hover': 'var(--prm-btn-bg-color)',
  '--prm-btn-bg-color--disabled': 'hsl(219, 14%, 81%)',

  '--prm-btn-text-color': 'hsl(220, 11%, 43%)',
  '--prm-btn-text-color--hover': 'var(--prm-btn-text-color)',
  '--prm-btn-text-color--disabled': 'hsl(0, 0%, 100%)',

  '--prm-btn-border-width': 0,
  '--prm-btn-border-box-shadow':
    '0rem 1.2rem 2.875rem -0.9375rem hsla(0, 0%, 0%, 0.35)',

  '--scn-btn-bg-color': 'var(--main-bg-color)',
  '--scn-btn-bg-color--active': 'var(--scn-gradient)',
  '--scn-btn-bg-color--hover': 'var(--scn-gradient-color-2)',
  '--scn-btn-border-color': 'var(--scn-text-color)',
  '--scn-btn-border-color--active': 'var(--scn-btn-bg-color)',
  '--scn-btn-border-color--hover': 'var(--scn-btn-bg-color)',
  '--scn-btn-text-color': 'var(--scn-text-color)',
  '--scn-btn-text-color--active': 'var(--main-bg-color)',
  '--scn-btn-text-color--hover': 'var(--main-bg-color)',

  '--error-text-color': 'hsl(37, 91%, 55%)',
  '--error-border-color': 'var(--error-text-color)',
  '--error-bg-color': 'hsla(37, 91%, 55%, 0.2)',

  '--input-text-color': 'hsl(224, 100%, 50%)',

  '--footer-bg-color-1': 'var(--main-gradient-color-1)',
  '--footer-bg-color-2': 'var(--main-gradient-color-2)',
  '--footer-gradient':
    'linear-gradient(to bottom right, var(--footer-bg-color-1), var(--footer-bg-color-2))',
  '--footer-text-color': 'var(--main-text-color--light)',
  '--footer-logo-color': 'var(--main-text-color--light)',

  '--progress-bar-bg-color': 'hsl(252, 100%, 50%)',
  '--progress-bar-bg-color--active': 'var(--progress-bar-bg-color)',
  '--progress-bar-step-bg-color': 'hsl(260, 36%, 76%)',
  '--progress-bar-step-bg-color--active': 'var(--main-gradient-color-2)',
  '--progress-bar-gradient-color-1': 'hsl(184, 100%, 50%)',
  '--progress-bar-gradient-color-2': 'hsl(271, 100%, 50%)',
  '--progress-bar-step-checked-bg-color': 'hsl(234, 100%, 65%)',

  '--confirmation-box-bg-color': 'hsl(220, 46%, 95%)',
  '--box-border-color': 'hsla(220, 27%, 70%, 0.1)',
  '--box-box-shadow-color': 'rgba(black, 0.75)',

  '--box-text-color--active': 'hsl(224, 100%, 47%)',
  '--box-border-color--active': 'var(--box-text-color--active)',

  '--parcel-btn-bg-color--hover': 'var(--main-gradient-color-2)',
  '--parcel-btn-text-color--hover': 'var(--scn-text-color)',

  '--network-btn-bg-color': 'hsl(268, 70%, 49%)',
  '--network-btn-bg-color--active': 'hsl(224, 100%, 47%)',
  '--network-btn-border-color': 'hsl(268, 70%, 49%)',
  '--network-btn-box-shadow-color': 'hsla(220, 11%, 43%, 0.15)',

  '--network-btn-badge-text-color': 'var(--main-text-color)',
  '--network-btn-badge-text-color--active': 'var(--main-text-color--light)',
  '--network-btn-badge-bg-color': 'var(--box-border-color)',
  '--network-btn-badge-bg-color--active': 'var(--network-btn-border-color)',

  '--network-btn-label-bg-color': 'var(--main-bg-color)',
  '--network-btn-label-bg-color--active': 'var(--main-bg-color--hover)',
  '--network-btn-label-text-color': 'var(--main-text-color)',
  '--network-btn-label-text-color--ative': 'var(--main-text-color--light)',

  '--brand-color-1': 'hsl(272, 64%, 49%)',
  '--brand-color-2': 'hsl(252, 100%, 50%)',
  '--brand-text-color': 'hsl(272, 99%, 54%)',

  '--language-bg-color': 'hsl(220, 46%, 95%)',

  '--input-check-bg-color': 'var(--brand-text-color)',
  '--input-check-text-color': 'white',

  '--field-select-text-color': 'var(--main-text-color)',
  '--field-select-text-color--active': 'var(--field-select-text-color)',
  '--field-select-bg-color': 'white',
  '--field-select-bg-color--active': 'var(--field-select-bg-color)',

  '--background': 'none',
  '--price-color': 'var(--main-text-color)',
}
