import React from 'react'

export default function ({
  errors,
  filled,
  hidden,
  classNames = '',
  children,
  style = {},
}) {
  return (
    <div
      className={`control ${errors ? 'error' : ''} ${filled ? 'filled' : ''} ${
        hidden ? 'hidden' : ''
      } ${classNames} `}
      style={style}
    >
      {children}
    </div>
  )
}
