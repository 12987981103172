import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { Form, Formik } from 'formik'
import { dispatch } from 'store'
import { get, isNil, omitBy } from 'lib/lodash'
import { withNamespaces } from 'react-i18next'
import i18n, { getTransKeyWODefault } from 'lib/i18n'
import orderActions from 'features/order/actions'
import CtaButton from 'components/CtaButton'
import { detailsSchema } from 'lib/validationSchemas/details'
import Price from 'components/Price'
import Title from 'components/Title'
import { isBlank, populateWithBlanks } from 'lib/helpers'
import shopSettings from 'config/shopSettings'
import styled from 'styled-components'
import useOrder from 'hooks/useOrder'
import useHistory from 'hooks/useHistory'
import { SHIPMENT_FIELDS } from 'config/constants'
import DetailsFields from './Fields'

const PriceText = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
`

const { ID, PAYMENT_URL } = SHIPMENT_FIELDS

function DetailsContainer(props) {
  const { noData, shop, order, isDropoff, isPickup, price } = useOrder({
    clearOrderId: true,
  })

  useEffect(() => {
    if (noData) return
    if (showDescription) setItemAmount(get(order, 'itemAmount') || 1)
  }, [order])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const { goToConfirmation, goToUrl } = useHistory()
  const [itemAmount, setItemAmount] = useState(0)

  if (noData) return null

  const { t } = props
  const { showDescription } = shop
  const numberOfBoxes = get(order, 'numberOfBoxes')

  const createOrder = async (variables) => {
    let payload

    setIsSubmitting(true)

    if (isPickup) payload = await dispatch(orderActions.createPickup(variables))

    if (isDropoff)
      payload = await dispatch(orderActions.createDropoff(variables))

    const paymentUrl = get(payload, PAYMENT_URL)

    // payment redirect
    if (!isBlank(paymentUrl)) return goToUrl(paymentUrl)

    const orderId = get(payload, ID)
    goToConfirmation(orderId)
    return null
  }

  const onSubmit = async (values) => {
    const variables = {
      ...omitBy(order, isBlank),
      ...omitBy(values, isBlank),
    }

    await createOrder(variables)
  }

  const renderPrice = () => {
    let fullPrice = numberOfBoxes * price
    if (shopSettings('singlePrice', shop)) fullPrice = price
    if (isNil(price)) fullPrice = null
    const result = [<Price key="price" price={fullPrice} showFree />]
    const translationKey = getTransKeyWODefault('details.priceNote', shop)
    if (i18n.exists(translationKey))
      result.push(<PriceText>{t(translationKey)}</PriceText>)
    return result
  }

  return (
    <div>
      <Title text={`${t('details.title')}`} />
      <Formik
        initialValues={populateWithBlanks(order)}
        validateOnMount={order.termsAndConditions}
        validationSchema={() =>
          yup.lazy((values) => detailsSchema({ shop, values, itemAmount }))
        }
        onSubmit={onSubmit}
      >
        {(form) => {
          const { isValid, values, validateForm, setFieldValue } = form
          // console.log('DETAILS errors', form.errors)

          return (
            <Form>
              <DetailsFields
                form={form}
                itemAmount={itemAmount}
                incrItemAmount={() => {
                  setItemAmount(itemAmount + 1)
                  validateForm()
                }}
                decrItemAmount={(itemId) => {
                  const newItemAmount = itemAmount - 1
                  setItemAmount(newItemAmount < 1 ? 1 : newItemAmount)
                  validateForm()
                  setFieldValue(itemId, undefined)
                }}
              />
              <div className="field">{renderPrice()}</div>
              <div className="field center-content">
                <CtaButton
                  type="submit"
                  text={
                    isValid && values.termsAndConditions
                      ? t('details.form.valid')
                      : t('details.form.invalid')
                  }
                  disabled={
                    !(isValid && values.termsAndConditions) || isSubmitting
                  }
                  arrow="bottom"
                  submitting={isSubmitting}
                />
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default withNamespaces('translation')(DetailsContainer)
