import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getShopAndOrder } from 'lib/selectors'
import orderHelper from 'lib/orderHelper'
import { get, isEmpty, differenceWith, isEqual, isNil } from 'lib/lodash'
import { PostalCodeConstructor } from 'lib/postalCode'
import {
  SHIPMENT_FIELDS,
  NETWORKS,
  APPROVE_STATUSES,
  PAYMENT_STATUSES,
  LOCAL_ADDRESS_FIELDS,
  SHOP_FIELDS,
} from 'config/constants'
import { isBlank, getPricelistCategoryName, capitalize } from 'lib/helpers'
import { fetchReturnReasons } from 'containers/Details/helper'

const {
  RETURN_TYPE,
  NETWORK,
  SHIP_FROM_COUNTRY_CODE,
  APPROVE_STATUS,
  PROCEEDED,
  SIZE,
  PAYMENT_STATUS,
  LABEL_URL,
  RETURN_REASON_ID,
} = SHIPMENT_FIELDS

const { PAPERLESS_ORIGINS, PAPERLESS_AVAILABLE } = SHOP_FIELDS

const useOrder = (props = {}) => {
  const [price, setPrice] = useState()
  const result = useSelector(getShopAndOrder)
  let { order } = result

  const { shop } = result
  const returnReasonId = get(order, RETURN_REASON_ID)

  // PRICE
  const returnReasons = fetchReturnReasons({ shop })
  const makesShipmentFree = get(
    returnReasons.formatted.index,
    `${returnReasonId}.makesShipmentFree`,
  )

  useEffect(() => {
    if (!order) return

    const newPrice = calcPrice()
    setPrice(newPrice)
  }, [order, makesShipmentFree])

  if (!shop || isEmpty(shop)) return { noData: true }
  if (!order || isEmpty(order)) return { noData: true }

  const { values } = props
  if (values) order = { ...order, ...values }

  const allPricelists = get(shop, SHOP_FIELDS.PRICELISTS)
  if (!allPricelists) return { noData: true }

  const {
    gatekeeperEnabled,
    insuranceOptionEnabled,
    showReturnReasons,
    showDescription,
    requireDescription,
    showPersonalFieldsForDropoff,
  } = shop

  const availableServices = get(shop, SHOP_FIELDS.AVAILABLE_SERVICES)
  const paperlessOrigins = get(shop, PAPERLESS_ORIGINS)
  const paperlessAvailable = get(shop, PAPERLESS_AVAILABLE)
  const { insured } = order

  const haveReturnReasons = !isEmpty(returnReasons.raw.index)

  const paymentStatus = get(order, PAYMENT_STATUS)
  const isEditing = get(order, 'isEditing')
  const network = get(order, NETWORK)
  const shipFromCountryCode = get(order, SHIP_FROM_COUNTRY_CODE)
  const size = get(order, SIZE)
  const labelUrl = get(order, LABEL_URL)
  const noLabel = isBlank(labelUrl)
  const approveStatus = get(order, APPROVE_STATUS)
  const returnType = get(order, RETURN_TYPE)
  const proceeded = get(order, PROCEEDED)
  const {
    isPickup,
    isDropoff,
    isLocal,
    isInternational,
    showName,
    showPhone,
    showDefectField,
    showConsent,
    addressNeedsState,
  } = orderHelper({ shop, order, ...props })

  const isDhl = network && network.toLowerCase() === NETWORKS.DHL
  const isUps = network && network.toLowerCase() === NETWORKS.UPS
  const isDpd = network && network.toLowerCase() === NETWORKS.DPD
  const isHomerr = network && network.toLowerCase() === NETWORKS.HOMERR
  const isBrenger = network && network.toLowerCase() === NETWORKS.BRENGER
  const isDhlPickup = isDhl && isPickup
  const isUpsPickup = isUps && isPickup
  const isUpsDropoff = isUps && isDropoff
  const isDhlDropoff = isDhl && isDropoff
  const isDpdDropoff = isDpd && isDropoff
  const isApproved =
    !isNil(approveStatus) && approveStatus === APPROVE_STATUSES.APPROVED
  const mayProceed = gatekeeperEnabled && isApproved && !proceeded
  const approvePending = [APPROVE_STATUSES.PENDING].includes(approveStatus)
  const paymentFailed = PAYMENT_STATUSES.FAILED.includes(paymentStatus)
  const paymentPending = PAYMENT_STATUSES.PENDING.includes(paymentStatus)

  const pickupInternationalEnabled =
    isInternational && get(availableServices, 'internationalPickupParcel')
  const pickupLocalEnabled =
    isLocal && get(availableServices, 'localPickupParcel')
  const dropoffLocalEnabled =
    isLocal && get(availableServices, 'localDropoffParcel')
  const dropoffInternationalEnabled =
    isInternational && get(availableServices, 'internationalDropoffParcel')
  const dropoffEnabled = dropoffLocalEnabled || dropoffInternationalEnabled
  const pickupEnabled = pickupLocalEnabled || pickupInternationalEnabled
  const letterboxEnabled =
    isLocal && get(availableServices, 'localDropoffLetterbox')

  const pricelistCategoryName = getPricelistCategoryName({
    isInternational,
    returnType,
    size,
  })

  const pricelists = allPricelists[pricelistCategoryName]

  const pricelistCategoryNameForLocal = getPricelistCategoryName({
    isInternational: false,
    returnType,
    size,
  })
  const localPricelists = allPricelists[pricelistCategoryNameForLocal]

  // const isLocal = (value) => isLocalOrder({ value, shipFromCountryCode });
  // const isInternational = (value) => isInternationalOrder({ value, shipFromCountryCode });

  const { POSTAL_CODE, POSTAL_CODE_END } = LOCAL_ADDRESS_FIELDS
  const postalCode = PostalCodeConstructor(
    get(order, POSTAL_CODE),
    get(order, POSTAL_CODE_END),
  )

  let upsPaperless
  if (isInternational)
    upsPaperless =
      isDropoff &&
      differenceWith(paperlessOrigins, ['nl'], isEqual).length > 0 &&
      (!isBlank(shipFromCountryCode) ||
        (shipFromCountryCode !== 'other' &&
          paperlessOrigins.includes(shipFromCountryCode))) &&
      paperlessAvailable
  if (isLocal) {
    if (!paperlessOrigins) return { noData: true }
    upsPaperless =
      isDropoff && paperlessOrigins.includes('nl') && paperlessAvailable
  }

  const pricelist =
    pricelists &&
    pricelists.find(
      (pl) => pl.network === network && pl.countryCode === shipFromCountryCode,
    )

  const returnReasonsEnabled = showReturnReasons || gatekeeperEnabled
  const showReturnReason =
    !isEditing && returnReasonsEnabled && haveReturnReasons

  const needCustomReturnReason = !haveReturnReasons || +returnReasonId === 0

  const showOtherReturnReason = returnReasonsEnabled && needCustomReturnReason
  const chosenReturnReason =
    !isEditing && returnReasons.raw.index[returnReasonId]
  const showExchangeField =
    returnReasonsEnabled && get(chosenReturnReason, 'offersExchange', false)
  const showWrongItemField =
    returnReasonsEnabled && get(chosenReturnReason, 'wrongItem', false)

  const showCustomReturnReason =
    !isEditing &&
    (showOtherReturnReason ||
      showDefectField ||
      showExchangeField ||
      showWrongItemField)

  const showReturnAllItems = showDescription && !requireDescription
  const showInsured = !isEditing && insuranceOptionEnabled && !gatekeeperEnabled
  const showTermsAndConditions = !isEditing

  const calcPrice = () => {
    if (isNil(pricelist)) return undefined

    const insuredAddition = insured ? 199 : 0
    if (makesShipmentFree) return gatekeeperEnabled ? 0 : insuredAddition

    const priceName = `${returnType}${capitalize(size)}Price`
    let newPrice = get(pricelist, priceName, -1)
    if (newPrice >= 0) newPrice += insuredAddition
    return newPrice
  }

  let showAddressForm = isPickup
  if (isDropoff)
    showAddressForm = isLocal ? showPersonalFieldsForDropoff : isInternational

  return {
    shop,
    order,
    approveStatus,
    returnType,
    network,
    proceeded,
    isPickup,
    isDropoff,
    isDhl,
    isUps,
    isDpd,
    isHomerr,
    isBrenger,
    isUpsPickup,
    isUpsDropoff,
    isDhlPickup,
    isDhlDropoff,
    isDpdDropoff,
    isLocal,
    isInternational,
    isApproved,
    mayProceed,
    pricelistCategoryName,
    approvePending,
    paymentFailed,
    paymentPending,
    noLabel,
    size,
    shipFromCountryCode,
    postalCode,
    upsPaperless,
    pickupInternationalEnabled,
    pickupLocalEnabled,
    dropoffLocalEnabled,
    dropoffInternationalEnabled,
    dropoffEnabled,
    pickupEnabled,
    letterboxEnabled,
    pricelists,
    localPricelists,
    pricelist,
    showAddressForm,
    showName,
    showPhone,
    showCustomReturnReason,
    showReturnReason,
    showReturnAllItems,
    showDescription,
    showConsent,
    showInsured,
    showDefectField,
    showTermsAndConditions,
    addressNeedsState,
    price,
  }
}

export default useOrder
