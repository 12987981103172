import React from 'react'
import CtaButton from 'components/CtaButton'
import ConfirmationEmoji from 'components/ConfirmationEmoji'

function CheckEmail({ shop, t, showEmoji = true }) {
  const { shopUrl } = shop

  return (
    <>
      <div className="column is-10 is-offset-1 confirmation__message">
        {showEmoji && <ConfirmationEmoji />}
      </div>
      <div className="column is-10 is-offset-1 confirmation__message">
        <h2>{t('confirmation.checkEmail.header')}</h2>
        <p style={{ marginTop: '1rem' }}>{t('confirmation.checkEmail.body')}</p>
      </div>
      <br />
      <div className="columns is-mobile is-centered">
        <div className="column is-10 center-content">
          <CtaButton
            text={t('confirmation.return')}
            arrow="right"
            onClick={() => {
              window.location.href = shopUrl
            }}
          />
        </div>
      </div>
    </>
  )
}

export default CheckEmail
