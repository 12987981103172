import React from 'react'
import Arrow from 'components/Arrow'
import CtaButton from 'components/CtaButton'
import ShamedEmoji from 'components/ShamedEmoji'
import useOrder from 'hooks/useOrder'
import useHistory from 'hooks/useHistory'
import { isBlank } from 'lib/helpers'

function PaymentFailed(props) {
  const { t, showEmoji = true } = props
  const { noData, order, shop, isDhlPickup } = useOrder()
  const { goToEdit, goToUrl } = useHistory()
  if (noData) return null

  const { shopUrl } = shop
  const { id: orderId } = order
  return (
    <>
      <div className="column is-10 is-offset-1 confirmation__message">
        {showEmoji && <ShamedEmoji />}
        <h2>{t('confirmation.header-failure')}</h2>
        <h2>{t('confirmation.subtitle-failure')}</h2>
        <p>{t('confirmation.payment_status.failure')}</p>
      </div>

      {isDhlPickup && (
        <div className="columns is-mobile is-centered">
          <div className="column is-10 center-content">
            <CtaButton
              text={t('confirmation.edit')}
              arrow="right"
              onClick={() => goToEdit(orderId)}
            />
          </div>
        </div>
      )}

      {!isBlank(shopUrl) && (
        <div className="columns is-mobile is-centered">
          <div className="column is-10 center-content">
            <button
              type="button"
              className="confirmation__edit--link"
              onClick={() => goToUrl(shopUrl)}
            >
              <span className="button__text">
                {t('confirmation.cancel-return')}
              </span>
              <Arrow />
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default PaymentFailed
