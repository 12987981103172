import React from 'react'
import { withNamespaces } from 'react-i18next'
import useOrder from 'hooks/useOrder'
import ShipFromCountryCode from './Fields/ShipFromCountryCode'
import ShipFromPostalCode from './Fields/ShipFromPostalCode'
import ShipFromCity from './Fields/ShipFromCity'
import ShipFromAddressLine from './Fields/ShipFromAddressLine'
import ShipFromStateProvinceCode from './Fields/ShipFromStateProvinceCode'

function ForeignAddressFields(props) {
  const { noData, shop, order, addressNeedsState } = useOrder()
  if (noData) return null

  const { onCountryChange, form, t } = props

  const defaultProps = {
    ...form,
    shop,
    order,
    type: 'input',
    classNames: 'input',
    hidden: false,
    showErrorCharacter: true,
    t,
  }

  return (
    <>
      <div className="field">
        <ShipFromCountryCode {...defaultProps} onChange={onCountryChange} />
      </div>
      {addressNeedsState && (
        <div className="field">
          <ShipFromStateProvinceCode {...defaultProps} />
        </div>
      )}
      <div className="field is-horizontal is-grouped is-grouped-with-mobile">
        <div className="field">
          <ShipFromPostalCode {...defaultProps} />
        </div>

        <div className="field">
          <ShipFromCity {...defaultProps} />
        </div>
      </div>
      <div className="field">
        <ShipFromAddressLine {...defaultProps} />
      </div>
    </>
  )
}

export default withNamespaces('translation')(ForeignAddressFields)
