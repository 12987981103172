import React from 'react'
import PropTypes from 'prop-types'
import shocked from '../../assets/images/emoji/shocked.png'

function Emoji({ emoji, style = {} }) {
  return (
    <div className="shamed-emoji__container" style={style}>
      <div className="shamed-emoji__wrapper">
        <img alt="emoji" src={emoji} />
      </div>
    </div>
  )
}

Emoji.defaultProps = {
  emoji: shocked,
}

Emoji.propTypes = {
  emoji: PropTypes.string,
}

export default Emoji
