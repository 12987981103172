import React from 'react'
import FieldInput from 'components/FieldInput'
import FieldWrapper from 'components/FieldWrapper'
import { get } from 'lib/lodash'
import { isFieldDirty } from 'lib/formHelpers'
import { LOCAL_ADDRESS_FIELDS } from 'config/constants'

const { HOUSE_NUMBER } = LOCAL_ADDRESS_FIELDS

export default function HouseNumber(props) {
  const { errors, values, touched, t } = props
  const value = get(values, HOUSE_NUMBER)
  const error = get(errors, HOUSE_NUMBER)

  const houseNumberProps = {
    ...props,
    id: HOUSE_NUMBER,
    name: HOUSE_NUMBER,
    label: t('address.form.house_number.label'),
    type: 'number',
    pattern: '\\d*',
    isFieldDirty: isFieldDirty(touched, HOUSE_NUMBER),
    value,
  }

  return (
    <FieldWrapper
      errors={error}
      filled={value}
      classNames="is-expanded control--s"
    >
      <FieldInput {...houseNumberProps} />
    </FieldWrapper>
  )
}
