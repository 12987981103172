import React from 'react'
import FieldInput from 'components/FieldInput'
import FieldWrapper from 'components/FieldWrapper'
import { get } from 'lib/lodash'
import { isFieldDirty } from 'lib/formHelpers'
import { LOCAL_ADDRESS_FIELDS } from 'config/constants'
const { CITY } = LOCAL_ADDRESS_FIELDS

export default function City(props) {
  const { errors, values, touched, t } = props
  const value = get(values, CITY)
  const error = get(errors, CITY)

  const shipFromCityProps = {
    ...props,
    id: CITY,
    name: CITY,
    label: t('address.form.city.label'),
    disabled: true,
    isFieldDirty: isFieldDirty(touched, CITY),
    value,
  }

  return (
    <FieldWrapper
      errors={error}
      filled={value}
      hidden={shipFromCityProps.hidden}
    >
      <FieldInput {...shipFromCityProps} />
    </FieldWrapper>
  )
}
