import React from 'react'
import Lottie from 'react-lottie'
import animationData from 'assets/animations/flyingBox.json'
import shopSettings from 'config/shopSettings'
import { useSelector } from 'react-redux'
import { getShop } from 'lib/selectors'

export default function FlyingBox() {
  const shop = useSelector(getShop)

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
    },
  }

  const flyingBoxVisible =
    shop.gatekeeperEnabled === false && !shopSettings('hideFlyingBox', shop)
  if (!flyingBoxVisible) return null

  return (
    <div className="lottie-wrapper">
      <Lottie options={lottieOptions} height="300px" />
    </div>
  )
}
