import React from 'react'
import FieldInput from 'components/FieldInput'
import FieldWrapper from 'components/FieldWrapper'
import { isFieldDirty } from 'lib/formHelpers'
import { SHIPMENT_FIELDS } from 'config/constants'
import { get } from 'lib/lodash'

const { NAME } = SHIPMENT_FIELDS

export default function Name(props) {
  const { errors, values, touched, t } = props
  const value = get(values, NAME)
  const error = get(errors, NAME)

  const nameProps = {
    ...props,
    id: NAME,
    name: NAME,
    label: t('details.form.full_name.label'),
    isFieldDirty: isFieldDirty(touched, NAME),
    autoFocus: true,
    value,
  }

  return (
    <FieldWrapper errors={error} filled={value}>
      <FieldInput {...nameProps} />
    </FieldWrapper>
  )
}
