import { createAction } from 'features/actionUtils'
import { createPromiseAction } from '@adobe/redux-saga-promise'

export const ORDER = {
  SET_ORDER: 'ORDER_SET_ORDER',
  CLEAR_ORDER: 'ORDER_CLEAR_ORDER',

  CREATE_PICKUP: 'ORDER_CREATE_PICKUP',
  CREATE_PICKUP_FAIL: 'ORDER_CREATE_PICKUP_FAIL',

  CREATE_DROPOFF: 'ORDER_CREATE_DROPOFF',
  CREATE_DROPOFF_FAIL: 'ORDER_CREATE_DROPOFF_FAIL',

  GET_ORDER: 'ORDER_GET_ORDER',
  GET_ORDER_OK: 'ORDER_GET_ORDER_OK',
  GET_ORDER_FAIL: 'ORDER_GET_ORDER_FAIL',

  UPDATE_SHIPMENT: 'ORDER_UPDATE_SHIPMENT',
  UPDATE_SHIPMENT_OK: 'ORDER_UPDATE_SHIPMENT_OK',

  PROCEED: 'ORDER_PROCEED',
  PROCEED_OK: 'ORDER_PROCEED_OK',

  SET_STEP: 'ORDER_SET_STEP',
}

export default {
  setOrder: createAction(ORDER.SET_ORDER),
  clearOrder: createAction(ORDER.CLEAR_ORDER),

  createPickup: createPromiseAction(ORDER.CREATE_PICKUP),
  createPickupFail: createAction(ORDER.CREATE_PICKUP_FAIL),

  createDropoff: createPromiseAction(ORDER.CREATE_DROPOFF),
  createDropoffFail: createAction(ORDER.CREATE_DROPOFF_FAIL),

  getOrder: createPromiseAction(ORDER.GET_ORDER),
  getOrderOk: createAction(ORDER.GET_ORDER_OK),
  getOrderFail: createAction(ORDER.GET_ORDER_FAIL),

  updateShipment: createPromiseAction(ORDER.UPDATE_SHIPMENT),
  updateShipmentOk: createAction(ORDER.UPDATE_SHIPMENT_OK),
  updateShipmentFail: createAction(ORDER.UPDATE_SHIPMENT_FAIL),

  proceed: createPromiseAction(ORDER.PROCEED),
  proceedOk: createAction(ORDER.PROCEED_OK),
  proceedFail: createAction(ORDER.PROCEED_FAIL),
}
