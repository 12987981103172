import React from 'react'
import FieldInput from 'components/FieldInput'
import FieldWrapper from 'components/FieldWrapper'
import { get } from 'lib/lodash'
import { isFieldDirty } from 'lib/formHelpers'
import { LOCAL_ADDRESS_FIELDS } from 'config/constants'

const { HOUSE_ADDITION } = LOCAL_ADDRESS_FIELDS

export default function ShipFromHouseNumber(props) {
  const { errors, values, touched, t } = props
  const value = get(values, HOUSE_ADDITION)
  const error = get(errors, HOUSE_ADDITION)

  const houseAdditionProps = {
    ...props,
    id: HOUSE_ADDITION,
    name: HOUSE_ADDITION,
    label: t('address.form.house_addition.label'),
    required: false,
    isFieldDirty: isFieldDirty(touched, HOUSE_ADDITION),
    value,
  }

  return (
    <FieldWrapper
      errors={error}
      filled={value}
      classNames="is-expanded control--s"
    >
      <FieldInput {...houseAdditionProps} />
    </FieldWrapper>
  )
}
