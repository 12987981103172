// kellyjeans
export default {
  '--kelly-jeans-blue': 'hsl(212, 72%, 59%)',
  '--kelly-jeans-blue--darker': 'hsl(212, 72%, 49%)',
  '--kelly-jeans-black': 'hsl(0, 0%, 0%)',
  '--kelly-jeans-white': 'hsl(0, 0%, 100%)',

  '--headers-text-color': 'var(--kelly-jeans-black)',
  '--headers-text-color--dimmed': 'hsl(0, 0%, 25%)',
  '--a-color': 'var(--kelly-jeans-blue)',

  '--prm-btn-bg-color': 'var(--kelly-jeans-white)',
  '--prm-btn-text-color': 'var(--kelly-jeans-black)',
  '--prm-btn-text-color--hover': 'var(--kelly-jeans-blue)',
  '--prm-btn-text-color--disabled': 'var(--kelly-jeans-black)',
  '--prm-btn-bg-color--hover': 'var(--kelly-jeans-white)',
  '--prm-btn-bg-color--disabled': 'hsl(0, 0%, 93%)',
  '--prm-btn-border-color': 'var(--kelly-jeans-black)',
  '--prm-btn-border-color--hover': 'var(--kelly-jeans-blue)',
  '--prm-btn-border-color--disabled': 'var(--kelly-jeans-black)',
  '--prm-btn-border-width': '.2rem',

  '--main-gradient-color-1': 'var(--kelly-jeans-blue--darker)',
  '--main-gradient-color-1--hover': 'var(--kelly-jeans-blue--darker)',
  '--main-gradient-color-2': 'var(--kelly-jeans-blue)',
  '--main-gradient-color-2--hover': 'var(--kelly-jeans-blue)',

  '--footer-bg-color-1': 'var(--kelly-jeans-black)',
  '--footer-bg-color-2': 'var(--kelly-jeans-black)',

  '--footer-text-color': 'var(--kelly-jeans-white)',
  '--footer-logo-color': 'var(--kelly-jeans-white)',

  '--scn-gradient-color-1': 'var(--kelly-jeans-blue--darker)',
  '--scn-gradient-color-1--hover': 'var(--kelly-jeans-blue--darker)',
  '--scn-gradient-color-2': 'var(--kelly-jeans-blue)',
  '--scn-gradient-color-2--hover': 'var(--kelly-jeans-blue)',

  '--main-text-color': 'var(--kelly-jeans-black)',

  '--network-btn-bg-color': 'var(--kelly-jeans-black)',
  '--network-btn-border-color': 'var(--kelly-jeans-blue)',

  '--brand-color-1': 'var(--kelly-jeans-blue)',
  '--brand-color-2': 'var(--kelly-jeans-blue)',
  '--brand-text-color': 'var(--kelly-jeans-blue)',
}
