import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { dispatch } from 'store'
import { withNamespaces } from 'react-i18next'
import { compose } from 'recompose'
import ErrorBoundary from 'components/ErrorBoundary'
import { get, pick, isEqual, isEmpty } from 'lib/lodash'
import LoadingPage from 'containers/LoadingPage'
import { capitalize } from 'lib/helpers'
import Helmet from 'react-helmet'
import 'lib/numerals'
import shopActions from 'features/shop/actions'
import Router from 'Router'
import 'app.scss'
import { langCode } from 'lib/i18n'
import appActions from 'features/app/actions'

function App(props) {
  const { t, i18n, setFontsOk, setSettingsOk, error, locale } = props
  const [shop, setShop] = useState()
  const [loaded, setLoaded] = useState(false)
  const showErrorPage = Boolean(error)
  const currentLocale = langCode()

  const updateLoaded = () => {
    const newLoaded = setFontsOk && setSettingsOk && shop && !isEmpty(shop)
    setLoaded(newLoaded)
  }

  useEffect(() => {
    updateLoaded()
  }, [setFontsOk, setSettingsOk, error])

  useEffect(() => {
    if (locale) {
      if (currentLocale.toLowerCase() === locale.toLowerCase()) return
      i18n.changeLanguage(locale)
    } else {
      storeLanguage(currentLocale)
    }
  }, [locale, currentLocale])

  const storeLanguage = (lang) => dispatch(appActions.setLocale(lang))

  const [title, setTitle] = useState('Re:turnista')
  const [description, setDescription] = useState(t('general.titleDefault'))

  const setMeta = () => {
    const shopName = get(shop, 'name')

    if (shopName) {
      const titleKey = 'general.title'
      if (i18n.exists(titleKey))
        setTitle(t(titleKey, { shopName: capitalize(shopName) }))
      const descriptionKey = 'general.description'
      if (i18n.exists(descriptionKey))
        setDescription(t(descriptionKey, { shopName: capitalize(shopName) }))
    }
  }

  const getShop = async () => {
    setLoaded(false)
    const responseData = await dispatch(shopActions.getShop())
    if (responseData) setShop(responseData)
    setMeta()
  }

  useEffect(() => {
    getShop()
  }, [])

  if (!loaded && !showErrorPage) {
    return (
      <div className="loading-page">
        <LoadingPage />
      </div>
    )
  }

  return (
    <ErrorBoundary>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Router />
    </ErrorBoundary>
  )
}

const fields = ['setFontsOk', 'setSettingsOk', 'error', 'locale']

const mapStateToProps = (state) => pick(state.app, fields)

export default compose(
  withNamespaces('translation'),
  connect(mapStateToProps),
)(
  React.memo(App, (prevProps, nextProps) =>
    isEqual(pick(prevProps, fields), pick(nextProps, fields)),
  ),
)
