import React from 'react'
import { withNamespaces } from 'react-i18next'

export function Loading({ t }) {
  return (
    <div className="time-slots-loading__wrapper">
      <div className="time-slots-loading__jumbo">
        {t('time-slots.loading.jumbo')}
      </div>
    </div>
  )
}

export default withNamespaces('translation')(Loading)
