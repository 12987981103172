import React, { useEffect } from 'react'
import { withNamespaces } from 'react-i18next'
import { Formik, Form } from 'formik'
import { pick, every, isEmpty } from 'lib/lodash'
import CtaButton from 'components/CtaButton'
import Title from 'components/Title'
import { timeslotSchema } from 'lib/validationSchemas/timeslot'
import { isBlank } from 'lib/helpers'
import NotAvailable from 'containers/Timeslots/NotAvailable'
import Loading from 'containers/Timeslots/Loading'
import {
  SHIPMENT_FIELDS,
  NETWORKS,
  RETURN_TYPES,
  SIZES,
} from 'config/constants'
import useProceedApi from 'containers/Proceed/useProceedApi'
import useOrder from 'hooks/useOrder'
import useHistory from 'hooks/useHistory'
import { orderAction } from 'lib/actions'
import useTimeslotApi from './useTimeslotApi'
import Fields from './Fields'

const {
  NETWORK,
  POSTAL_CODE,
  RETURN_TYPE,
  NUMBER_OF_BOXES,
  TIMESLOT_ID,
  SIZE,
} = SHIPMENT_FIELDS

function TimeslotsContainer(props) {
  const {
    noData,
    order,
    isDropoff,
    mayProceed,
    isDhl,
    isInternational,
    postalCode,
  } = useOrder()

  const { goToConfirmation, goToLanding, goToAddress, goToNextStep } =
    useHistory()
  const { loading, requestTimeslots, timeslots } = useTimeslotApi()
  const { requestProceed } = useProceedApi()
  const { id: orderId, network, timeslotId } = order || {}

  useEffect(() => {
    if (isBlank(network)) return

    fetchTimeslots()
  }, [network])

  useEffect(() => {
    if (!timeslotId) return

    if (mayProceed) requestProceed()
  }, [order])

  if (noData) return null

  const { t } = props

  const fetchTimeslots = async () => {
    const variables = { [NETWORK]: network.toUpperCase() }
    if (isDhl && postalCode) variables[POSTAL_CODE] = postalCode.toUpperCase()

    await requestTimeslots(variables)
  }

  const populateEmptyFields = (values) => {
    const newValues = { ...order, ...values }
    const variables = pick(newValues, [
      SIZE,
      RETURN_TYPE,
      NETWORK,
      NUMBER_OF_BOXES,
    ])
    if (every(Object.values(variables).map((el) => !isBlank(el))))
      return newValues

    return {
      ...newValues,
      [SIZE]: SIZES.PARCEL,
      [RETURN_TYPE]: RETURN_TYPES.PICKUP,
      [NETWORK]: isInternational ? NETWORKS.UPS : NETWORKS.DHL,
      [NUMBER_OF_BOXES]: 1,
    }
  }

  const handleSubmit = (values) => {
    orderAction.setOrder(populateEmptyFields(values))
    goToNextStep()
  }

  if (!mayProceed && orderId) {
    goToConfirmation(orderId)
    return null
  }

  if (isDropoff) {
    goToLanding()
    return null
  }

  if (loading) return <Loading />

  if (!timeslots || isEmpty(timeslots)) {
    return <NotAvailable goBack={goToAddress} />
  }

  return (
    <div className="time-slot__container">
      <Title text={`${t('time-slots.title')}`} />
      <Formik
        initialValues={pick(order, [TIMESLOT_ID])}
        validationSchema={timeslotSchema}
        onSubmit={handleSubmit}
      >
        {(form) => {
          const { isSubmitting, isValid } = form
          return (
            <Form>
              <Fields timeslots={timeslots} form={form} />
              <div className="field center-content">
                <CtaButton
                  type="submit"
                  text={`${t('time-slots.cta')}`}
                  arrow="right"
                  disabled={isSubmitting || !isValid}
                  submitting={isSubmitting}
                  onClick={() => {}}
                />
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default withNamespaces('translation')(TimeslotsContainer)
