import { TIMESLOTS } from './actions'

export const INITIAL_STATE = []

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case TIMESLOTS.GET_TIMESLOTS_OK: {
      return payload
    }
    default: {
      return state
    }
  }
}
