import React from 'react'
import CheckBox from 'components/CheckBox'
import { isFieldDirty } from 'lib/formHelpers'
import { SHIPMENT_FIELDS } from 'config/constants'
const { CONSENT } = SHIPMENT_FIELDS

export default function Consent(props) {
  const { touched, t } = props
  const consentProps = {
    ...props,
    name: CONSENT,
    label: t('details.form.consent.label'),
    isFieldDirty: isFieldDirty(touched, CONSENT),
  }

  return <CheckBox {...consentProps} />
}
