import yup from './yup'
import { isBlank } from '../helpers'

export const parcels = {
  numberOfBoxes: yup.number().required('parcels.form.required_message'),
}
export const network = {
  network: yup.string().required('network.form.required_message'),
}

export const postalCodeRegex = /^[1-9][0-9]{3}$/i
export const postalCodeEndRegex = /^[A-z]{2}$/i
export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
export const nameRegex = /[A-z]/
export const houseNumberRegex = /[0-9]/
export const houseAdditionRegex = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/
export const streetNameRegex = /[A-z0-9]/
export const cityRegex = /[A-z0-9]/
export const phoneRegex = /^[\\+]?([0-9])+$/
export const wordAndNumbersRegex = /[A-z0-9]/

// shared
export const ParcelSchema = yup.object().shape(parcels)
export const NetworkSchema = yup.object().shape(network)

export const getFieldsUnder = (fields, fieldName) => {
  const index = fields.indexOf(fieldName)
  if (index === -1) return []

  const result = fields.slice(index + 1, fields.length - index)
  return result
}

export const anyFilled = (total, item) => total || !isBlank(item)
export const checkAnyFilled = (...rest) => {
  const result = rest.reduce(anyFilled)
  return (result instanceof Array && result) || true
}
