import { createPromiseAction } from '@adobe/redux-saga-promise'
import { createAction } from 'features/actionUtils'

export const SHOPS = {
  GET_SHOP: 'SHOPS_GET_SHOP',
  GET_SHOP_OK: 'SHOPS_GET_SHOP_OK',
}

export default {
  getShop: createPromiseAction(SHOPS.GET_SHOP),
  getShopOk: createAction(SHOPS.GET_SHOP_OK),
}
