import React from 'react'
import { withNamespaces } from 'react-i18next'
import { getTransKey } from 'lib/i18n'
import { get } from 'lib/lodash'
import { capitalize } from 'lib/helpers'

export function IntroTitle({ t, shop }) {
  return (
    <div className="intro-title">
      <h1 className="dimmed">
        {t(getTransKey('intro.pre', shop), {
          shopName: capitalize(get(shop, 'name')),
        })}{' '}
        <em>{t(getTransKey('intro.title', shop))}</em>
      </h1>
      <h1 className="dimmed">{t(getTransKey('intro.post', shop))}</h1>
    </div>
  )
}

export default withNamespaces()(IntroTitle)
