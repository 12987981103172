import { createPromiseAction } from '@adobe/redux-saga-promise'
import { createAction } from 'features/actionUtils'

export const ADDRESS = {
  VALIDATE_ADDRESS: 'ADDRESS_VALIDATE_ADDRESS',
  VALIDATE_ADDRESS_OK: 'ADDRESS_VALIDATE_ADDRESS_OK',
  VALIDATE_ADDRESS_FAIL: 'ADDRESS_VALIDATE_ADDRESS_FAIL',
}

export default {
  validateAddress: createPromiseAction(ADDRESS.VALIDATE_ADDRESS),
  validateAddressOk: createAction(ADDRESS.VALIDATE_ADDRESS_OK),
  validateAddressFail: createAction(ADDRESS.VALIDATE_ADDRESS_FAIL),
}
