import React from 'react'

export default (WrappedComponent) => {
  class ResetWindowScroll extends React.Component {
    componentDidMount() {
      window.scrollTo(0, 0)
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  return ResetWindowScroll
}
