import { takeLatest } from 'redux-saga/effects'

import { validateAddress } from 'features/address/saga'
import addressActions from 'features/address/actions'

import { setSettings, sendError } from 'features/app/saga'
import appActions from 'features/app/actions'

import {
  createPickup,
  createDropoff,
  getOrder,
  updateShipment,
  proceed,
} from 'features/order/saga'
import orderActions from 'features/order/actions'

import shopActions from 'features/shop/actions'
import { getShop } from 'features/shop/saga'

import timeslotActions from 'features/timeslots/actions'
import { getTimeslots } from 'features/timeslots/saga'

export default function* rootSaga() {
  yield takeLatest(addressActions.validateAddress, validateAddress)

  yield takeLatest(appActions.setSettings, setSettings)
  yield takeLatest(appActions.sendError, sendError)

  yield takeLatest(orderActions.createPickup, createPickup)
  yield takeLatest(orderActions.createDropoff, createDropoff)
  yield takeLatest(orderActions.getOrder, getOrder)
  yield takeLatest(orderActions.updateShipment, updateShipment)
  yield takeLatest(orderActions.proceed, proceed)

  yield takeLatest(shopActions.getShop, getShop)

  yield takeLatest(timeslotActions.getTimeslots, getTimeslots)
}
