import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import app, { INITIAL_STATE as appState } from 'features/app/reducer'
import order, { INITIAL_STATE as orderState } from 'features/order/reducer'
import shop, { INITIAL_STATE as shopState } from 'features/shop/reducer'
import timeslots, {
  INITIAL_STATE as timeslotsState,
} from 'features/timeslots/reducer'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    app,
    order,
    shop,
    timeslots,
  })

export const defaultState = {
  app: appState,
  order: orderState,
  shop: shopState,
  timeslots: timeslotsState,
}
