import { createPromiseAction } from '@adobe/redux-saga-promise'
import { createAction } from 'features/actionUtils'

export const APP = {
  SET_ERROR: 'APP_SET_ERROR',
  CLEAR_ERROR: 'APP_CLEAR_ERROR',
  SEND_ERROR: 'APP_SEND_ERROR',

  SET_SETTINGS: 'APP_SET_SETTINGS',
  SET_SETTINGS_OK: 'APP_SET_SETTINGS_OK',
  SET_FONTS_OK: 'APP_SET_FONTS_OK',

  SET_LOCALE: 'APP_SET_LOCALE',
  CLEAR_LOADING: 'APP_CLEAR_LOADING',
}

export default {
  setError: createAction(APP.SET_ERROR),
  clearError: createAction(APP.CLEAR_ERROR),
  sendError: createPromiseAction(APP.SEND_ERROR),

  setSettings: createPromiseAction(APP.SET_SETTINGS),
  setSettingsOk: createAction(APP.SET_SETTINGS_OK),
  setFontsOk: createAction(APP.SET_FONTS_OK),

  setLocale: createAction(APP.SET_LOCALE),
  clearLoading: createAction(APP.CLEAR_LOADING),
}
