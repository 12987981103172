import React from 'react'
import ReactSVG from 'react-svg'
import { getBranding } from 'lib/selectors'
import { useSelector } from 'react-redux'

function Logo() {
  const branding = useSelector(getBranding)
  const { logo, logoSvg, logoHeight } = branding || {}
  const style = { height: `${logoHeight}px`, maxHeight: `${logoHeight}px` }

  if (logoSvg) {
    return (
      <div className="store__logo">
        <ReactSVG src={logoSvg} style={style} />
      </div>
    )
  }

  return (
    <div className="store__logo">
      <img className="store__logo-logo" style={style} src={logo} alt="" />
    </div>
  )
}

export default Logo
