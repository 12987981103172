import React from 'react'
import ReactSVG from 'react-svg'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css' // optional
import info from '../../assets/icons/info.svg'
import './index.scss'

function InformationTooltip({ html, iconStyle }) {
  return (
    <Tippy
      content={html}
      placement="bottom"
      // trigger='click'
      animation="scale"
      theme="returnista"
      arrow={false}
      hideOnClick={false}
    >
      <div>
        <ReactSVG
          src={info}
          className="information-tooltip__icon"
          style={iconStyle}
        />
      </div>
    </Tippy>
  )
}

export default InformationTooltip
