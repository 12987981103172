import React from 'react'
import { useSelector } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import shopSettings from 'config/shopSettings'
import { getShop } from 'lib/selectors'
import { getTransKey } from 'lib/i18n'
import { styled, respondTo } from 'lib/styled'
import Title from 'components/Title'

const Container = styled.div`
  /* ... */
`
const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`

const activeStyles = `
    background: var(--scn-btn-bg-color--active);
    color: var(--scn-btn-text-color--active);
    border: none;
    box-shadow: none !important;
    padding: calc(0.375em + 2px) calc(0.75em + 3px);
`

const ParcelButton = styled.button`
  background: var(--scn-btn-bg-color);
  border: 0.1875rem solid var(--scn-btn-border-color);
  color: var(--scn-btn-text-color);
  letter-spacing: 0;
  text-align: center;
  font-weight: var(--font-weight-h2);

  font-size: 2.375rem;
  height: 5.125rem;
  width: 5.125rem;
  border-radius: 0.9375rem;
  max-width: 10rem;

  ${({ active }) => active && activeStyles};

  &:active,
  &:visited,
  &:hover,
  &:focus {
    ${activeStyles};
  }

  &:hover {
    background: var(--scn-btn-bg-color--hover);
    color: var(--scn-btn-text-color--hover);
  }

  ${respondTo.md`
    flex: 1 0 21%;
    margin: 0.3125rem;
  `};

  ${respondTo.sm`
    height: 7.375rem;
    width: 7.375rem;
    font-size: 3.4375rem;
    flex: 1 0 21%;
    margin: 0.3125rem;
  `}
`

function ParcelFields(props) {
  const shop = useSelector(getShop)
  const { orderValue, value, setValue, t } = props
  const maxParcels = shopSettings('maxParcels', shop, 4)
  const parcelButtons = []

  for (let number = 1; number <= maxParcels; number += 1) {
    parcelButtons.push(
      <ParcelButton
        className="button"
        type="button"
        tabIndex={number}
        key={number}
        number={number}
        active={number === (value || orderValue)}
        onClick={() => setValue(number)}
      >
        {number}
      </ParcelButton>,
    )
  }

  return (
    <Container>
      <Title text={`${t(getTransKey('parcels.title', shop))}`} />
      <ButtonsContainer className="buttons">{parcelButtons}</ButtonsContainer>
    </Container>
  )
}

export default withNamespaces('translation')(ParcelFields)
