import { SHOPS } from './actions'

export const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action = {}) => {
  const { payload, type } = action
  switch (type) {
    case SHOPS.GET_SHOP_OK:
      return payload
    default:
      return state
  }
}
