import React from 'react'
import FieldInput from 'components/FieldInput'
import FieldWrapper from 'components/FieldWrapper'
import { isFieldDirty, isBlank } from 'lib/formHelpers'
import { get, isNil } from 'lib/lodash'
import { SHIPMENT_FIELDS } from 'config/constants'
import { useSelector } from 'react-redux'
import { getBranding } from 'lib/selectors'

const { ORDER_NUMBER } = SHIPMENT_FIELDS

function OrderNumber(props) {
  const branding = useSelector(getBranding)
  if (!branding) return null

  const orderNumberRegex = get(branding, 'orderNumberRegex')

  const { errors, values, touched, t, shop } = props
  const value = get(values, ORDER_NUMBER)
  const error = get(errors, ORDER_NUMBER)

  const frontendAssetId = get(shop, 'frontendAssetId')
  const defaultFieldName = t('details.form.order_number.name.default')
  const fieldName = t(
    `details.form.order_number.name.${frontendAssetId}`,
    defaultFieldName,
  )

  const validateOrderNumber = (val) => {
    if (val && val.length > 50) return t('details.form.too_long', { max: 50 })
    if (isNil(orderNumberRegex)) return undefined
    if (isBlank(val)) return t('details.form.required_message')

    const matcher = new RegExp(orderNumberRegex)
    if (!matcher.test(val.toUpperCase()))
      return t('details.form.order_number.validation_message', {
        name: fieldName,
      })

    return undefined
  }

  const orderNumberProps = {
    ...props,
    id: ORDER_NUMBER,
    name: ORDER_NUMBER,
    label: t('details.form.order_number.label', { name: fieldName }),
    isFieldDirty: isFieldDirty(touched, ORDER_NUMBER),
    required: !isNil(orderNumberRegex),
    validate: validateOrderNumber,
    classNames: `${props.classNames} uppercase`,
    value,
  }

  return (
    <FieldWrapper errors={error} filled={value}>
      <FieldInput {...orderNumberProps} />
    </FieldWrapper>
  )
}
export default OrderNumber
