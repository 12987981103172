import React, { useEffect, useCallback, useState } from 'react'
import { dispatch } from 'store'
import { withNamespaces } from 'react-i18next'
import addressActions from 'features/address/actions'
import { get, pick, debounce, isEqual, isNil, some } from 'lib/lodash'
import { LOCAL_ADDRESS_FIELDS } from 'config/constants'
import { isBlank } from 'lib/helpers'
import styled from 'styled-components'
import PostalCode from './Fields/PostalCode'
import PostalCodeEnd from './Fields/PostalCodeEnd'
import HouseNumber from './Fields/HouseNumber'
import HouseAddition from './Fields/HouseAddition'
import Street from './Fields/Street'
import City from './Fields/City'

const ValidationError = styled.div`
  color: var(--error-text-color);
  background: var(--error-bg-color);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  margin-top: 2.5rem;
`

const {
  POSTAL_CODE,
  POSTAL_CODE_END,
  HOUSE_NUMBER,
  HOUSE_ADDITION,
  STREET,
  CITY,
} = LOCAL_ADDRESS_FIELDS

const CHANGEABLE_FIELDS = [
  POSTAL_CODE,
  POSTAL_CODE_END,
  HOUSE_NUMBER,
  HOUSE_ADDITION,
]
const VALIDATABLE_FIELDS = [POSTAL_CODE, POSTAL_CODE_END, HOUSE_NUMBER]

function LocalAddressFields(props) {
  const { form, setLoading, t } = props
  const { errors, values, setFieldValue, isSubmitting, touched } = form
  const editValues = pick(values, CHANGEABLE_FIELDS)
  const validatableValues = pick(values, VALIDATABLE_FIELDS)
  const [showValidationError, setShowValidationError] = useState(false)

  const validateAddress = async (variables) => {
    setLoading(true)

    try {
      const responseData = await dispatch(
        addressActions.validateAddress(variables),
      )

      setFieldValue(STREET, responseData.street)
      setFieldValue(CITY, responseData.city)
    } catch (e) {
      setShowValidationError(true)
    } finally {
      setLoading(false)
    }
  }

  const debouncedValidation = useCallback(debounce(validateAddress, 500), [])

  const [lastPayload, setLastPayload] = useState()

  const handleChange = async () => {
    setShowValidationError(false)
    setFieldValue(STREET, null)
    setFieldValue(CITY, null)

    const variables = validatableValues

    if (some(variables, isBlank)) return

    const valid =
      get(variables, POSTAL_CODE, '').toString().length === 4 &&
      get(variables, POSTAL_CODE_END, '').toString().length === 2 &&
      get(variables, HOUSE_NUMBER, '').toString().length > 0
    if (!valid) return

    await debouncedValidation(variables)
  }

  useEffect(() => {
    if (!isNil(lastPayload) && isEqual(lastPayload, editValues)) return

    setLastPayload(editValues)
    handleChange()
  }, [editValues])

  const defaultProps = {
    type: 'input',
    required: true,
    classNames: 'input',
    isSubmitting,
    errors,
    hidden: false,
    showErrorCharacter: true,
    touched,
    values,
    setFieldValue,
    t,
  }

  return (
    <>
      <div className="field is-horizontal is-grouped">
        <PostalCode {...defaultProps} />
        <PostalCodeEnd {...defaultProps} />
      </div>

      <div className="field is-horizontal is-grouped">
        <HouseNumber {...defaultProps} />
        <HouseAddition {...defaultProps} />
      </div>

      <div className="field">
        <Street {...defaultProps} />
        <City {...defaultProps} />
      </div>

      {showValidationError && (
        <ValidationError>
          {t('errors.locations.invalid_address')}
        </ValidationError>
      )}
    </>
  )
}

export default withNamespaces('translation')(LocalAddressFields)
