const nikkie = {
  buttonColor: 'hsl(152, 100%, 30%)',
  elementsColor: 'hsl(0, 0%, 13%)',
  buttonBorderRadius: '0',
}

export const fixtures = {
  '4sysfootwear': {
    buttonColor: 'hsl(163.4, 100%, 33.9%)',
  },
  '4thecompagnie': {
    buttonBorderRadius: '0',
    buttonColor: 'black',
  },
  '123verkopen': {
    buttonColor: 'hsl(233, 100%, 13%)',
    elementsColor: 'hsl(158, 100%, 34%)',
  },
  alixthelabel: {
    buttonColor: 'black',
    fontHeaders: 'Playfair Display',
    fontPrimary: 'Almarai',
  },
  bronx: {
    buttonColor: 'hsl(0, 0%, 20%)',
  },
  bundleboon: {
    buttonColor: '#f0512a',
  },
  bybar: {
    buttonColor: 'hsl(174, 17.2%, 65.9%)', // elementsColor: "hsl(270, 0.9%, 42.4%)",
    fontPrimary: 'Poppins',
    fontWeightCorrection: -3,
  },
  coef: {
    buttonColor: 'hsl(224.2, 59.6%, 34.9%)',
  },
  dante6: {
    buttonColor: '#deddd6',
  },
  dirkdewitmode: {
    buttonColor: '#009fe3',
  },
  esuals: {
    buttonColor: 'hsl(30, 29%, 67%)',
    elementsColor: 'hsl(0, 0%, 13%)',
  },
  'exchange-glasscandinavia': {
    buttonColor: 'hsl(6, 24%, 75%)',
    elementsColor: 'hsl(172, 20%, 22%)',
  },
  farfallina: {
    buttonColor: 'hsl(328, 89%, 48%)',
    elementsColor: 'hsl(0, 0%, 13%)',
  },
  fashionforless: {
    buttonColor: 'hsl(0, 86%, 58%)',
    elementsColor: 'hsl(0, 0%, 20%)',
  },
  feestwinkelxl: {
    buttonColor: 'hsl(193.5, 100%, 41%)',
  },
  feraggio: {
    buttonColor: 'hsl(44, 59%, 69%)',
    elementsColor: 'hsl(0, 0%, 12%)',
    errorBgColor: 'hsla(44, 59%, 69%, 0.2)',
    errorTextColor: 'hsl(0, 93%, 36%)',
  },
  fifthhouse: nikkie,
  freestylesport: {
    buttonColor: 'hsl(242.9, 43.7%, 27.8%)',
  },
  glasscandinavia: {
    buttonColor: 'hsl(6, 24%, 75%)',
    elementsColor: 'hsl(172, 20%, 22%)',
  },
  gorillasports: {
    buttonColor: '#FACF2C',
    elementsColor: '#0C1A33',
  },
  groteschoenen: {
    buttonColor: 'hsl(19.6, 91%, 61%)',
  },
  hetcosmeticahuis: {
    buttonColor: 'hsl(7, 54%, 31%)',
    elementsColor: 'hsl(0, 0%, 7%)',
  },
  hethannahhuis: {
    buttonColor: 'hsl(346, 80%, 55%)',
    elementsColor: 'hsl(0, 0%, 7%)',
  },
  humansarevain: {
    buttonColor: 'hsl(0, 0%, 7%)',
  },
  humblelights: {
    buttonColor: 'hsl(44.7, 100%, 67.6%)',
    elementsColor: 'hsl(25, 57.1%, 91.8%)',
  },
  koopallesonline: {
    buttonColor: 'hsl(22.9, 100%, 65.1%)',
    elementsColor: 'hsl(202, 100%, 61%)',
  },
  madampeach: {
    buttonColor: '#FFE5B4',
  },
  mamaloes: {
    buttonColor: 'hsl(341.9, 97.5%, 52.5%)',
    elementsColor: 'hsl(201.3, 81.1%, 43.5%)',
  },
  mijnwittetshirts: {
    buttonColor: 'hsl(94, 45%, 53%)',
    elementsColor: 'hsl(28, 57%, 72%)',
  },
  modecastelijn: {
    buttonBorderRadius: '0',
    buttonColor: 'hsl(0, 0%, 12%)',
  },
  moostwanted: {
    buttonColor: 'hsl(0, 0%, 13%)',
  },
  mostwantedluxury: {
    buttonColor: 'hsl(333, 85%, 48%)',
    elementsColor: '',
  },
  myjewellery: {
    buttonBorderRadius: '.4rem',
    buttonColor: 'black',
    elementsColor: 'hsl(346, 52%, 82%)',
  },
  'n-brands': nikkie,
  naduvi: {
    background: 'hsl(30, 35%, 93%)',
    blackColor: 'hsl(0, 0%, 20%)',
    buttonColor: 'hsl(10, 62%, 42%)',
    elementsColor: 'hsl(0, 0%, 20%)',
    fontPrimary: 'Poppins',
    fontWeightCorrection: -3,
    priceColor: 'hsl(6, 74%, 51%)',
    whiteColor: 'hsl(30, 35%, 93%)',
  },
  nikandnik: nikkie,
  nikkie,
  nitten: {
    buttonColor: 'hsl(28.1, 34.8%, 82%)',
  },
  olliewood: {
    buttonColor: 'hsl(27, 100%, 50%)',
    elementsColor: 'hsl(214, 30%, 31%)',
  },
  onedayfashiondeals: {
    buttonColor: 'hsl(359, 92%, 39%)',
    elementsColor: 'hsl(0, 0%, 20%)',
  },
  'original-replicas': {
    buttonColor: 'black',
  },
  outdoorxl: {
    buttonBorderRadius: '.3rem',
    buttonColor: 'hsl(69, 87%, 38%)',
  },
  plusman: {
    buttonColor: 'hsl(208, 100%, 46%)',
    elementsColor: 'hsl(0, 0%, 22%)',
  },
  polewolf: {
    buttonColor: 'hsl(0, 0%, 7%)',
  },
  racketstringers: {
    buttonColor: 'hsl(18, 100%, 55%)',
    elementsColor: 'hsl(0, 0%, 20%)',
  },
  retourjeans: {
    buttonColor: 'hsl(213, 81%, 21%)',
    elementsColor: 'hsl(226, 46%, 22%)',
  },
  rogon: {
    buttonColor: 'black',
  },
  'runway-closet': {
    buttonColor: 'black',
  },
  smilefavorites: {
    buttonBorderRadius: '0',
    buttonColor: 'hsl(353, 56%, 54%)',
    elementsColor: 'hsl(0, 0%, 12%)',
  },
  speedcomfort: {
    buttonColor: 'hsl(104, 48%, 43%)',
  },
  stoov: {
    buttonColor: 'hsl(4, 58%, 48%)',
    elementsColor: 'hsl(0, 21.9%, 12.5%)',
  },
  studioanneloes: {
    buttonColor: 'hsl(5, 85%, 89%)',
    elementsColor: '',
  },
  suitable: {
    buttonColor: '',
    elementsColor: '',
    fontHeaders: 'Volkhov',
    fontPrimary: 'Karla',
    fontWeightCorrection: -1,
  },
  'summersport-store': {
    buttonColor: 'hsl(26, 89%, 50%)',
    elementsColor: 'hsl(210, 54%, 53%)',
  },
  sustain: nikkie,
  tanrevel: {
    buttonColor: '#DCC6B6',
  },
  thingsilikethingsilove: {
    buttonColor: 'hsl(0, 0%, 7%)',
    headerBgColor: 'black',
  },
  totectors: {
    buttonColor: 'hsl(58.4, 92.7%, 48.4%)',
    elementsColor: 'hsl(0, 0%, 20%)',
  },
  toytraders: {
    buttonColor: 'hsl(29, 100%, 45%)',
    elementsColor: 'hsl(0, 2%, 84%)',
  },
  tuinland: {
    buttonColor: 'hsl(99.6, 48.3%, 39.4%)',
    elementsColor: 'hsl(305.6, 35%, 24.1%)',
  },
  twinseasons: {
    buttonColor: 'hsl(0, 80%, 52%)',
    elementsColor: 'hsl(0, 0%, 7%)',
  },
  vandevenfashion: {
    buttonColor: '#00a09d',
  },
  villamoo: {
    buttonColor: 'hsl(0, 100%, 80%)',
  },
  voordelvanger: {
    buttonColor: '',
    elementsColor: '',
    fontPrimary: 'Montserrat',
  },
  wannahaves: {
    buttonColor: 'hsl(10, 20%, 35%)',
    elementsColor: 'hsl(0, 0%, 20%)',
  },
  'wintersport-store': {
    buttonColor: 'hsl(26, 89%, 50%)',
    elementsColor: 'hsl(210, 54%, 53%)',
  },
  new: {
    buttonColor: '',
    elementsColor: '',
  },
}
