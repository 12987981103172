import { call, put } from 'redux-saga/effects'
import { implementPromiseAction } from '@adobe/redux-saga-promise'
import { get } from 'lib/lodash'
import rootApiCatch from 'features/rootApiCatch'
import api from '../rootApi'

export function* getTimeslots(action) {
  yield call(implementPromiseAction, action, function* () {
    const { payload: variables } = action

    const requestPayload = {
      query: 'op:GetTimeslots',
      variables,
    }

    let response
    try {
      response = yield api(requestPayload)
    } catch (e) {
      yield put(rootApiCatch(requestPayload, e))
      throw e
    }

    return get(response, 'data.data.timeslots')
  })
}
