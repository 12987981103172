import { get } from 'lib/lodash'
import i18n from 'lib/i18n'
import { populateWithBlanks, cleanFromBlanks } from 'lib/helpers'
import { call, put, select } from 'redux-saga/effects'
import { implementPromiseAction } from '@adobe/redux-saga-promise'
import { getShop } from 'lib/selectors'
import orderHelper from 'lib/orderHelper'
import rootApiCatch from 'features/rootApiCatch'
import {
  internationalDropoffMapper,
  localDropoffMapper,
  internationalPickupMapper,
  localPickupMapper,
  backendOrderInterface,
  updateShipmentInterface,
} from './formatter'
import orderActions from './actions'
import api from '../rootApi'

const withLocale = (vars) => ({
  ...vars,
  locale: i18n.language.substring(0, 2),
})

export function* createPickup(action) {
  yield call(implementPromiseAction, action, function* () {
    const { payload: order } = action

    const shop = yield select(getShop)
    const { isInternational, isLocal } = orderHelper({ shop, order })

    let request
    let dataKey

    if (isInternational) {
      request = {
        query: 'op:CreatePickupInt',
        variables: internationalPickupMapper(order),
      }
      dataKey = 'createPickupShipmentInt'
    }

    if (isLocal) {
      request = {
        query: 'op:CreatePickup',
        variables: localPickupMapper(order),
      }
      dataKey = 'createPickupShipment'
    }

    if (!request)
      throw new Error(
        `Can't figure out the request, order: ${JSON.stringify(order)}`,
      )

    let variables = withLocale(request.variables)
    variables = cleanFromBlanks(variables)
    const requestPayload = { ...request, variables }

    let response
    try {
      response = yield api(requestPayload)
    } catch (e) {
      yield put(rootApiCatch(requestPayload, e))
      throw e
    }

    if ('errors' in response) {
      const { errors } = response
      throw new Error(errors)
    }

    return get(response, `data.data.${dataKey}`)
  })
}

export function* createDropoff(action) {
  yield call(implementPromiseAction, action, function* () {
    const { payload: order } = action

    const shop = yield select(getShop)
    const { isInternational, isLocal } = orderHelper({ shop, order })

    let request
    let dataKey

    if (isInternational) {
      request = {
        query: 'op:CreateDropoffInt',
        variables: internationalDropoffMapper(order),
      }
      dataKey = 'createDropoffShipmentInt'
    }

    if (isLocal) {
      request = {
        query: 'op:CreateDropoff',
        variables: localDropoffMapper(order),
      }
      dataKey = 'createDropoffShipment'
    }
    if (!request)
      throw new Error(
        `Can't figure out the request, order: ${JSON.stringify(order)}`,
      )

    let variables = withLocale(request.variables)
    variables = cleanFromBlanks(variables)

    const requestPayload = { ...request, variables }
    let response
    try {
      response = yield api(requestPayload)
    } catch (e) {
      yield put(rootApiCatch(requestPayload, e))
      throw e
    }

    if ('errors' in response) {
      const { errors } = response
      throw new Error(errors)
    }

    return get(response, `data.data.${dataKey}`)
  })
}

export function* getOrder(action) {
  yield call(implementPromiseAction, action, function* () {
    const { payload: id } = action

    const variables = {
      id,
    }

    const requestPayload = {
      query: 'op:SummaryShipment',
      variables,
    }
    let response
    try {
      response = yield api(requestPayload)
    } catch (e) {
      yield put(rootApiCatch(requestPayload, e))
    }

    const data = get(response, 'data.data.shipment')

    const shop = yield select(getShop)
    const order = backendOrderInterface({ shop, order: data })

    yield put(orderActions.getOrderOk(order))

    return order
  })
}

export function* updateShipment(action) {
  yield call(implementPromiseAction, action, function* () {
    const { payload: values } = action

    let variables = updateShipmentInterface(values)
    variables = populateWithBlanks(variables)

    const requestPayload = {
      query: 'op:Portal:UpdatePickupShipment',
      variables,
    }
    let response
    try {
      response = yield api(requestPayload)
    } catch (e) {
      yield put(rootApiCatch(requestPayload, e))
      throw e
    }

    if ('errors' in response) {
      const { errors } = response
      throw new Error(errors)
    }

    return get(response, 'data.data.updatePickupShipment')
  })
}

export function* proceed(action) {
  yield call(implementPromiseAction, action, function* () {
    const { payload: order } = action
    const shipmentId = get(order, 'id')
    const variables = { shipmentId }

    if (order.returnType === 'pickup')
      variables.timeslotId = get(order, 'timeslotId')

    const requestPayload = {
      query: 'op:ProceedWithShipment',
      variables,
    }
    let response
    try {
      response = yield api(requestPayload)
    } catch (e) {
      yield put(rootApiCatch(requestPayload, e))
      throw e
    }

    if ('errors' in response) {
      const { errors } = response
      throw new Error(errors)
    }

    return get(response, 'data.data.proceedWithShipment')
  })
}
