import React from 'react'
import FieldInput from 'components/FieldInput'
import FieldWrapper from 'components/FieldWrapper'
import { isFieldDirty } from 'lib/formHelpers'
import { SHIPMENT_FIELDS } from 'config/constants'
import { get } from 'lib/lodash'

const { EMAIL } = SHIPMENT_FIELDS

export default function Email(props) {
  const { errors, values, touched, t, autoFocus = false } = props
  const value = get(values, EMAIL)
  const error = get(errors, EMAIL)

  const fieldInputProps = {
    ...props,
    id: EMAIL,
    name: EMAIL,
    type: 'email',
    label: t('details.form.email.label'),
    isFieldDirty: isFieldDirty(touched, EMAIL),
    autoFocus,
    value,
  }

  return (
    <FieldWrapper errors={error} filled={value}>
      <FieldInput {...fieldInputProps} />
    </FieldWrapper>
  )
}
