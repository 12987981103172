import React from 'react'
import { useSelector } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { getShop } from 'lib/selectors'
import useHistory from 'hooks/useHistory'
import { isEmpty } from 'lib/lodash'
import { useLocation } from 'react-router-dom'
import CountriesPage from './CountriesPage'
import ShopsPage from './ShopsPage'

function LandingPage(props) {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const shop = useSelector(getShop)
  const { goToReturnType } = useHistory()
  if (!shop || isEmpty(shop)) return null

  const { startWithShopsPage, onlyLocalShipments } = shop
  const showShopsPage = startWithShopsPage && query.get('root') !== 'true'

  if (showShopsPage) return <ShopsPage {...props} />

  if (onlyLocalShipments) {
    goToReturnType()
    return null
  }

  return <CountriesPage {...props} />
}

export default withNamespaces('translation')(LandingPage)
