import React from 'react'
import FieldInput from 'components/FieldInput'
import FieldWrapper from 'components/FieldWrapper'
import { isFieldDirty } from 'lib/formHelpers'
import { SHIPMENT_FIELDS } from 'config/constants'
import { get } from 'lib/lodash'

const { SHIP_FROM_POSTAL_CODE } = SHIPMENT_FIELDS

export default function ShipFromPostalCode(props) {
  const { errors, values, touched, t } = props
  const value = get(values, SHIP_FROM_POSTAL_CODE)
  const error = get(errors, SHIP_FROM_POSTAL_CODE)

  const shipFromPostalCodeProps = {
    ...props,
    id: SHIP_FROM_POSTAL_CODE,
    name: SHIP_FROM_POSTAL_CODE,
    label: t('address.form.postal_code.label'),
    type: 'text',
    isFieldDirty: isFieldDirty(touched, SHIP_FROM_POSTAL_CODE),
    value,
  }

  return (
    <FieldWrapper errors={error} filled={value} classNames="is-expanded">
      <FieldInput {...shipFromPostalCodeProps} />
    </FieldWrapper>
  )
}
