import * as yup from 'yup'

yup.setLocale({
  string: {
    max: ({ max }) => ({ key: 'details.form.too_long', values: { max } }),
    min: ({ min }) => ({ key: 'details.form.too_short', values: { min } }),
  },
})

export default yup
