import { css } from 'styled-components'

export const breakpoints = {
  xs: { minWidth: 'min-width: 480px', ratioString: '' },
  sm: { minWidth: 'min-width: 768px', ratioString: '' },
  md: {
    minWidth: 'min-width: 1024px',
    ratioString: 'min-device-aspect-ratio: 1001/1000',
  },
  lg: { minWidth: 'min-width: 1200px', ratioString: '' },
}

export const respondTo = Object.keys(breakpoints).reduce(
  (accumulator, label) => {
    accumulator[label] = (...args) => css`
      @media screen and (${breakpoints[label].minWidth}) {
        ${css(...args)};
      }
    `
    return accumulator
  },
  {},
)

export const vh = (number, expr) => {
  let calcExpr = `var(--vh, 1vh) * ${number}`
  if (expr) calcExpr = `${calcExpr} ${expr}`

  return `calc(${calcExpr})`
}

export const hideScrollBar = `
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`

export const ios = (...args) => css`
  @supports (-webkit-touch-callout: none) {
    ${css(...args)};
  }
`

export { default as styled } from 'styled-components'
