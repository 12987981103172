import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { get, uniq } from 'lib/lodash'
import { SHIPMENT_FIELDS } from 'config/constants'
import { isBlank, isZero } from 'lib/helpers'
import shopSettings from 'config/shopSettings'
import useOrder from 'hooks/useOrder'
import useHistory from 'hooks/useHistory'
import { orderAction } from 'lib/actions'
import ParcelsFields from './ParcelsFields'
import NetworkFields from './NetworkFields'

const Container = styled.div`
  /* ... */
`

const { NUMBER_OF_BOXES, NETWORK } = SHIPMENT_FIELDS

function ParcelsAndNetwork() {
  const { noData, shop, order, pricelists } = useOrder()
  const { goToNextStep } = useHistory()
  const [initialized, setInitialized] = useState(false)
  const [numberOfBoxes, setNumberOfBoxes] = useState()
  const [network, setNetwork] = useState()
  const [showNetworks, setShowNetworks] = useState()

  useEffect(() => {
    if (!initialized) return

    validateAndSubmit()
    // eslint-disable-next-line
  }, [network, numberOfBoxes])

  useEffect(() => {
    if (!pricelists || initialized) return

    const networks = uniq(pricelists.map((pl) => get(pl, NETWORK)))
    setNetwork(networks.length === 1 ? networks[0] : undefined)
    let defaultNumberOfBoxes = order.numberOfBoxes || 1
    const manyNetworks = networks.length > 1
    const oneNetwork = networks.length === 1

    if (oneNetwork) defaultNumberOfBoxes = maxParcels === 1 ? 1 : undefined

    setInitialized(true)
    setNumberOfBoxes(defaultNumberOfBoxes)
    setShowNetworks(manyNetworks)
  }, [pricelists])

  if (noData) return null

  // default values for direct access to the link /drop-off/parcels or /pickup/parcels
  const { returnType } = order
  if (!returnType && window) {
    let defaultReturnType
    const { pathname } = window.location
    if (pathname.includes('order-pickup')) defaultReturnType = 'pickup'
    if (pathname.includes('drop-off')) defaultReturnType = 'dropoff'
    if (defaultReturnType)
      orderAction.setOrder({ returnType: defaultReturnType })
  }

  const orderNumberOfBoxes = get(order, NUMBER_OF_BOXES)
  const maxParcels = shopSettings('maxParcels', shop, 4)

  function onSubmit() {
    orderAction.setOrder({ network, numberOfBoxes })
    setTimeout(goToNextStep, 250)
  }

  const validateAndSubmit = () => {
    const parcelsNotChosen = isZero(+numberOfBoxes)
    const networkNotChosen = isBlank(network)
    if (parcelsNotChosen || networkNotChosen) return

    onSubmit()
  }
  if (!pricelists) return null

  return (
    <Container>
      {maxParcels > 1 && (
        <ParcelsFields
          orderValue={orderNumberOfBoxes}
          value={numberOfBoxes}
          setValue={setNumberOfBoxes}
        />
      )}
      {showNetworks && (
        <NetworkFields network={network} setNetwork={setNetwork} />
      )}
    </Container>
  )
}

export default ParcelsAndNetwork
