import { createSelector } from 'reselect'
import { get } from 'lib/lodash'
import { PostalCodeConstructor } from './postalCode'

export const getAddressFormFields = (state) => state.address.formFields
export const getApp = (state) => state.app
export const getShop = (state) => state.shop
export const getConfig = (state) => state.shop
export const getOrder = (state) => state.order
export const getBranding = (state) => get(state, 'shop.shopBranding')
export const getTimeslots = (state) => state.timeslots
export const getCustomizations = (state) => state.shop.shop.customizations
export const selectedDay = (_, day) => day
export const getShopAndOrder = (state) => ({
  shop: getShop(state),
  order: getOrder(state),
})
export const getPostalCode = createSelector(
  getOrder,
  ({ postalCode, postalCodeEnd }) =>
    PostalCodeConstructor(postalCode, postalCodeEnd),
)
