import React from 'react'
import ProgressBar from 'components/ProgressBar'
import { get } from 'lib/lodash'

export default function DetailsLayout(props) {
  const { history, children } = props

  return (
    <div className="columns is-mobile is-gapless small-margin">
      <div className="progress column is-2 is-offset-2-desktop">
        <ProgressBar
          flow={get(props, 'match.path', '').replace(/^\/|\/$/g, '')}
          history={history}
        />
      </div>
      <div className="column is-7-desktop is-7-tablet is-10-mobile">
        {children}
      </div>
    </div>
  )
}
