import {
  formatDateAsDay,
  formatDateAsDayWeek,
  formatAsMomentDate,
} from 'lib/dateHelpers'

export const getTimeslotsByDay = (timeslots, day) =>
  timeslots.filter((ts) => day === whatDay(ts))

export const whatDay = (timeslot) => {
  if (!timeslot) return undefined

  const tsDay = formatDateAsDay(timeslot.start)
  return parseInt(tsDay, 10)
}

export const getDaysIndex = (timeslots, locale) => {
  const daysIndex = {}
  timeslots.forEach(({ start }) => {
    const day = formatDateAsDay(start)

    if (!daysIndex[day]) {
      daysIndex[day] = {
        day,
        weekDay: formatDateAsDayWeek(start, locale),
        startMomentDate: formatAsMomentDate(start),
        timeslots: getTimeslotsByDay(timeslots, day),
      }
    }
  })

  return daysIndex
}

export const getDaysList = (daysIndex = {}) =>
  Object.values(daysIndex)
    .sort((a, b) => a.startMomentDate - b.startMomentDate)
    .map((el) => el.day)

// export const getTimeslotDays = (timeslots = []) => {
//   if (!timeslots || isEmpty(timeslots)) return;
//
//   const daysIndex = {};
//   //  timeslots.forEach(({ start }) => {
//   //    const day = formatDateAsDay(start);
//   //
//   //    if (!daysIndex[day]) {
//   //      daysIndex[day] = {
//   //        day,
//   //        weekDay: formatDateAsDayWeek(start),
//   //        startMomentDate: formatAsMomentDate(start),
//   //        timeslots: getTimeslotsByDay(timeslots, day),
//   //      };
//   //    }
//   //  });
//
//   const daysList = [];
//   //   const daysList = Object.values(daysIndex).sort(
//   //     (a, b) => a.startMomentDate - b.startMomentDate
//   //   );
//
//   return [daysList, daysIndex];
// };
