import { useState, useEffect } from 'react'
import { useHistory as useHistoryHook } from 'react-router-dom'
import { FLOWS, PATHS } from 'config/constants'
import { orderAction } from 'lib/actions'
import useOrder from './useOrder'

const { PARCELS, ADDRESS, TIMESLOT, DETAILS } = PATHS

const useHistory = () => {
  const { noData, shop, order, isPickup, isDropoff, showAddressForm } =
    useOrder()
  const history = useHistoryHook()
  const [flowPath, setFlowPath] = useState()
  const [path, setPath] = useState()
  useEffect(() => {
    if (noData) return

    if (isPickup) setFlowPath(FLOWS.PICKUP)
    if (isDropoff) setFlowPath(FLOWS.DROPOFF)
  }, [order])

  useEffect(() => {
    if (!path) return

    history.push(`/${flowPath}/${path}`)
  }, [path])

  useEffect(() => {
    if (order.id && standardSteps.includes(currentPath))
      orderAction.clearOrderId()
  }, [currentPath])

  if (noData) return { noData }

  const goToLanding = () => history.push('/')

  const goToReturnType = () => history.push(`/${PATHS.RETURN_TYPE}`)

  const { gatekeeperEnabled } = shop

  const currentPaths = history.location.pathname.split('/')
  const currentPath = currentPaths[currentPaths.length - 1]
  const commonSteps = []
  const gatekeeperSteps = []

  if (showAddressForm) commonSteps.push(ADDRESS)
  if (isPickup) {
    if (gatekeeperEnabled) gatekeeperSteps.push(TIMESLOT)
    else commonSteps.push(TIMESLOT)
  }

  const standardSteps = [PARCELS, ...commonSteps, DETAILS]
  const steps = [...standardSteps, ...gatekeeperSteps]
  const currentStep = steps.findIndex((el) => el === currentPath) + 1

  const goToFlowPath = (newPath) => {
    if (path === newPath) return

    setPath(newPath)
  }

  const goToParcels = () => goToFlowPath(PATHS.PARCELS)
  const goToAddress = () => goToFlowPath(PATHS.ADDRESS)
  const goToTimeslots = () => goToFlowPath(PATHS.TIMESLOT)
  const goToDetails = () => goToFlowPath(PATHS.DETAILS)

  const goToConfirmation = (orderId) => {
    if (!orderId) throw new Error('No order id provided')
    history.push(`/${PATHS.CONFIRMATION}/${orderId}`)
  }

  const goToEdit = (orderId) => {
    if (!orderId) throw new Error('No order id provided')

    history.push(`/edit/${orderId}`)
  }

  const goToUrl = (url) => {
    if (!url) throw new Error('No url provided')

    window.location.href = url
  }

  const goBack = () => history.goBack()

  const STEP_METHODS = {
    [PARCELS]: goToParcels,
    [ADDRESS]: goToAddress,
    [TIMESLOT]: goToTimeslots,
    [DETAILS]: goToDetails,
  }

  const stepsCount = steps.length + 2 // +1 for return type, +1 for the confirmation page ( step: download the label)
  const nextStepPath = steps[currentStep]

  const goToNextStep = STEP_METHODS[nextStepPath]

  const goToStep = (stepNumber) => {
    const newPath = steps[stepNumber - 1]
    goToFlowPath(newPath)
  }

  const startFlow = () => {
    const firtStep = steps[0]
    const stepMethod = STEP_METHODS[firtStep]
    return stepMethod()
  }

  const errorPageIsOpen = history.location.pathname.includes('error')

  return {
    goToLanding,
    goToReturnType,
    goToParcels,
    goToAddress,
    goToTimeslots,
    goToDetails,
    goToConfirmation,
    goToEdit,
    goToUrl,
    nextStepPath,
    flowPath,
    currentStep,
    currentPath,
    steps,
    stepsCount,
    goToStep,
    goToNextStep,
    startFlow,
    goBack,
    shop,
    order,
    errorPageIsOpen,
  }
}

export default useHistory
