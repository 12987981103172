import React from 'react'
import qs from 'qs'
import { withNamespaces } from 'react-i18next'
import { get } from 'lib/lodash'
import { longestText } from 'lib/helpers'
import { getTransKey, langCode } from 'lib/i18n'
import CtaButton from 'components/CtaButton'
import IntroTitle from 'components/IntroTitle'
import FlyingBox from 'components/FlyingBox'
import useHistory from 'hooks/useHistory'
import { RETURN_TYPES, SIZES, NETWORKS } from 'config/constants'
import useOrder from 'hooks/useOrder'
import shopSettings from 'config/shopSettings'
import { orderAction } from 'lib/actions'

function ReturnType(props) {
  const {
    noData,
    order,
    shop,
    isInternational,
    isLocal,
    dropoffEnabled,
    letterboxEnabled,
    pickupEnabled,
  } = useOrder()
  const { startFlow } = useHistory()
  if (noData) return null

  const countryCodesAllowed = shopSettings('countryCodesAllowed', shop)
  const { shipFromCountryCode } = order
  if (!shipFromCountryCode)
    orderAction.setOrder({ shipFromCountryCode: countryCodesAllowed[0] })

  const { location, t, i18n } = props
  const { gatekeeperEnabled, frontendAssetId } = shop

  const parsed = qs.parse(get(location, 'search', '?').substring(1))
  const locale = get(parsed, 'locale')
  if (locale && langCode() !== locale) i18n.changeLanguage(locale)

  const handleOrderPickupClick = async () => {
    const newValues = {
      size: SIZES.PARCEL,
      returnType: RETURN_TYPES.PICKUP,
      network: NETWORKS.DHL,
    }
    // TODO check if await is needed
    await orderAction.setOrder(newValues)
    return startFlow()
  }

  const handleDropOffClick = async (size) => {
    const newValues = { size, returnType: RETURN_TYPES.DROPOFF }
    if (size === SIZES.LETTERBOX) newValues.network = NETWORKS.DHL

    // TODO check if await is needed
    await orderAction.setOrder(newValues)
    return startFlow()
  }

  const handleContentBlockRender = () => {
    const addAltText = +get(shop, 'id') === 175

    return (
      <>
        {!gatekeeperEnabled && !shopSettings('hideIntroTitle', shop) && (
          <IntroTitle shop={shop} />
        )}
        <FlyingBox />
        {addAltText && (
          <>
            <h2 style={{ textAlign: 'center' }}>
              {isLocal ? t('intro.altPost3') : t('intro.altPost1')}
            </h2>
            <h3
              style={{ textAlign: 'center', marginBottom: '2rem' }}
              className="dimmed"
            >
              {t('intro.altPost2')}
            </h3>
          </>
        )}
      </>
    )
  }

  const handleButtonsRender = () => {
    let buttons = []

    if (dropoffEnabled)
      buttons.push({
        id: 'dropoff',
        text: t(getTransKey('intro.cta_drop-off-parcel', shop)),
        onClick: () => handleDropOffClick('parcel'),
      })

    if (letterboxEnabled)
      buttons.push({
        id: 'dropoff',
        text: t('intro.cta_drop-off-letterbox'),
        onClick: () => handleDropOffClick('letterbox'),
      })

    if (pickupEnabled)
      buttons.push({
        id: 'pickup',
        text: t(getTransKey('intro.cta_order-pickup', shop)),
        onClick: () => handleOrderPickupClick(),
      })

    const maxLength = longestText(buttons.map((el) => el.text)) + 3
    buttons = buttons.map((el) => {
      const element = el
      element.buttonLength = maxLength
      return element
    })

    return (
      <div className="columns">
        <div className="column is-12-desktop is-12-tablet is-12-mobile">
          {buttons.map((attrs) => (
            <div className="center-content" key={attrs.text}>
              <CtaButton {...attrs} key={attrs.text} />
            </div>
          ))}
        </div>
      </div>
    )
  }

  const renderHeader = () => {
    const translationKey = `landing.header.${frontendAssetId}`
    const parcelSize = isInternational ? '100 x 50 x 50cm' : '180 x 100 x 50cm'
    if (!i18n.exists(translationKey)) return null

    let translationValue = t(translationKey, {
      size: parcelSize,
      returnObjects: true,
    })
    let joined = [translationValue]

    if (translationValue.constructor === Array) {
      translationValue = t(translationKey, {
        size: parcelSize,
        returnObjects: true,
      })
      const [firstLine, secondLine] = translationValue
      joined = [firstLine, <br />, secondLine]
    }

    const shopSupportEmail = shopSettings('shopSupportEmail', shop)
    if (shopSupportEmail) {
      const shopSupportEmailFragment = (
        <a href={`mailto:${shopSupportEmail}`} target="_blank">
          {shopSupportEmail}
        </a>
      )
      joined.push(' ')
      joined.push(shopSupportEmailFragment)
    }

    const shopSupportPhone = shopSettings('shopSupportPhone', shop)
    if (shopSupportPhone) {
      const shopSupportPhoneFragment = (
        <a href={`tel:${shopSupportPhone}`} target="_blank">
          {shopSupportPhone}
        </a>
      )
      joined.push(' ')
      joined.push(shopSupportPhoneFragment)
    }

    joined.push(<br key={1} />)
    joined.push(<br key={2} />)

    return (
      <div className="columns" style={{ marginTop: '2rem' }}>
        <div className="column is-12-desktop is-12-tablet is-12-mobile">
          <h3 style={{ textAlign: 'center' }}>{joined}</h3>
        </div>
      </div>
    )
  }

  return (
    <div className="columns is-mobile landing-page">
      <div className="column is-10-desktop is-10-tablet is-10-mobile is-offset-1">
        {handleContentBlockRender()}
        {renderHeader()}
        {handleButtonsRender()}
      </div>
    </div>
  )
}

export default withNamespaces('translation')(ReturnType)
