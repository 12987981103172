import { get } from 'lib/lodash'
import {
  darken,
  getLuminance,
  lighten,
  mix,
  getContrast,
  transparentize,
} from 'polished'
import variables from 'config/customShopSchemes'
import { fixtures } from './shopSchemes'

export const getCssVariablesForShop = (frontendAssetId, allVars = {}) => {
  const varsFixtures = fixtures[frontendAssetId]
  return getCssVariables(varsFixtures, allVars)
}

export const getCssVariables = (obj, allVars = {}) => {
  if (!obj) return null

  const vars = {
    '--text-color': 'hsl(0, 0%, 0%)',
    '--text-color--lighter': 'hsl(0, 0%, 7%)',

    '--headers-text-color': 'var(--text-color--lighter)',
    '--headers-text-color--dimmed': 'hsl(0, 0%, 40%)',

    '--input-text-color': 'var(--text-color--lighter)',
    '--main-text-color': 'var(--text-color--lighter)',
    '--main-text-color--light': 'var(--text-color--lighter)',
    '--prm-btn-text-color--disabled': 'var(--prm-btn-text-color)',

    '--main-gradient-color-1': 'var(--text-color)',
    '--main-gradient-color-1--hover': 'var(--text-color--lighter)',
    '--main-gradient-color-2': 'var(--text-color)',
    '--main-gradient-color-2--hover': 'var(--text-color)',

    '--footer-bg-color-1': 'hsl(0, 0%, 13%)',
    '--footer-bg-color-2': 'var(--footer-bg-color-1)',

    '--footer-text-color': 'hsl(0, 0%, 98%)',
    '--footer-logo-color': 'var(--footer-text-color)',

    '--scn-gradient-color-1': 'var(--text-color)',
    '--scn-gradient-color-1--hover': 'var(--text-color--lighter)',
    '--scn-gradient-color-2': 'var(--text-color)',
    '--scn-gradient-color-2--hover': 'var(--text-color)',

    '--progress-bar-bg-color': 'hsl(220, 11%, 43%)',
    '--progress-bar-bg-color--active': 'hsl(220, 11%, 43%)',
    '--progress-bar-step-bg-color': 'hsl(219, 14%, 81%)',
    '--progress-bar-step-bg-color--active': 'var(--headers-text-color--dimmed)',
    '--progress-bar-step-checked-bg-color': 'var(--headers-text-color--dimmed)',

    '--confirmation-box-bg-color': 'rgba(155,155,155,.05)',
    '--brand-color-1': 'var(--text-color)',
    '--brand-color-2': 'var(--text-color--lighter)',
    '--brand-text-color': 'var(--text-color)',
  }

  const {
    buttonColor,
    fontPrimary,
    fontHeaders,
    fontWeightCorrection,
    buttonBorderRadius,
    background,
    blackColor = 'hsl(0,0%,7%)',
    whiteColor = 'hsl(0,0%,98%)',
    priceColor,
    errorTextColor,
    errorBgColor,
    headerBgColor,
  } = obj

  const getVar = (name) => {
    const digVar = (value) => {
      if (value && value.startsWith('var')) {
        const res = value.replace('var(', '').replace(')', '')
        return getVar(res)
      }

      return value
    }
    const oldVar = digVar(get(vars, name))
    const newVar = digVar(get(allVars, name))
    return oldVar || newVar
  }

  const setVar = (name, value, options = {}) => {
    vars[name] = value
    if (options.active) vars[`${name}--active`] = options.active
  }

  const isDark = (color) => getLuminance(color) <= 0.179
  const textColorPicker = (
    color,
    opts = { lightColor: whiteColor, darkColor: blackColor },
  ) =>
    getContrast(opts.darkColor, color) < 6 ? opts.lightColor : opts.darkColor

  const setTextColor = (bgColorField, texColorField) => {
    const bgColor = getVar(bgColorField)
    const bgColorActive = getVar(`${bgColorField}--active`)
    const value = textColorPicker(bgColor)
    const active = textColorPicker(bgColorActive)
    setVar(texColorField, value, { active })
  }

  const elementsColor = obj.elementsColor || buttonColor
  const is2Colors = Boolean(obj.elementsColor)

  if (fontPrimary) {
    let weights = [700, 800, 900]
    if (fontWeightCorrection) {
      weights = weights.map((el) => el + fontWeightCorrection * 100)
    }

    const fontElements = [`${fontPrimary}:${weights.join(',')}`]
    if (fontHeaders) fontElements.push(`${fontHeaders}:${weights.join(',')}`)
    setVar('fontFamilies', fontElements)
    setVar('--font-family-primary', fontPrimary)
    setVar('--font-family-headers', fontHeaders || fontPrimary)
  }

  if (buttonBorderRadius) setVar('--prm-btn-border-radius', buttonBorderRadius)
  if (background) setVar('--background', background)
  if (priceColor) setVar('--price-color', priceColor)
  if (errorTextColor) setVar('--error-text-color', errorTextColor)
  if (errorBgColor) setVar('--error-bg-color', errorBgColor)
  if (headerBgColor) setVar('--header-bg-color', headerBgColor)

  const mapButtonColor = () => {
    const darkerColor = darken(0.05, buttonColor)
    const lighterColor = lighten(0.05, buttonColor)

    setVar('--prm-btn-bg-color', buttonColor)
    setVar('--prm-btn-bg-color--disabled', transparentize(0.7, buttonColor))
    setVar(
      '--prm-btn-bg-color--hover',
      textColorPicker(buttonColor, {
        darkColor: darkerColor,
        lightColor: lighterColor,
      }),
    )
    setVar('--prm-btn-text-color', textColorPicker(buttonColor))
    setVar('--prm-btn-text-color--disabled', 'hsl(220, 11%, 20%)')
    setVar('--brand-text-color', buttonColor)
  }

  const mapElementsColor = () => {
    const darkerColor = darken(0.02, elementsColor)
    const lighterColor = lighten(0.02, elementsColor)

    setVar('--scn-btn-bg-color--active', elementsColor)
    setVar('--scn-btn-text-color--active', textColorPicker(elementsColor))

    setVar(
      '--scn-btn-bg-color--hover',
      isDark(elementsColor) ? lighterColor : darkerColor,
    )
    setVar('--scn-btn-text-color--hover', textColorPicker(elementsColor))

    let colorToApply = elementsColor
    if (is2Colors) colorToApply = buttonColor

    setVar('--progress-bar-gradient-color-1', colorToApply)
    setVar('--progress-bar-gradient-color-2', colorToApply, {
      lightColor: lighten(0.2, colorToApply),
      darkColor: darken(0.2, colorToApply),
    })

    if (is2Colors) {
      setVar('--progress-bar-bg-color', elementsColor)
      setVar('--progress-bar-bg-active', elementsColor)
      setVar('--progress-bar-step-bg-color--active', elementsColor)
    }

    setVar('--network-btn-bg-color', elementsColor)
    setVar('--network-btn-border-color', elementsColor)
    setVar('--network-btn-label-bg-color', elementsColor, {
      active: whiteColor,
    })

    setTextColor(
      '--network-btn-badge-bg-color',
      '--network-btn-badge-text-color',
    )
    setTextColor(
      '--network-btn-label-bg-color',
      '--network-btn-label-text-color',
    )

    setVar('--box-border-color--active', darkerColor)
    setVar('--box-text-color--active', mix(0.5, 'black', elementsColor))

    setVar('--field-select-bg-color', getVar('--scn-btn-bg-color'), {
      active: getVar('--scn-btn-bg-color--active'),
    })
    setVar('--field-select-text-color', getVar('--scn-btn-text-color'), {
      active: getVar('--scn-btn-text-color--active'),
    })

    setVar('--footer-bg-color-1', whiteColor)
    setVar('--footer-bg-color-2', whiteColor)
    setVar('--footer-text-color', 'hsl(220, 11%, 43%)')
    setVar('--footer-logo-color', 'hsl(220, 11%, 43%)')
  }

  if (buttonColor) {
    mapButtonColor()
    mapElementsColor(obj.elementsColor || buttonColor)
  }

  return { ...allVars, ...vars }
}

export const getVariables = (frontendAssetId) => {
  const rootVars = get(variables, 'root', {})
  const shopVars = get(variables, frontendAssetId, {})

  const oldAllVars = {
    ...rootVars,
    ...shopVars,
  }

  const allVars = getCssVariablesForShop(frontendAssetId, oldAllVars)

  return allVars || oldAllVars
}

export const applyVariables = (frontendAssetId) => {
  const vars = getVariables(frontendAssetId)
  Object.entries(vars).forEach((entry) =>
    document.documentElement.style.setProperty(entry[0], entry[1]),
  )
}
