import React from 'react'
import { withNamespaces } from 'react-i18next'
import CtaButton from '../../../components/CtaButton'

export function NotAvailable({ t, goBack }) {
  return (
    <div className="time-slots-not-available__wrapper">
      <div className="time-slots-not-available__jumbo">
        {t('time-slots.not-available.jumbo')}
      </div>
      <p>
        {t('time-slots.not-available.first_line')}
        <br />
        {t('time-slots.not-available.second_line')}
      </p>
      <div className="center-content">
        <CtaButton
          text={`${t('time-slots.not-available.cta')}`}
          type="submit"
          onClick={goBack}
        />
      </div>
    </div>
  )
}

export default withNamespaces('translation')(NotAvailable)
