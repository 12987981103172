import root from './root'
import voordeelvanger from './voordeelvanger'
import kellyjeans from './kellyjeans'
import werkschoenenbaas from './werkschoenenbaas'

export default {
  root,
  voordeelvanger,
  kellyjeans,
  werkschoenenbaas, // LEAVE IT
  // suitable, // NON EXIST
  // bascom, // NON-EXIST
  // bascomrepair: bascom, // NON-EXIST
  // "sans-online": sansOnline, // NO SUCH SHOP
  // "kixx-online": kixxOnline, // NON-EXIST
  // shoesbyarmel, // NON-EXIST
}
