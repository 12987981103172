import { dispatch } from 'store'
import orderActions from 'features/order/actions'

const setOrder = async (values) => dispatch(orderActions.setOrder(values))
const fetchOrder = async (orderId) => dispatch(orderActions.getOrder(orderId))
const clearOrder = async () => dispatch(orderActions.clearOrder())
const updateOrder = async (values) =>
  dispatch(orderActions.updateShipment(values))
const clearOrderId = async () => setOrder({ id: undefined })

export const orderAction = {
  setOrder,
  fetchOrder,
  clearOrder,
  updateOrder,
  clearOrderId,
}
