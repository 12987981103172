import React from 'react'
import { withNamespaces } from 'react-i18next'
import numeral from 'numeral'

export function Price({ price, t, showFree = false }) {
  let priceText = ''
  if (price || price === 0) {
    const elements = [t('price')]
    if (price === 0 && showFree) elements.push(t('details.form.free'))

    if (price > 0) {
      const formattedPrice = numeral(price / 100).format('$0,0.00')
      elements.push(formattedPrice)
    }
    priceText = elements.join(': ')
  }

  return (
    <div className="price">
      <p className="form__price">{priceText}</p>
    </div>
  )
}

export default withNamespaces('translation')(Price)
