import moment from 'moment'
import { capitalize } from 'lib/helpers'

export const formatDateAsDay = (date) => parseInt(moment(date).format('D'), 10)

export const formatDateAsDayWeek = (date, locale) =>
  capitalize(moment(date).locale(locale).format('dd'))

export const formatAsDay = (date, locale) =>
  capitalize(moment(date).locale(locale).format('dddd'))

export const getTomorrowDay = () => formatDateAsDay(moment().add(1, 'days'))

export const formatDateAsHour = (date) => moment(date).format('H:mm')

export const formatAsLongDate = (date, locale) =>
  capitalize(moment(date).locale(locale).format('dddd D MMM'))

export const formatAsMomentDate = (date) => moment(date)
