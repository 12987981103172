import React from 'react'
import { withNamespaces } from 'react-i18next'

function CheckBoxElement(props) {
  const {
    label,
    name,
    required,
    errors = {},
    linkText,
    linkUrl,
    tooltip,
    value,
    onClick,
    style,
    onChange,
  } = props

  const checked = [true, 'true'].includes(value)

  return (
    <div
      className={`checkbox__wrapper ${
        errors[name]
          ? 'checkbox__wrapper-with-errors'
          : 'checkbox__wrapper-is-valid'
      }`}
      style={style}
    >
      <label
        className={
          required
            ? 'checkbox__container checkbox__container--required'
            : 'checkbox__container'
        }
      >
        {label}
        <input
          type="checkbox"
          onClick={onClick}
          onChange={onChange ? () => onChange(!checked) : undefined}
          checked={checked}
        />
        {linkUrl ? (
          <a
            className="checkbox__link"
            href={linkUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {linkText}
          </a>
        ) : null}
        <span className="checkbox__checkmark" />
      </label>
      {tooltip}
      {/* {errors[name] ? <span>{t(errors[name])}</span> : null} */}
    </div>
  )
}

export default withNamespaces('translation')(CheckBoxElement)
