import { configureStore } from '@reduxjs/toolkit'
import rootReducer, { defaultState } from 'features/rootReducer'
import { promiseMiddleware } from '@adobe/redux-saga-promise'
import createSagaMiddleware from 'redux-saga'
import logger from 'redux-logger'
import freeze from 'redux-freeze'
import rootSaga from 'features/rootSaga'
// eslint-disable-next-line
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { persistStore, persistReducer } from 'redux-persist'
// import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import storage from 'redux-persist/lib/storage/session'
import { APP } from 'features/app/actions'
export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()
const prodMiddleware = [
  promiseMiddleware,
  sagaMiddleware,
  routerMiddleware(history),
]

const middleware =
  process.env.NODE_ENV === 'development'
    ? [logger, freeze, ...prodMiddleware]
    : prodMiddleware

const persistedReducer = persistReducer(
  { key: 'root', storage, blacklist: ['shop', 'app'] },
  rootReducer(history),
)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [APP.SEND_ERROR],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['meta', 'register', 'rehydrate'],
        // Ignore these paths in the state
        ignoredPaths: ['items.dates'],
      },
    }).concat(middleware),
  preloadedState: defaultState,
})
export const { dispatch } = store
export const persistor = persistStore(store)

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept(rootReducer, () =>
    store.replaceReducer(rootReducer(history)),
  )
}

sagaMiddleware.run(rootSaga)
