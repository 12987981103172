import { get } from 'lib/lodash'
import { isBlank } from './helpers'

export const isNlDomain = /returnista.nl/.test(window.location.href)
export const isComDomain = /returnista.com/.test(window.location.href)

export const international = (order) => {
  const shipFromCountryCode = get(order, 'shipFromCountryCode')
  if (shipFromCountryCode) {
    return shipFromCountryCode !== 'nl'
  }

  return isComDomain
}

export const isForeignCountry = (value) => !isBlank(value) && value !== 'nl'
export const isLocalCountry = (value) => value === 'nl'
