import React, { useState, useEffect } from 'react'
import i18n from 'lib/i18n'
import { withNamespaces } from 'react-i18next'
import { get, isEmpty, isEqual } from 'lib/lodash'
import Days from 'containers/Timeslots/Fields/Days'
import Timeslots from 'containers/Timeslots/Fields/Timeslots'
import { SHIPMENT_FIELDS, TIMESLOT_FIELDS } from 'config/constants'
import { isBlank } from 'lib/helpers'
import { getDaysIndex, getDaysList, whatDay } from './formatter'

const { TIMESLOT_ID } = SHIPMENT_FIELDS
const getDate = (timeslot) => new Date(timeslot.start).getDate()

function Fields(props) {
  const { form, timeslots = [] } = props
  const incomingLocale = i18n.language.substring(0, 2)
  const [locale, setLocale] = useState()
  const [orderTimeslot, setOrderTimeslot] = useState()

  useEffect(() => {
    if (isEqual(incomingLocale, locale)) return

    setLocale(incomingLocale)
  }, [incomingLocale])

  const [daysList, setDaysList] = useState([])
  const [daysIndex, setDaysIndex] = useState({})
  const [day, setDay] = useState()
  const [timeslot, setTimeslot] = useState()
  const [dayTimeslots, setDayTimeslots] = useState([])

  const findOrderTimeslot = () => {
    const foundTimeslot = timeslots.find(
      (tsElement) => tsElement.id === form.values.timeslotId,
    )
    if (!foundTimeslot) return undefined

    setOrderTimeslot(foundTimeslot)
    return { ...foundTimeslot, day: getDate(foundTimeslot) }
  }

  const getDaysTimeslots = (chosenDay, newDaysIndex = {}) =>
    get(newDaysIndex[chosenDay] || daysIndex[chosenDay], 'timeslots', [])

  const setFoundtimeslot = (targetTimeslot, newDaysIndex) => {
    const targetDay = targetTimeslot.day
    setDay(targetDay)

    const targetDayTimeslots = getDaysTimeslots(targetDay, newDaysIndex)
    setDayTimeslots(targetDayTimeslots)

    setTimeslot(targetTimeslot)
  }

  const setFirstTimeslot = (targetDay, newDaysIndex) => {
    setDay(targetDay)

    const targetDayTimeslots = getDaysTimeslots(targetDay, newDaysIndex)
    setDayTimeslots(targetDayTimeslots)

    const targetTimeslot = targetDayTimeslots[0]
    setTimeslot(targetTimeslot)
  }

  const formatTimeslots = () => {
    const newDaysIndex = getDaysIndex(timeslots, locale)
    const newDaysList = getDaysList(newDaysIndex)
    setDaysList(newDaysList)
    setDaysIndex(newDaysIndex)

    const foundTS = findOrderTimeslot()
    if (foundTS) return setFoundtimeslot(foundTS, newDaysIndex)

    const firstDay = newDaysList[0]
    setFirstTimeslot(firstDay, newDaysIndex)

    return null
  }

  const selectDay = (chosenDay) => {
    setDay(chosenDay)
    const newDayTimeslots = getDaysTimeslots(chosenDay)
    const firstTimeslot = newDayTimeslots[0]
    setTimeslot(firstTimeslot)
  }

  useEffect(() => {
    if (!timeslots || isEmpty(timeslots)) return
    if (isBlank(locale)) return
    if (
      !isEqual(locale, incomingLocale) &&
      (!isEmpty(daysList) || !isEmpty(daysIndex))
    )
      return

    formatTimeslots()
  }, [timeslots, locale])

  useEffect(() => {
    if (!timeslot) return

    form.setFieldValue(TIMESLOT_ID, get(timeslot, TIMESLOT_FIELDS.ID))
  }, [timeslot])

  if (dayTimeslots.length === 0) {
    return <div />
  }

  return (
    <div className="time-slots-fields__container">
      <div className="day-slider__container">
        <Days
          daysIndex={daysIndex}
          daysList={daysList}
          selectDay={selectDay}
          orderDay={whatDay(orderTimeslot)}
        />
      </div>
      <Timeslots
        dayTimeslots={getDaysTimeslots(day)}
        selectTimeslot={setTimeslot}
        selectedTimeslot={timeslot}
      />
    </div>
  )
}

export default withNamespaces('translation')(Fields)
