import { isNil, get } from 'lib/lodash'

const countryCodesAllowed = {
  stoov: ['de'],
  rogon: ['fr'],
  raven: ['pl', 'cz', 'be', 'fr'],
  wannahaves: ['be', 'de'],
  plusman: ['be', 'de', 'at'],
  kleidermafia: ['de', 'fr'],
  kwsseurende: ['de', 'at'],
  naduvi: ['be', 'de'],
  gorillasports: ['be'],
}

const slug1608 = '1608'
const maxParcels = {
  kwsseuren: 1,
  kwsseurende: 1,
  glasscandinavia: 1,
  aurelien: 1,
  [slug1608]: 1,
  '1608credit-exchanges': 1,
}

const shopSupportEmails = {
  tuinland: 'webshop@tuinland.nl',
  mamaloes: 'klantenservice@mamaloesbabysjop.nl',
  decathlon: 'klantenservice@decathlon.nl',
}

const shopSupportPhones = {
  mamaloes: '+31 132205959',
}
const nlNotAllowedShops = {
  kwsseurende: true,
  rogon: true,
}

const settingsForShops = {
  showPhoneNumberField: (shop) =>
    get(shop, 'gatekeeperEnabled') &&
    get(shop, 'frontendAssetId') !== 'voordeelvanger',
  countryCodesAllowed: (shop) => {
    let res = []
    const nlNotAllowed = get(nlNotAllowedShops, get(shop, 'frontendAssetId'))
    if (!nlNotAllowed) {
      res.push('nl')
    }
    res = [
      ...res,
      ...get(countryCodesAllowed, get(shop, 'frontendAssetId'), []),
    ]
    return res
  },
  maxParcels: (shop) => get(maxParcels, get(shop, 'frontendAssetId')),
  shopSupportEmail: (shop) =>
    get(shopSupportEmails, get(shop, 'frontendAssetId')),
  shopSupportPhone: (shop) =>
    get(shopSupportPhones, get(shop, 'frontendAssetId')),
}

const settings = {
  hideOtherCountry: ['kwsseurende', 'naduvi', 'gorillasports', 'rogon'],
  singlePrice: ['naduvi'],
  hideFlyingBox: [
    'alixthelabel',
    'plusman',
    'mamaloes',
    'tuinland',
    'bronx',
    'decathlon',
    'glasscandinavia',
    'exchange-glasscandinavia',
  ],
  hideIntroTitle: ['mamaloes', 'exchange-glasscandinavia'],
  hideFooterText: ['mamaloes'],
  consentRequired: ['moostwanted'],
  networkButtonsWithNotes: ['naduvi'],
  verticalNetworkButtons: ['bronx'],
  hideConfirmationPageEmojis: ['siebel', 'gorillasports'],
  sortReturnReasons: ['siebel', 'dirkdewitmode'],
  showAllCountries: ['glasscandinavia', 'exchange-glasscandinavia'],
}

function shopSettings(setting, shop, defaultValue) {
  const shopIds = get(settings, setting)
  if (shopIds) return shopIds.includes(get(shop, 'frontendAssetId'))

  const method = get(settingsForShops, setting)
  if (isNil(method)) return defaultValue
  if (isNil(shop)) return defaultValue

  const result = method(shop)
  if (isNil(result) && !isNil(defaultValue)) return defaultValue

  return result
}

export default shopSettings
