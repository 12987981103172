import { APP } from './actions'

export const INITIAL_STATE = {
  setFontsOk: undefined,
  setSettingsOk: undefined,
  error: null,
  locale: undefined,
}

export default (state = INITIAL_STATE, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case APP.SET_ERROR:
      return {
        ...state,
        error: payload,
      }
    case APP.SET_FONTS_OK:
      return {
        ...state,
        setFontsOk: true,
      }
    case APP.SET_SETTINGS_OK:
      return {
        ...state,
        setSettingsOk: true,
      }
    case APP.CLEAR_LOADING:
      return {
        ...state,
        setSettingsOk: undefined,
        setFontsOk: undefined,
      }
    case APP.SET_LOCALE: {
      return {
        ...state,
        locale: payload,
      }
    }
    case APP.CLEAR_ERROR: {
      return {
        ...state,
        error: null,
      }
    }
    default:
      return state
  }
}
