import { getAddressValidation } from 'lib/getValidation'
import { get, pick } from 'lib/lodash'
import yup from './yup'
import { mapRequired } from './details'
import * as constants from './constants.js'

export const internationalValidators = {
  shipFromCountryCode: yup.string().notOneOf(['other', 'nl']),
  shipFromPostalCode: yup.string().max(9),
  shipFromStateProvinceCode: yup.string(),
  shipFromCity: yup.string().max(31),
  shipFromAddressLine: yup.string().max(105),
}

export const localValidators = {
  postalCode: yup
    .string()
    .matches(
      constants.postalCodeRegex,
      'address.form.postal_code.validation_message',
    )
    .nullable(true),
  postalCodeEnd: yup.string().when('postalCode', {
    is: (val) => get(val, 'length') === 4,
    then: yup
      .string()
      .matches(
        constants.postalCodeEndRegex,
        'address.form.postal_code.validation_message',
      )
      .nullable(),
  }),
  houseNumber: yup.string().when(['postalCode', 'postalCodeEnd'], {
    is: (postalCodeEnd, postalCode) => postalCodeEnd && postalCode,
    then: yup
      .string()
      .matches(
        constants.houseNumberRegex,
        'address.form.house_number.validation_message',
      )
      .nullable(),
  }),
  houseAddition: yup.string().when('houseNumber', {
    is: (val) => val,
    then: yup
      .string()
      .matches(
        constants.houseAdditionRegex,
        'address.form.house_addition.validation_message',
      )
      .max(4)
      .nullable(),
  }),
  street: yup
    .string()
    .nullable()
    .when(['postalCode', 'postalCodeEnd', 'houseNumber'], {
      is: (postalCode, postalCodeEnd, houseNumber) =>
        postalCode && postalCodeEnd && houseNumber,
      then: yup
        .string()
        .matches(
          constants.streetNameRegex,
          'address.form.street_name.validation_message',
        ),
    }),
  city: yup
    .string()
    .nullable()
    .when(['postalCode', 'postalCodeEnd', 'houseNumber'], {
      is: (postalCode, postalCodeEnd, houseNumber) =>
        postalCode && postalCodeEnd && houseNumber,
      then: yup
        .string()
        .matches(
          constants.cityRegex,
          'address.form.shipFromCity.validation_message',
        ),
    }),
}

export function mapValidatorsAddress({
  validators,
  validateFields = [],
  requiredFields,
}) {
  const validationsInUse = pick(validators, validateFields)

  const result = {}
  const fieldNames = Object.keys(validationsInUse)

  for (let i = 0; i < fieldNames.length; i += 1) {
    const name = fieldNames[i]
    result[name] = mapRequired({ validationsInUse, requiredFields, name })
  }

  return result
}

export const addressSchemaValidations = ({ shop, values }) => {
  const props = getAddressValidation({
    shop,
    values,
  })

  props.validators = localValidators
  if (props.isInternational) props.validators = internationalValidators

  return mapValidatorsAddress(props)
}

export const addressSchema = ({ shop, values }) =>
  yup.object().shape(addressSchemaValidations({ shop, values }))
