import React from 'react'
import { withNamespaces } from 'react-i18next'
import ShamedEmoji from 'components/ShamedEmoji'
import CtaButton from 'components/CtaButton'
import i18n from 'lib/i18n'
import { styled } from 'lib/styled'
import { useSelector } from 'react-redux'
import { getApp } from 'lib/selectors'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

function ErrorContainer(props) {
  const { t, history } = props
  const { error: errorCode } = useSelector(getApp)
  // if (!errorCode) window.open("/", "_self");
  if (!errorCode) return null

  const { hostname } = window.location

  const handleCtaClick = () => window.open('/', '_self')
  const addresses = []
  const shopNotFound = parseInt(errorCode, 10) === 401
  if (shopNotFound) {
    addresses.push(hostname)

    if (hostname.includes('returnista.nl'))
      addresses.push(hostname.replace('returnista.nl', 'returnista.com'))

    if (hostname.includes('returnista.com'))
      addresses.push(hostname.replace('returnista.com', 'returnista.nl'))
  }

  const errorKey = `error.${errorCode}.cta`
  let ctaText
  if (i18n.exists(errorKey)) ctaText = t(errorKey)

  if (!errorCode) history.push('/')

  return (
    <Container>
      <ShamedEmoji />
      <div className="section centered">
        <h2>{t(`error.${errorCode}.heading`)}</h2>
      </div>
      {shopNotFound &&
        addresses.map((address) => (
          <div className="centered">
            <p>{address}</p>
          </div>
        ))}
      <div className="section centered">
        <p>{t(`error.${errorCode}.description`)}</p>
      </div>

      {ctaText && (
        <div className="section centered">
          <div className="center-content">
            <CtaButton id="pickup" text={ctaText} onClick={handleCtaClick} />
          </div>
        </div>
      )}
    </Container>
  )
}

export default withNamespaces('translation')(ErrorContainer)
