import React from 'react'
import { get } from 'lib/lodash'
import Button from './Button'

function Content({ dayTimeslots, selectTimeslot, selectedTimeslot }) {
  const selectedTimeslotIndex = dayTimeslots.findIndex(
    (dayTimeslot) => dayTimeslot.id === get(selectedTimeslot, 'id'),
  )

  return (
    <section>
      <div className="time-slots">
        {dayTimeslots.map((timeslot, index) => {
          const { start, stop, id } = timeslot
          const isDefault = index === 0
          const selected = index === selectedTimeslotIndex
          return (
            <div
              role="presentation"
              className={`time-slots__wrapper ${isDefault ? 'default' : ''}`}
              key={id}
              onClick={() => selectTimeslot(timeslot)}
            >
              <Button
                start={start}
                stop={stop}
                isSelected={selected}
                isDefault={isDefault}
              />
            </div>
          )
        })}
      </div>
    </section>
  )
}
export default Content
