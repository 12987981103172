import React from 'react'
import FieldInput, { focusChangeByMaxLength } from 'components/FieldInput'
import FieldWrapper from 'components/FieldWrapper'
import { get } from 'lib/lodash'
import { isFieldDirty } from 'lib/formHelpers'
import { LOCAL_ADDRESS_FIELDS } from 'config/constants'

const { POSTAL_CODE, POSTAL_CODE_END } = LOCAL_ADDRESS_FIELDS

export default function ShipFromPostalCode(props) {
  const { errors, values, touched, classNames } = props
  const value = get(values, POSTAL_CODE_END)
  const error = get(errors, POSTAL_CODE_END)
  const postalCodeError = get(errors, POSTAL_CODE)

  const postalCodeEndProps = {
    ...props,
    id: POSTAL_CODE_END,
    name: POSTAL_CODE_END,
    minLength: 2,
    maxLength: 2,
    onChange: focusChangeByMaxLength,
    showErrorCharacter: false,
    classNames: `${classNames} uppercase`,
    isFieldDirty: isFieldDirty(touched, POSTAL_CODE_END),
    value,
    grouped: true,
    showError: !postalCodeError,
  }

  return (
    <FieldWrapper
      errors={error}
      filled={value}
      classNames="is-expanded control--xs input-postalcode-end"
    >
      <FieldInput {...postalCodeEndProps} />
    </FieldWrapper>
  )
}
