import { call, put } from 'redux-saga/effects'
import { implementPromiseAction } from '@adobe/redux-saga-promise'
import WebFont from 'webfontloader'
import { dispatch } from 'store'
import { applyVariables, getVariables } from 'config/customization'
import { get } from 'lib/lodash'
import { isBlank, trim } from 'lib/helpers'
import rootApiCatch from 'features/rootApiCatch'
import api from '../rootApi'
import appActions from './actions'

function setFonts(action) {
  const { fontFamilies } = action

  WebFont.load({
    google: {
      families: fontFamilies,
    },
    active: () => dispatch(appActions.setFontsOk()),
    inactive: () => dispatch(appActions.setFontsOk()),
  })
}

export function* setSettings(action) {
  yield call(implementPromiseAction, action, function* () {
    const { payload: frontendAssetId } = action
    const variables = getVariables(frontendAssetId)
    const fontFamilies = get(variables, 'fontFamilies')
    setFonts({ fontFamilies })
    applyVariables(frontendAssetId)

    yield put(appActions.setSettingsOk())
  })
}

const formatStack = (str) => {
  if (!str) return []

  return str
    .split('\n')
    .filter((el) => !isBlank(el))
    .map((el) => trim(el))
}

function toJSON(error) {
  const alt = {}

  Object.getOwnPropertyNames(error).forEach(function (key) {
    alt[key] = this[key]
  }, this)

  return alt
}

export function* sendError(action) {
  yield call(implementPromiseAction, action, function* () {
    const { payload } = action

    let error = get(payload, 'error')
    if (error) error = toJSON(error)

    const message = get(error, 'message')

    let stack = get(error, 'stack')
    if (stack) stack = formatStack(stack)

    let errorInfo = get(payload, 'errorInfo')
    if (errorInfo) errorInfo = formatStack(errorInfo.componentStack)

    const debugInfo = get(payload, 'debugInfo')

    const variables = {
      message,
      stack,
      info: errorInfo,
      debugInfo,
    }

    console.log('ERROR', variables)

    const requestPayload = { query: 'op:LogError', variables }
    try {
      yield api(requestPayload)
    } catch (e) {
      yield put(rootApiCatch(requestPayload, e, { notify: false }))
      throw e
    }
  })
}
