import { isNil, get, isNaN } from 'lib/lodash'
import { langCode } from 'lib/i18n'

export const isBlank = (value) => isNil(value) || value === ''
export const isZero = (value) => isNaN(+value) || +value <= 0
export const getTermsAndConditionsUrl = (shop) => {
  const termsAndConditionsUrls = get(shop, 'termsAndConditionsUrls')
  return get(
    termsAndConditionsUrls,
    langCode(),
    get(termsAndConditionsUrls, 'default'),
  )
}
export const getDefault = (object, path = '', defaultValue) => {
  const value = get(object, path)
  return isBlank(value) ? defaultValue : value
}

export const populateWithBlanks = (obj) => {
  const res = {}
  Object.keys(obj).forEach((key) => {
    res[key] = getDefault(obj, key, '')
  })
  return res
}

export const cleanFromBlanks = (obj) => {
  const res = {}
  Object.keys(obj).forEach((key) => {
    const value = get(obj, key)
    res[key] = value === '' ? null : value
  })
  return res
}

export const trim = (string) => string.replace(/^\s+|\s+$/g, '')

export const getPricelistCategoryName = ({
  isInternational,
  returnType,
  size,
}) => {
  const parts = []
  parts.push(isInternational ? 'international' : 'local')
  parts.push(capitalize(returnType))
  parts.push(capitalize(size))
  return parts.join('')
}

export function capitalize(string) {
  if (isNil(string)) return string

  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function longestText(texts = []) {
  if (texts.length === 0) return 0
  return Math.max(...texts.map((el) => el.length))
}

export function truncate(str, num) {
  if (!str) return null
  if (str.length <= num) return str
  return `${str.slice(0, num)}...`
}
