import React from 'react'
import { dispatch } from 'store'
import appActions from 'features/app/actions'
import ErrorPage from 'containers/Error'
import { connect } from 'react-redux'
import { styled } from 'lib/styled'

const ErrorContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  & div {
    align-items: center;
    justify-content: center;
  }
`

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    dispatch(appActions.sendError({ error, errorInfo }))
    dispatch(appActions.setError(500))
  }

  render() {
    if (this.state.hasError)
      return (
        <ErrorContainer>
          <ErrorPage />
        </ErrorContainer>
      )
    return this.props.children
  }
}
const mapStateToProps = (state) => ({ error: state.app.error })

export default connect(mapStateToProps)(ErrorBoundary)
