import { trim } from 'lib/helpers'

export default function classNames(classnames) {
  if (typeof classnames !== 'object')
    throw new Error('Please pass classNames a single nested object')

  let result = ''

  Object.entries(classnames).forEach(([key, value]) => {
    if (Boolean(value) === true) {
      result += `${key} `
    }
  })

  return trim(result)
}
