import { PostalCodeConstructor } from 'lib/postalCode'
import { call, put } from 'redux-saga/effects'
import { implementPromiseAction } from '@adobe/redux-saga-promise'
import { get } from 'lib/lodash'
import api from '../rootApi'
import rootApiCatch from '../rootApiCatch'

export function* validateAddress(action) {
  yield call(implementPromiseAction, action, function* () {
    const { payload } = action
    const { postalCode, postalCodeEnd, houseNumber, houseAddition } = payload
    const variables = {
      postalCode: PostalCodeConstructor(postalCode, postalCodeEnd),
      houseNumber: `${houseNumber}${houseAddition ? ` ${houseAddition}` : ''}`,
    }
    const requestPayload = {
      query: 'op:ResolveAddress',
      variables,
    }

    let response
    try {
      response = yield api(requestPayload)
    } catch (e) {
      yield put(rootApiCatch(requestPayload, e))
      throw e
    }

    const address = get(response, 'data.data.address', {})
    return address
  })
}
