import React from 'react'
import { useSelector } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import CtaButton from 'components/CtaButton'
import { get, isEmpty } from 'lib/lodash'
import { getShop } from 'lib/selectors'
import i18n, { getTransKey, getTransKeyWODefault, langCode } from 'lib/i18n'
import './index.scss'

function ShopsPage(props) {
  const { t } = props
  const shop = useSelector(getShop)
  if (!shop || isEmpty(shop)) return null

  const shopOptions = get(shop, 'shopOptions')
  const openShop = (shopOption) => {
    const currentShopSlug = get(shop, 'slug')
    if (shopOption.slug === currentShopSlug)
      return window.open(
        `${shopOption.portalLink}/?locale=${langCode()}&root=true`,
        '_self',
      )

    return window.open(
      `${shopOption.portalLink}/?locale=${langCode()}`,
      '_self',
    )
  }

  const buttons = []
  const lengths = []
  shopOptions.forEach((shopOption) => {
    let text = shopOption.nickname || shopOption.name
    const keyToTranslate = `shopsPage.nickname.${shopOption.nickname}`
    if (i18n.exists(keyToTranslate)) text = t(keyToTranslate)

    lengths.push(text.length)
    buttons.push({ text, onClick: () => openShop(shopOption) })
  })

  const maxLength = Math.max(...lengths)
  const renderShops = () => {
    buttons.map((el) => {
      const element = el
      element.buttonLength = maxLength + 4
      return element
    })
    return buttons.map((attrs) => <CtaButton {...attrs} key={attrs.text} />)
  }

  return (
    <div className="shops-page">
      <div className="shops-page__headers">
        <h2 style={{ marginBottom: '1rem' }}>
          {t(getTransKey('shopsPage.header', shop))}
        </h2>
        <h3 className="dimmed">
          {t(getTransKey('shopsPage.subHeader', shop))}
        </h3>
      </div>
      <div className="shops-page__buttons">{renderShops()}</div>
    </div>
  )
}

export default withNamespaces('translation')(ShopsPage)
