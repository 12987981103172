import React, { useState, useEffect } from 'react'
import { withNamespaces } from 'react-i18next'
import { get, pick } from 'lib/lodash'
import { Formik, Form } from 'formik'
import Title from 'components/Title'
import CtaButton from 'components/CtaButton'
import { populateWithBlanks } from 'lib/helpers'
import { getTransKey } from 'lib/i18n'
import {
  SHIPMENT_FIELDS,
  LOCAL_ADDRESS_FIELDS,
  INTERNATIONAL_ADDRESS_FIELDS,
} from 'config/constants'
import useHistory from 'hooks/useHistory'
import useOrder from 'hooks/useOrder'
import { addressSchema } from 'lib/validationSchemas/address'
import * as yup from 'yup'
import useActions from 'hooks/useActions'
import { orderAction } from 'lib/actions'
import AddressFields from './AddressFields'

const { SHIP_FROM_POSTAL_CODE } = SHIPMENT_FIELDS

function AddressContainer(props) {
  const { noData, shop, order, isPickup, isDropoff } = useOrder()
  // const { appActions } = useActions()

  const [titleKey, setTitleKey] = useState()
  const [initialValues, setInitialValues] = useState()
  const [loading, setLoading] = useState(false)
  const { goToNextStep, goToLanding } = useHistory()

  useEffect(() => {
    if (initialValues) return
    if (noData) return

    if (!order.network || !order.numberOfBoxes) {
      // This happens when a cutomer follows the direct line to /pickup/address from the shop's page.
      // In this case it should populate the default values for DHL pickup.
      // So this is a TODO.

      // appActions.sendError({
      //   debugInfo: {
      //     customMessage: `Empty order error`,
      //     href: window.location.href,
      //   },
      // })
      goToLanding()
      return
    }

    let newTitleKey
    if (isDropoff) newTitleKey = 'address.dropoff-title'
    if (isPickup) newTitleKey = 'address.title'
    if (!newTitleKey)
      throw new Error(
        `Can't pickup address page title, order: ${JSON.stringify(order)}`,
      )
    setTitleKey(newTitleKey)

    setInitialValues(populateWithBlanks(order))
    orderAction.clearOrderId()
  }, [order])

  if (noData) return null

  const { t } = props

  const postalCode = get(order, LOCAL_ADDRESS_FIELDS.POSTAL_CODE)
  const shipFromPostalCode = get(order, SHIP_FROM_POSTAL_CODE)
  const formWasFilledInBefore = postalCode || shipFromPostalCode

  const onSubmit = (values) => {
    const addressValues = pick(values, [
      ...Object.values(LOCAL_ADDRESS_FIELDS),
      ...Object.values(INTERNATIONAL_ADDRESS_FIELDS),
    ])
    orderAction.setOrder(addressValues)
    goToNextStep()
  }

  if (!initialValues) return null

  return (
    <>
      <Title text={`${t(getTransKey(titleKey, shop))}`} />

      <Formik
        isInitialValid={formWasFilledInBefore}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={() =>
          yup.lazy((values) => addressSchema({ shop, values }))
        }
        onSubmit={(values, { setSubmitting, setErrors }) =>
          onSubmit(values, setSubmitting, setErrors)
        }
      >
        {(form) => {
          const { isSubmitting, isValid } = form
          return (
            <Form>
              <AddressFields
                form={form}
                order={order}
                setLoading={setLoading}
              />
              <div
                className="field center-content"
                style={{ marginTop: '2rem' }}
              >
                <CtaButton
                  type="submit"
                  text={
                    isValid
                      ? t('address.form.valid')
                      : t('address.form.invalid')
                  }
                  disabled={!isValid || isSubmitting}
                  arrow="bottom"
                  submitting={isSubmitting || loading}
                />
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default withNamespaces('translation')(AddressContainer)
