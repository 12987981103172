import React from 'react'
import Lottie from 'react-lottie'
import startEmoji from 'assets/animations/starStruck.json'

function ConfirmationEmoji() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: startEmoji,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <div className="confirmation-emoji__container">
      <div className="confirmation-emoji__wrapper">
        <Lottie options={defaultOptions} height={76} width={86} />
      </div>
    </div>
  )
}

export default ConfirmationEmoji
