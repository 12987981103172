import { dispatch } from 'store'
import orderActions from 'features/order/actions'
import { SHIPMENT_FIELDS } from 'config/constants'
import { get } from 'lib/lodash'
import useHistory from 'hooks/useHistory'
import { getOrder } from 'lib/selectors'
import { useSelector } from 'react-redux'
import { isBlank } from 'lib/helpers'

const { ID, PAYMENT_URL } = SHIPMENT_FIELDS

export default () => {
  const { goToUrl, goToConfirmation } = useHistory()
  const order = useSelector(getOrder)

  const requestProceed = async () => {
    const data = await dispatch(orderActions.proceed(order))
    const paymentUrl = get(data, PAYMENT_URL)
    if (!isBlank(paymentUrl)) return goToUrl(paymentUrl)

    const orderId = get(data, ID)
    return goToConfirmation(orderId)
  }

  return { requestProceed }
}
