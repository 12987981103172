import React from 'react'
import cn from 'classnames'
import { times } from 'lib/lodash'
import useHistory from 'hooks/useHistory'

export default function ProgressBar() {
  const { noData, currentStep, stepsCount, goToStep, goBack } = useHistory()
  if (noData) return null

  const handleOnClick = (stepToNavigate) => {
    if (stepToNavigate <= currentStep) {
      goBack()
      goToStep(stepToNavigate)
    }
  }

  const progressBarHeight = 400
  const progress = (progressBarHeight / (stepsCount - 1)) * currentStep
  const progressBarClasses = {
    height: `${progress / 16}rem`,
  }

  return (
    <div className="progress">
      <div className="progress__bar" style={{ height: progressBarHeight }}>
        {times(stepsCount, (index) => {
          const pastedStep = index < currentStep
          return (
            <div
              role="presentation"
              id={`step-${index}`}
              key={index}
              className={cn('progress__step', {
                last: index + 1 === stepsCount,
                'progress__step--checked': pastedStep,
              })}
              onClick={() => handleOnClick(index + 1)}
            />
          )
        })}
        <div className={cn('progress__fill')} style={progressBarClasses}>
          <div
            className={cn(
              'has-text-centered progress__step--toned-down',
              'progress__step--current',
            )}
          >
            <span></span>
          </div>
        </div>
      </div>
    </div>
  )
}
