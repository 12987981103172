import { get, pick, times } from 'lib/lodash'
import { getDetailsValidation } from 'lib/getValidation'
import * as constants from './constants.js'
import yup from './yup'

export const mapRequired = ({ requiredFields, validationsInUse, name }) => {
  let validator = validationsInUse[name]
  if (requiredFields.includes(name))
    validator = validator.required('details.form.required_message')
  return validator
}

const validators = {
  name: yup
    .string()
    .max(22)
    .matches(constants.nameRegex, 'details.form.full_name.validation_message'),
  email: yup
    .string()
    .max(200)
    .matches(constants.emailRegex, 'details.form.email.validation_message'),
  termsAndConditions: yup
    .boolean()
    .oneOf([true], 'details.form.terms_conditions.validation_message'),
  consent: yup
    .boolean()
    .oneOf([true], 'details.form.consent.validation_message'),
  phone: yup
    .string()
    .matches(
      constants.phoneRegex,
      'details.form.phone_number.validation_message',
    )
    .min(8)
    .max(30),
  returnReasonId: yup.number().integer(),
  customReturnReason: yup.string().max(1000).nullable(true),
  image: yup
    .object()
    .test(
      'file-size',
      'details.form.image.too_big',
      (value) => get(value, 'size', 0) <= 15 * 1024 * 1024,
    )
    .test('file-type', 'details.form.image.wrong_type', (value) =>
      ['image/jpeg', 'image/jpg', 'image/png'].includes(
        get(value, 'type', 'image/jpeg'),
      ),
    )
    .nullable(true),
  comments: yup
    .string()
    .matches(
      constants.wordAndNumbersRegex,
      'details.form.courier_note.wrong_format',
    )
    .max(33)
    .nullable(true),
  item: yup.string().max(200),
}

export const mapValidatorsDetails = ({
  validateFields = [],
  requiredFields,
  validateItemAmount,
  requiredItemAmount,
}) => {
  const validationsInUse = pick(validators, validateFields)

  const result = {}
  const fieldNames = Object.keys(validationsInUse)
  for (let i = 0; i < fieldNames.length; i += 1) {
    const name = fieldNames[i]

    if (name === 'item' && validateItemAmount > 0) {
      times(validateItemAmount, (index) => {
        let validator = validationsInUse.item
        const arrayName = `item_${index}`
        if (requiredItemAmount > index)
          validator = validator.required('details.form.required_message')
        result[arrayName] = validator
      })
    } else {
      result[name] = mapRequired({ validationsInUse, requiredFields, name })
    }
  }

  return result
}

export const detailsSchema = ({ shop, values, itemAmount }) => {
  const props = getDetailsValidation({
    shop,
    values,
    itemAmount,
  })

  return yup.object().shape(mapValidatorsDetails(props))
}
