import { createPromiseAction } from '@adobe/redux-saga-promise'
import { createAction } from 'features/actionUtils'

export const TIMESLOTS = {
  GET_TIMESLOTS: 'TIMESLOTS_GET_TIMESLOTS',
  GET_TIMESLOTS_OK: 'TIMESLOTS_GET_TIMESLOTS_OK',
  GET_TIMESLOTS_FAIL: 'TIMESLOTS_GET_TIMESLOTS_FAIL',
}

export default {
  getTimeslots: createPromiseAction(TIMESLOTS.GET_TIMESLOTS),
  getTimeslotsOk: createAction(TIMESLOTS.GET_TIMESLOTS_OK),
  getTimeslotsFail: createAction(TIMESLOTS.GET_TIMESLOTS_FAIL),
}
