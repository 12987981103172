import React from 'react'
import { Field } from 'formik'
import { withNamespaces } from 'react-i18next'

function CheckBox(props) {
  const {
    label,
    name,
    required,
    errors,
    linkText,
    linkUrl,
    tooltip,
    wrapperStyle,
    labelStyle,
  } = props
  return (
    <Field {...props}>
      {({ field }) => (
        <div
          className={`checkbox__wrapper ${
            errors[name]
              ? 'checkbox__wrapper-with-errors'
              : 'checkbox__wrapper-is-valid'
          }`}
          style={wrapperStyle}
        >
          <label
            className={
              required
                ? 'checkbox__container checkbox__container--required'
                : 'checkbox__container'
            }
            style={labelStyle}
          >
            <input
              {...field}
              type="checkbox"
              checked={[true, 'true'].includes(field.value)}
            />
            {label}

            <span className="checkbox__checkmark" />
          </label>
          {linkUrl ? (
            <a
              className="checkbox__link"
              href={linkUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {linkText}
            </a>
          ) : null}
          {tooltip}
          {/* {errors[name] ? <span>{t(errors[name])}</span> : null} */}
        </div>
      )}
    </Field>
  )
}

export default withNamespaces('translation')(CheckBox)
