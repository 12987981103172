import { get, difference, pick, isEmpty } from 'lib/lodash'
import { isBlank } from 'lib/helpers'
import orderHelper from 'lib/orderHelper'

const getLocalPostalCode = ({ postalCode, postalCodeEnd }) =>
  [postalCode, postalCodeEnd].join('')
const getLocalHouseNumber = ({ houseNumber, houseAddition }) => {
  const shipFromHouseNumber = [houseNumber]
  if (!isBlank(houseAddition)) shipFromHouseNumber.push(houseAddition)
  return shipFromHouseNumber.join(' ')
}

export const commonShipmentFields = [
  'network',
  'numberOfBoxes',
  'name',
  'phone',
  'email',
  'orderNumber',
  'customReturnReason',
  'insured',
  'image',
  'termsAndConditions',
  'locale',
  'itemDescriptions',
  'returnReasonId',
]

const transformReturnReasonId = (vars = {}) => {
  const value = vars.returnReasonId
  let formatted = +value
  if (isBlank(value)) formatted = null
  return { ...vars, returnReasonId: formatted }
}

const transformItemsInVariables = (variables) => {
  let newVariables = { ...variables }
  const allKeys = Object.keys(variables)
  const itemKeys = allKeys.filter((key) => key.startsWith('item_'))
  const itemsObj = pick(variables, itemKeys)
  const restKeys = difference(allKeys, itemKeys)
  const itemDescriptions = Object.values(itemsObj).filter(
    (value) => !isBlank(value),
  )
  if (isEmpty(itemDescriptions)) {
    itemKeys.forEach((key) => delete newVariables[key])
    return newVariables
  }
  newVariables = { ...pick(variables, restKeys), itemDescriptions }

  return newVariables
}

const formatVariables = (vars) => {
  let variables = transformItemsInVariables(vars)
  variables = transformReturnReasonId(variables)
  return { ...variables, network: variables.network.toUpperCase() }
}

export const localDropoffMapper = (vars) => {
  const variables = formatVariables(vars)

  return {
    ...pick(variables, [...commonShipmentFields, 'size']),
    shipFromPostalCode: getLocalPostalCode(variables),
    shipFromHouseNumber: getLocalHouseNumber(variables),
  }
}

export const internationalDropoffMapper = (vars) => {
  const variables = formatVariables(vars)

  return {
    ...pick(variables, [
      ...commonShipmentFields,
      'size',
      'shipFromCountryCode',
      'shipFromCity',
      'shipFromPostalCode',
      'shipFromStateProvinceCode',
      'shipFromAddressLine',
      'shipFromStreet',
      'shipFromHouseNumber',
    ]),
  }
}

export const internationalPickupMapper = (vars) => {
  const variables = formatVariables(vars)

  return {
    ...pick(variables, [
      ...commonShipmentFields,
      'timeslotId',
      'size',
      'shipFromCountryCode',
      'shipFromPostalCode',
      'shipFromStateProvinceCode',
      'shipFromCity',
      'shipFromAddressLine',
    ]),
  }
}

export const localPickupMapper = (vars) => {
  const variables = formatVariables(vars)

  return {
    ...pick(variables, [...commonShipmentFields, 'timeslotId']),
    shipFromStreet: get(variables, 'street'),
    shipFromCity: get(variables, 'city'),
    shipFromPostalCode: getLocalPostalCode(variables),
    shipFromHouseNumber: getLocalHouseNumber(variables),
  }
}

export const backendOrderInterface = ({ shop, order }) => {
  const { isPickup, isDropoff, isLocal, isInternational } = orderHelper({
    shop,
    order,
  })

  let mapped
  if (isPickup && isInternational) mapped = backendPickupIntMapper(order)
  if (isPickup && isLocal) mapped = backendPickupMapper(order)
  if (isDropoff && isInternational) mapped = backendDropoffIntMapper(order)
  if (isDropoff && isLocal) mapped = backendDropoffMapper(order)
  Object.keys(mapped).map((key) => get(mapped, key))

  return mapped
}

export const backendDropoffMapper = (order) => ({
  ...pick(order, [
    'id',
    'locale',
    'paymentUrl',
    'paymentStatus',
    'email',
    'orderNumber',
    'termsAndConditions',
    'insured',
    'newsLetter',
    'dropoffProviderUrl',
    'dropoffProviderName',
    'labelUrl',
    'price',
    'shipFromCountryCode',
    'approveStatus',
    'isFree',
    'noLogistics',
    'proceeded',
    'returnType',
    'shipFromStreet',
    'shipFromHouseNumber',
    'shipFromCity',
    'returnType',
    'name',
    'numberOfBoxes',
  ]),
  makesShipmentFree: get(order, 'returnReason.makesShipmentFree'),
  network: get(order, 'network').toLowerCase(),
})

export const backendDropoffIntMapper = (order) => ({
  ...pick(order, [
    'id',
    'locale',
    'paymentUrl',
    'paymentStatus',
    'email',
    'orderNumber',
    'termsAndConditions',
    'insured',
    'newsLetter',
    'dropoffProviderUrl',
    'dropoffProviderName',
    'labelUrl',
    'price',
    'shipFromCountryCode',
    'shipFromCity',
    'shipAddressLine',
    'approveStatus',
    'isFree',
    'noLogistics',
    'proceeded',
    'returnType',
  ]),
  makesShipmentFree: get(order, 'returnReason.makesShipmentFree'),
  network: get(order, 'network').toLowerCase(),
})

export const backendPickupMapper = (order) => {
  const pickupPostalCodeParts =
    (order.shipFromPostalCode || '').match(/^(\d{4})\s?(\w{2})$/) || []
  const houseNumberParts =
    (order.shipFromHouseNumber || '').match(/^(\d+)\s?(.*)$/) || []

  return {
    ...pick(order, [
      'id',
      'locale',
      'paymentUrl',
      'paymentStatus',
      'email',
      'orderNumber',
      'termsAndConditions',
      'insured',
      'newsLetter',
      'dropoffProviderUrl',
      'dropoffProviderName',
      'labelUrl',
      'price',
      'shipFromCountryCode',
      'shipFromStateProvinceCode',
      'approveStatus',
      'start',
      'stop',
      'isFree',
      'noLogistics',
      'proceeded',
      'returnType',
      'numberOfBoxes',
      'returnType',
      'name',
      'shipFromCity',
      'shipFromStreet',
      'timeslotId',
    ]),
    houseNumber: houseNumberParts[1],
    houseAddition: houseNumberParts[2],
    postalCode: pickupPostalCodeParts[1],
    postalCodeEnd: pickupPostalCodeParts[2],
    street: get(order, 'shipFromStreet'),
    city: get(order, 'shipFromCity'),
    makesShipmentFree: get(order, 'returnReason.makesShipmentFree'),
    network: get(order, 'network').toLowerCase(),
  }
}

export const backendPickupIntMapper = (order) => ({
  ...pick(order, [
    'id',
    'locale',
    'paymentUrl',
    'paymentStatus',
    'email',
    'orderNumber',
    'termsAndConditions',
    'insured',
    'newsLetter',
    'dropoffProviderUrl',
    'dropoffProviderName',
    'labelUrl',
    'price',
    'shipFromCountryCode',
    'shipFromPostalCode',
    'shipFromStateProvinceCode',
    'shipFromCity',
    'shipFromAddressLine',
    'approveStatus',
    'start',
    'stop',
    'isFree',
    'noLogistics',
    'proceeded',
    'returnType',
    'timeslotId',
    'name',
    'numberOfBoxes',
  ]),
  network: get(order, 'network').toLowerCase(),
  makesShipmentFree: get(order, 'returnReason.makesShipmentFree'),
})

export const updateShipmentInterface = (order) => {
  const dropoffVariables = pick(order, [
    'id',
    'name',
    'email',
    'numberOfBoxes',
    'orderNumber',
  ])

  if (order.returnType === 'dropoff') {
    return dropoffVariables
  }

  return {
    ...dropoffVariables,
    ...pick(order, [
      'shipFromStreet',
      'shipFromStateProvinceCode',
      'shipFromCity',
      'timeslotId',
    ]),
    shipFromHouseNumber: getLocalHouseNumber(order),
    shipFromPostalCode: getLocalPostalCode(order),
  }
}
