import React from 'react'
import FieldInput, { focusChangeByMaxLength } from 'components/FieldInput'
import FieldWrapper from 'components/FieldWrapper'
import { get } from 'lib/lodash'
import { isFieldDirty } from 'lib/formHelpers'
import { LOCAL_ADDRESS_FIELDS } from 'config/constants'

const { POSTAL_CODE } = LOCAL_ADDRESS_FIELDS

export default function PostalCode(props) {
  const { errors, values, touched, t } = props
  const value = get(values, POSTAL_CODE)
  const error = get(errors, POSTAL_CODE)

  const postalCodeProps = {
    ...props,
    id: POSTAL_CODE,
    name: POSTAL_CODE,
    label: t('address.form.postal_code.label'),
    minLength: 4,
    maxLength: 4,
    pattern: '\\d*',
    type: 'number',
    onChange: focusChangeByMaxLength,
    isFieldDirty: isFieldDirty(touched, POSTAL_CODE),
    autoFocus: true,
    value,
  }

  return (
    <FieldWrapper
      errors={error}
      filled={value}
      classNames="is-expanded control--s input-postalcode"
    >
      <FieldInput {...postalCodeProps} />
    </FieldWrapper>
  )
}
