import React from 'react'
import FieldInput from 'components/FieldInput'
import FieldWrapper from 'components/FieldWrapper'
import { isFieldDirty } from 'lib/formHelpers'
import { get } from 'lib/lodash'
import { SHIPMENT_FIELDS } from 'config/constants'

export default function ShipFromAddressLine(props) {
  const { errors, values, touched, t } = props
  const value = get(values, SHIPMENT_FIELDS.SHIP_FROM_ADDRESS_LINE)
  const error = get(errors, SHIPMENT_FIELDS.SHIP_FROM_ADDRESS_LINE)

  const shipFromAddressLineProps = {
    ...props,
    id: SHIPMENT_FIELDS.SHIP_FROM_ADDRESS_LINE,
    name: SHIPMENT_FIELDS.SHIP_FROM_ADDRESS_LINE,
    label: t('address.form.street_address.label'),
    type: 'text',
    isFieldDirty: isFieldDirty(touched, SHIPMENT_FIELDS.SHIP_FROM_ADDRESS_LINE),
    value,
  }

  return (
    <FieldWrapper errors={error} filled={value}>
      <FieldInput {...shipFromAddressLineProps} />
    </FieldWrapper>
  )
}
