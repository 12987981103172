import React, { useState, useMemo } from 'react'
import './index.scss'
import nl from 'assets/images/flags/nl@2x.png'
import en from 'assets/images/flags/en@2x.png'
import de from 'assets/images/flags/de@2x.png'
import fr from 'assets/images/flags/fr@2x.png'
import { dispatch } from 'store'
import { useSelector } from 'react-redux'
import { getApp } from 'lib/selectors'
import appActions from 'features/app/actions'
import { langCode } from 'lib/i18n'

const FLAGS = {
  nl,
  en,
  de,
  fr,
}

export const LANGUAGES_OBJECT = {
  NL: 'nl',
  EN: 'en',
  DE: 'de',
  FR: 'fr',
}

function FlagsLanguageSelector({ languages, selected, onSelect, className }) {
  const [open, setOpen] = useState(false)

  const filtered = useMemo(
    () => languages.filter((lang) => lang !== selected),
    [languages, selected],
  )

  const handleSelect = (language) => {
    onSelect(language)
    setOpen(false)
  }

  return (
    <div
      className={`switch-language switch-language--flags ${
        open ? 'switch-language--open' : ''
      } ${className}`}
    >
      <button
        type="button"
        className="switch-language__lang switch-language__lang--selected"
        onClick={() => setOpen(!open)}
      >
        <img src={FLAGS[selected]} alt={selected} />
      </button>
      {filtered.map((lang, index) => (
        <button
          type="button"
          className={`switch-language__lang switch-language__lang--${index}`}
          onClick={() => handleSelect(lang)}
          key={lang}
        >
          <img src={FLAGS[lang]} alt={lang} />
        </button>
      ))}
    </div>
  )
}

function ChangeLanguageWrapper({ className = '' }) {
  const storeLanguage = (lang) => {
    dispatch(appActions.setLocale(lang))
  }

  const app = useSelector(getApp)
  const languages = Object.values(LANGUAGES_OBJECT).map((l) => l.toLowerCase())

  const appLocale = app.locale || langCode()
  return (
    <FlagsLanguageSelector
      languages={languages}
      selected={appLocale}
      onSelect={storeLanguage}
      className={className}
    />
  )
}
export default ChangeLanguageWrapper
