import ups1 from 'assets/images/network-logos/ups.png'
import ups2 from 'assets/images/network-logos/ups@2x.png'
import ups3 from 'assets/images/network-logos/ups@3x.png'
import dhl1 from 'assets/images/network-logos/dhl.png'
import dhl2 from 'assets/images/network-logos/dhl@2x.png'
import dhl3 from 'assets/images/network-logos/dhl@3x.png'
import homerr from 'assets/images/network-logos/homerr.jpeg'
import dpd1 from 'assets/images/network-logos/dpd@1x.png'
import dpd2 from 'assets/images/network-logos/dpd@2x.png'
import dpd3 from 'assets/images/network-logos/dpd@3x.png'
import brenger from 'assets/images/network-logos/brenger2.png'

export default {
  networks: {
    ups: {
      logos: [ups1, ups2, ups3],
    },
    dhl: {
      logos: [dhl1, dhl2, dhl3],
    },
    dpd: {
      logos: [dpd1, dpd2, dpd3],
    },
    homerr: {
      logos: [homerr, homerr, homerr],
    },
    brenger: {
      logos: [brenger, brenger, brenger],
    },
  },
}
