import React from 'react'
import { withNamespaces } from 'react-i18next'
import { get } from 'lib/lodash'
import Price from 'components/Price'
import networkLogos from 'config/networkLogos'
import i18n, { getTransKey } from 'lib/i18n'
import { styled, respondTo } from 'lib/styled'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin-bottom: 1.25rem;

  &:first-child {
    ${respondTo.md`
      margin-bottom: 0;
    `}
  }
  ${respondTo.md`
      width: ${({ withNotes }) => (withNotes ? '50%' : '100%')};
      max-width: auto;
  `}
`
const Button = styled.button`
  padding: 1.25rem;
  width: 100%;
  border-radius: 0.9375rem;
  box-shadow: ${({ active }) =>
    active
      ? 'none'
      : '0 0.1875rem 0.375rem 0 var(--network-btn-box-shadow-color)'};
  background-color: var(--main-bg-color);
  cursor: pointer;
  border: solid 0.25rem
    ${({ active }) =>
      active ? 'var(--network-btn-border-color)' : 'transparent'};

  &:hover {
    ${respondTo.md`
      border: solid 0.25rem var(--network-btn-border-color);
      box-shadow: none;
    `}
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const Title = styled.div`
  font-size: 1.5rem;
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-extra-bold);
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  line-height: 1.11;
  letter-spacing: normal;
  color: var(--main-text-color);
`

const TitleText = styled.div`
  font-size: 0.9375rem;
  margin-bottom: 0.75rem;
  width: 100%;

  ${respondTo.md`
    font-size: inherit;
    margin-bottom: 0;
  `}
`

const LogoContainer = styled.form`
  width: 4.375rem;
`

const Img = styled.img`
  /* ... */
`

const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.0625rem;
  border-radius: 0.3125rem;
  background-color: ${(props) =>
    props.active
      ? 'var(--network-btn-badge-bg-color--active)'
      : 'var(--network-btn-badge-bg-color)'};

  font-size: 1rem;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) =>
    props.active
      ? 'var(--network-btn-badge-text-color--active)'
      : 'var(--network-btn-badge-text-color)'};
`

const NoPrintingLabel = styled.div`
  align-items: center;
  justify-content: center;

  width: auto;
  border-radius: 0.3125rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  margin-bottom: 0.75rem;
  background-color: ${({ active }) =>
    active
      ? 'var(--network-btn-label-bg-color--active)'
      : 'var(--network-btn-label-bg-color)'};

  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: ${({ active }) =>
    active
      ? 'var(--network-btn-label-text-color--active)'
      : 'var(--network-btn-label-text-color)'};
  text-transform: uppercase;

  display: ${(props) => (props.hiddenMobile ? 'none' : 'flex')};
  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
  height: 1.5625rem;
  font-size: 0.625rem;

  ${respondTo.md`
    display: ${(props) => (props.hiddenDesktop ? 'none' : 'flex')};
    height: 2.125rem;
    font-size: 0.75rem;
  `}
`

const PriceText = styled.div`
  width: 90%;
  word-wrap: normal;
  text-align: center;
  margin-top: 1rem;
`

const titles = {
  dropoff: {
    ups: 'UPS Access Point',
    dhl: 'DHL Service Point',
    dpd: 'DPD Service Point',
    homerr: 'Homerrpunt',
  },
  pickup: {
    ups: 'UPS Pickup',
    dhl: 'DHL Pickup',
    brenger: 'Brenger pickup',
  },
}

function NetworkButton(props) {
  const {
    shop,
    price,
    network,
    paperless,
    active,
    allPricesFree,
    handleOnClick,
    withNotes,
    isDropoff,
    isPickup,
    t,
  } = props
  const logos = get(networkLogos, `networks.${network}.logos`, [])
  const priceText = <Price price={price} showFree={!allPricesFree} />

  let priceNoteAbove
  const translationKey = getTransKey('network.form.priceNote', shop, 'hi')
  if (i18n.exists(translationKey))
    priceNoteAbove = <PriceText>{t(translationKey)}</PriceText>

  const noPrintingMessage = ({ hiddenMobile, hiddenDesktop }) => (
    <NoPrintingLabel
      hiddenMobile={hiddenMobile}
      hiddenDesktop={hiddenDesktop}
      hidden={!paperless}
      active={paperless && active}
    >
      {t('network.form.no-printer-required')}
    </NoPrintingLabel>
  )

  const buttonText = active
    ? t('network.form.selected')
    : t('network.form.select-option')

  let titleByType
  if (isDropoff) titleByType = titles.dropoff
  if (isPickup) titleByType = titles.pickup
  const titleText = titleByType[network]

  return (
    <Container withNotes={withNotes}>
      <Button
        active={active}
        type="submit"
        onClick={() => handleOnClick(network)}
      >
        <Header>
          <Title>
            <TitleText>{titleText}</TitleText>
            {noPrintingMessage({ hiddenDesktop: true })}
          </Title>
          <LogoContainer>
            <Img
              alt={network}
              src={logos[0]}
              srcSet={`${logos[1]} 2x, ${logos[2]} 3x`}
            />
          </LogoContainer>
        </Header>
        {noPrintingMessage({ hiddenMobile: true })}
        <Badge active={active}>{buttonText}</Badge>
      </Button>

      {!allPricesFree && priceNoteAbove}
      {!allPricesFree && priceText}
    </Container>
  )
}

export default withNamespaces('translation')(NetworkButton)
