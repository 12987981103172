import { useState } from 'react'
import moment from 'moment'
import timeslotActions from 'features/timeslots/actions'
import { dispatch } from 'store'
import useOrder from 'hooks/useOrder'

export default (props = {}) => {
  const { noData, isDhl } = useOrder()
  if (noData) return { noData }

  const { onSuccess } = props
  const [loading, setLoading] = useState(false)
  const [timeslots, setTimeslots] = useState()

  const filterForDhl = (data) => {
    const endTime = moment('23:30', 'hh:mm')
    const now = moment()
    if (now.isAfter(endTime)) {
      const tomorrow = new Date().setDate(new Date().getDate() + 1)
      return data.filter((day) => moment(day.start).isAfter(tomorrow, 'day'))
    }
    return data
  }

  const requestTimeslots = async (variables) => {
    let responseData
    setLoading(true)

    try {
      responseData = await dispatch(timeslotActions.getTimeslots(variables))
    } finally {
      setLoading(false)
    }

    const data = isDhl ? filterForDhl(responseData) : responseData
    setTimeslots(data)
    if (onSuccess) onSuccess(data)
  }

  return { loading, requestTimeslots, timeslots }
}
