/* eslint-disable arrow-parens */
import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { reactI18nextModule } from 'react-i18next'
import moment from 'moment'
import 'moment/locale/nl'
import 'moment/locale/de'
import 'moment/locale/fr'
import { get } from 'lib/lodash'

const params = {
  postProcess: ['unescapeSingleQuote'],
  fallbackLng: 'en',
  debug: false,
  react: {
    wait: true,
  },
}

i18n
  .on('languageChanged', (lng) => {
    moment.locale(lng)
  })
  .use(Backend)
  .use(LanguageDetector)
  .use({
    type: 'postProcessor',
    name: 'unescapeSingleQuote',
    process(value) {
      return value.split('&#39;').join("'")
    },
  })
  .use(reactI18nextModule)
  .init(params)

export function langCode() {
  let code = i18n.language.substring(0, 2)
  if (!['en', 'nl', 'de', 'fr'].includes(code)) code = 'en'
  return code
}

export const getTransKey = (path, shop, defaultValue) => {
  const customTransKey = getTransKeyForShop(path, shop)
  if (i18n.exists(customTransKey)) return customTransKey
  if (defaultValue) return defaultValue

  const defaultTransKey = `${path}.default`
  if (i18n.exists(defaultTransKey)) return defaultTransKey

  return path
}

export const getTransKeyForShop = (path, shop) => {
  const frontendAssetId = get(shop, 'frontendAssetId')
  return `${path}.${frontendAssetId}`
}

export const getTransKeyWODefault = (path, shop, defaultValue) => {
  const { frontendAssetId } = shop
  const customTransKey = `${path}.${frontendAssetId}`
  if (i18n.exists(customTransKey)) return customTransKey
  if (defaultValue) return defaultValue

  const defaultTransKey = `${path}.default`
  if (i18n.exists(defaultTransKey)) return defaultTransKey

  return undefined
}

export default i18n
