import React from 'react'
import Slider from '@themainingredient/react-slick'
import { get, isEqual } from 'lib/lodash'

function Days({ daysIndex, daysList = [], selectDay, orderDay = daysList[0] }) {
  const sliderIndex = daysList.findIndex(
    (dayElement) => dayElement === orderDay,
  )

  const settings = {
    centerMode: true,
    swipeToSlide: true,
    slidesToShow: 1,
    infinite: false,
    speed: 200,
    variableWidth: true,
    arrows: false,
    focusOnSelect: true,
    initialSlide: sliderIndex,
  }

  return (
    <Slider {...settings}>
      {daysList.map((day) => {
        const weekDay = get(daysIndex[day], 'weekDay')
        return (
          <div
            role="presentation"
            className="dayslider__item"
            key={weekDay}
            onClick={() => selectDay(day)}
          >
            <div className="dayslider__day-of-week">
              <span>{weekDay}</span>
            </div>
            <div className="dayslider__date">
              <span>{`${day}`.length === 1 ? `0${day}` : day}</span>
            </div>
          </div>
        )
      })}
    </Slider>
  )
}

export default React.memo(
  Days,
  (prevProps, nextProps) =>
    isEqual(prevProps.selectedDay, nextProps.selectedDay) &&
    isEqual(prevProps.daysIndex, nextProps.daysIndex),
)
