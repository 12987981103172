import React from 'react'
import FieldInput from 'components/FieldInput'
import FieldWrapper from 'components/FieldWrapper'
import { isFieldDirty } from 'lib/formHelpers'
import { get } from 'lib/lodash'
import { SHIPMENT_FIELDS } from 'config/constants'

const { PHONE } = SHIPMENT_FIELDS

export default function Phone(props) {
  const { errors, values, touched, t } = props
  const value = get(values, PHONE)
  const error = get(errors, PHONE)

  const phoneProps = {
    ...props,
    id: PHONE,
    name: PHONE,
    label: t('details.form.phone_number.label'),
    placeholder: '+341234567890',
    type: 'tel',
    inputmode: 'numeric',
    isFieldDirty: isFieldDirty(touched, PHONE),
    value,
  }

  return (
    <FieldWrapper errors={error} filled={value}>
      <FieldInput {...phoneProps} />
    </FieldWrapper>
  )
}
