import React from 'react'
import { withNamespaces } from 'react-i18next'
import useOrder from 'hooks/useOrder'
import useHistory from 'hooks/useHistory'
import { isBlank } from 'lib/helpers'
import { get } from 'lib/lodash'
import { SHIPMENT_FIELDS } from 'config/constants'
import { orderAction } from 'lib/actions'
import ForeignAddressFields from './ForeignAddressFields'
import LocalAddressFields from './LocalAddressFields'

const { NETWORK, SHIP_FROM_COUNTRY_CODE } = SHIPMENT_FIELDS

function AddressFields(props) {
  const {
    noData,
    order,
    isLocal,
    isInternational,
    localPricelists,
    isDropoff,
    isPickup,
  } = useOrder()
  const { goToParcels } = useHistory()

  if (noData) return null

  const { setLoading, form, isEdit } = props
  const network = get(order, NETWORK)
  const onCountryChange = (value) => {
    if (isBlank(value)) return

    const attrs = { [SHIP_FROM_COUNTRY_CODE]: value, countrySetFromForm: true }
    if (value === 'nl') {
      if (isPickup) {
        if (localPricelists.size > 1) {
          const index = localPricelists.findIndex(
            (pl) => pl.network === network,
          )
          if (!index) {
            attrs[NETWORK] = undefined
            goToParcels()
          }
        } else {
          attrs[NETWORK] = localPricelists[0].network
        }
      }

      if (isDropoff) goToParcels()
    }

    orderAction.setOrder(attrs)
  }

  if (isLocal)
    return (
      <LocalAddressFields isEdit={isEdit} form={form} setLoading={setLoading} />
    )

  if (isInternational)
    return (
      <ForeignAddressFields
        form={form}
        setLoading={setLoading}
        onCountryChange={onCountryChange}
      />
    )

  return null
}

export default withNamespaces('translation')(AddressFields)
