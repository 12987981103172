import React from 'react'
import ReactDOM from 'react-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from 'store'
import { Provider } from 'react-redux'
import { datadogLogs } from '@datadog/browser-logs'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import App from './App'
import * as serviceWorker from './serviceWorker'

if (window && window.location.hostname === 'zinzi.returnista.nl')
  window.open('https://zinzi.returnista.com', '_self')

if (process.env.REACT_APP_SENTRY) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  site: process.env.REACT_APP_DATADOG_SITE,
  forwardErrorsToLogs: true,
  sampleRate: 100,
})

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

window.reset = () => {
  persistor.purge()
  document.location.reload()
}

serviceWorker.unregister()
