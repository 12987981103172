/* eslint-disable nonblock-statement-body-position */
import React from 'react'
import { get } from 'lib/lodash'
import FieldSelect from 'components/FieldSelect'
import FieldWrapper from 'components/FieldWrapper'
import { isFieldDirty } from 'lib/formHelpers'
import {
  SHIPMENT_FIELDS,
  SHOP_FIELDS,
  RETURN_REASON_FIELDS,
} from 'config/constants'
import { orderAction } from 'lib/actions'
import { fetchReturnReasons, getOtherOption } from '../helper'

const { RETURN_REASON_ID, CUSTOM_RETURN_REASON } = SHIPMENT_FIELDS
const { OFFERS_EXCHANGE, WRONG_ITEM, DEFECT } = RETURN_REASON_FIELDS

export default function ReturnReason(props) {
  const { errors, values, touched, setFieldValue, t, shop } = props
  const value = get(values, RETURN_REASON_ID)
  const error = get(errors, RETURN_REASON_ID)
  const shopId = get(shop, SHOP_FIELDS.ID)
  const repairShop = get(shop, SHOP_FIELDS.REPAIR_SHOP, false)
  const otherOption = getOtherOption({ shop, t })
  const returnReasons = fetchReturnReasons({ shop, otherOption })
  const gatekeeperEnabled = get(shop, SHOP_FIELDS.GATEKEEPER_ENABLED, false)

  const returnReasonProps = {
    ...props,
    id: RETURN_REASON_ID,
    name: RETURN_REASON_ID,
    prompt: t('details.form.returnReasonId.prompt'),
    label: t('details.form.returnReasonId.label'),
    isFieldDirty: isFieldDirty(touched, RETURN_REASON_ID),
    options: returnReasons.formatted.options,
    optionsIndex: returnReasons.formatted.index,
    required: gatekeeperEnabled,
    shopId,
    error,
    value,
    onChange: (val) => {
      // hack for custom return reason
      let newValue = 'dummy'
      const currentReturnReason = returnReasons?.raw?.index?.[val]
      if (
        val === '0' ||
        get(currentReturnReason, OFFERS_EXCHANGE) ||
        get(currentReturnReason, WRONG_ITEM) ||
        get(currentReturnReason, DEFECT)
      )
        newValue = ''
      setFieldValue(CUSTOM_RETURN_REASON, newValue, false)
      orderAction.setOrder({ [RETURN_REASON_ID]: val })
    },
  }

  if (repairShop)
    returnReasonProps.label = t('details.form.returnReasonId.repairShop.label')

  return (
    <FieldWrapper errors={error} filled={value} classNames="control-no-hr">
      <FieldSelect {...returnReasonProps} />
    </FieldWrapper>
  )
}
